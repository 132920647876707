import styled from 'styled-components';
import { TableCellProps } from 'react-table';
import { Scrollbars } from 'react-custom-scrollbars';

export const ResizeContainer = styled.div`
  height: 100%;
  overflow: hidden;
`;

export const Table = styled.div<TableProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  ${({ top, bottom }) => `max-height: calc(100vh - ${top + bottom}px);`}
`;

export const Thead = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  box-shadow: 0 1px 3px 0 gray;
`;

export const Tbody = styled(Scrollbars)`
  overflow: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  .table_body_row {
    :hover {
      background-color: var(--table-hover-bg);
    }
    min-height: 40px;
  }
`;

export const Tfoot = styled.div`
  padding: 10px;
  box-shadow: 0 0 3px 1px gray;
`;

export const Tr = styled.div<TRProps>`
  ${({ index = 0 }) => index % 2 === 0 && `${Td} { background-color: var(--table-odd-bg); }`}
  cursor: pointer;
  display: flex;
  width: 100%;
  height: auto;
  text-decoration: none;
  color: unset;
  > div {
    border-right: var(--table-cell-border-color);
  }
  > div:last-child {
    border-right: none;
  }
  &:hover {
    text-decoration: none;
    color: unset;
  }
`;

const Cell = styled.div<TableCellProps>`
  padding: 3px;
  ${({ style }) => (!style?.width || !/\d+px/.test(style?.width as string) ? `flex 1;` : ``)}
`;

export const Th = styled(Cell)``;

export const Td = styled(Cell)``;

type TRProps = {
  index?: number;
};

type TableProps = {
  top: number;
  bottom: number;
};

type SortContainerProps = {
  clickable?: boolean;
};
export const SortContainer = styled.div<SortContainerProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${({ clickable }) => clickable && `cursor: pointer;`}
  user-select: none;
  width: 100%;
`;

export const HeaderContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  padding: 5px;
`;

export const CellContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  height: 100%;
`;

export const FilterContainer = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
`;

export const PaginationContainer = styled.div`
  display: flex;
  align-items: center;
  div {
    margin: 0 2px;
  }
`;
