import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { getType } from 'utils';
import { useFormikContext } from 'formik';
import { ColorEnum, Icon, IconType, IconTypeWithFile } from '@whitelabel/common/dist/api';
import { FileSelectField, SelectField, SelectFieldOption } from 'components/ui/formik-controls';
import * as iconsWireframeList from 'components/ui/icon/content/iconsWireframeList';
import { Button, Collapse, ControlGroup, FormGroup } from '@blueprintjs/core';
import { IconCaption } from './icon-caption';
import { IconPreview } from './icon-preview';

const colorMap: { [key in ColorEnum]: ColorEnum } = _.omit(ColorEnum, [ColorEnum.brand]);

export interface ChooseIconHandlerProps {
  initialIcon: IconType;
  currentIcon: IconTypeWithFile;
  groupLabel?: string;
  formFieldName?: string;
}

export function ChooseIconHandler({
  initialIcon,
  currentIcon,
  groupLabel = 'Icon',
  formFieldName = 'icon',
}: ChooseIconHandlerProps) {
  const { setFieldValue } = useFormikContext();
  const iconFileInputRef = React.createRef<HTMLInputElement>();
  const [iconOptions, setIconOptions] = useState<SelectFieldOption<IconTypeWithFile>[]>([]);

  useEffect(() => {
    _.chain(iconsWireframeList)
      .keys()
      .map((icon) => ({
        key: icon,
        value: { name: icon },
        caption: <IconCaption icon={{ name: icon }} size={24} />,
      }))
      .unshift(
        (initialIcon && getType(initialIcon) === 'String'
          ? { value: initialIcon, caption: <IconCaption icon={initialIcon} size={24} /> }
          : undefined) as any
      )
      .compact()
      .thru(setIconOptions)
      .value();
  }, [initialIcon]);

  const iconColorOptions = _.chain(ColorEnum)
    .keys()
    .map((color) => ({ key: color, value: color, caption: color }))
    .unshift({ value: undefined, caption: <br /> } as any)
    .value();

  const compareHandler = (value1: IconTypeWithFile, value2: IconTypeWithFile) =>
    ['File', 'Object'].includes(getType(value1))
      ? (value1 as Icon)?.name === (value2 as Icon)?.name
      : value1 === value2;

  return (
    <>
      <div hidden>
        <FileSelectField
          fill
          preView
          name={formFieldName}
          inputProps={{
            accept: '.jpg, .jpeg, .JPG, .JPEG, .png, .PNG, .svg',
            id: 'icon',
            ref: iconFileInputRef,
          }}
          onSelect={(_, value) => {
            setIconOptions([
              {
                key: value.name,
                value,
                caption: <IconCaption icon={value} size={24} />,
              },
              ...iconOptions,
            ]);
          }}
        />
      </div>
      <FormGroup label={groupLabel} style={{ width: '100%' }}>
        <ControlGroup fill>
          <SelectField<IconTypeWithFile>
            fill
            large
            options={iconOptions}
            name={formFieldName}
            filterable={false}
            placeholder=" "
            popoverProps={{
              boundary: 'viewport',
              popoverClassName: 'scrollable',
            }}
            inputProps={{ large: true }}
            onCompare={compareHandler}
          />
          <FormGroup style={{ flex: 0 }}>
            <Button large icon="folder-open" onClick={() => iconFileInputRef.current?.click()} />
          </FormGroup>
          <FormGroup style={{ flex: 0 }}>
            <Button large icon="trash" onClick={() => setFieldValue(formFieldName, null)} />
          </FormGroup>
        </ControlGroup>
        <Collapse isOpen={!!currentIcon}>
          <div style={{ paddingLeft: '15px', display: 'flex' }}>
            {currentIcon && (
              <IconPreview
                icon={currentIcon}
                color={((color?: ColorEnum) => (color ? colorMap[color] : color))((currentIcon as Icon)?.color)}
                size={64}
              />
            )}
            {getType(currentIcon) === 'Object' && (
              <SelectField
                label="color"
                fill
                filterable={false}
                placeholder=" "
                options={iconColorOptions}
                name={`${formFieldName}[color]`}
                style={{ width: '120px', paddingLeft: '30px' }}
              />
            )}
          </div>
        </Collapse>
      </FormGroup>
    </>
  );
}
