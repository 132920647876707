import { BaseApi } from 'api/base';
import * as Types from 'api/user-types';

class UserApi extends BaseApi {
  getList = async (): Promise<Types.UserListResponse> => {
    const result = await this.fetch<Types.UserListResponse>('/user');
    return result;
  };

  getItem = async (id: number): Promise<Types.UserResponse> => {
    const result = await this.fetch<Types.UserResponse>(`/user/${id}`);
    return { ...result, password: '' };
  };

  addItem = async (data: Types.User): Promise<Types.User> => {
    const result = await this.fetch<Types.AddUserResponse>(`/user`, { method: 'post', data });
    return { id: result.id, ...data };
  };

  editItem = async (id: number, data: Types.User): Promise<Types.User> => {
    const result = await this.fetch<Types.UserResponse>(`/user/${id}`, { method: 'put', data });
    return { ...result, password: '' };
  };

  deleteItem = async (id: number): Promise<number> => {
    await this.fetch<Types.UserResponse>(`/user/${id}`, { method: 'delete' });
    return id;
  };

  changePassword = async (id: number, password: string, newPassword: string): Promise<void> => {
    await this.fetch<Types.UserResponse>(`/user/change-pwd/${id}`, { method: 'put', data: { password, newPassword } });
  };
}

export const userApi = new UserApi();
