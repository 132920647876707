import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ReactQueryCacheProvider, QueryCache } from 'react-query';
import { ReactQueryDevtools } from 'react-query-devtools';
import configureStore, { history } from 'store/configure-store';
import App from './app';
import * as serviceWorker from './serviceWorker';
import './index.scss';

const store = configureStore();
const queryCache = new QueryCache();

ReactDOM.render(
  // <React.StrictMode>
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ReactQueryCacheProvider queryCache={queryCache}>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </ReactQueryCacheProvider>
    </ConnectedRouter>
  </Provider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
