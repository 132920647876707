import React from 'react';
import moment from 'moment';
import { Icon } from '@blueprintjs/core';
import { Client } from '../api/client-types';

export const ClientPopover = ({ client }: { client: Client }) => {
  return (
    <div>
      <p>
        <b>external_id: </b>
        <span>{client.externalId}</span>
      </p>
      <p>
        <b>mnemocode: </b>
        <span>{client.mnemocode}</span>
      </p>
      <p>
        <b>profile_id: </b>
        <span>{client.profileId}</span>
      </p>
      <p>
        <b>role_id: </b>
        <span>{client.roleId}</span>
      </p>
      <p>
        <b>role_name: </b>
        <span>{client.name}</span>
      </p>
      <p>
        <b>is_locked: </b>
        <span>{client.isLocked === 'Y' ? <Icon icon="small-tick" /> : ''}</span>
      </p>
      <p>
        <b>is_stopped: </b>
        <span>{client.isStopped === 'Y' ? <Icon icon="small-tick" /> : ''}</span>
      </p>
      <p>
        <b>stopped_on: </b>
        <span>{client.stoppedOn ? moment(client.stoppedOn).format('DD.MM.YYYY') : ''}</span>
      </p>
      <p>
        <b>profile_closed: </b>
        <span>{client.profileClosed === 'Y' ? <Icon icon="small-tick" /> : ''}</span>
      </p>
      <p>
        <b>profile_close_date: </b>
        <span>{client.profileCloseDate ? moment(client.profileCloseDate).format('DD.MM.YYYY') : ''}</span>
      </p>
    </div>
  );

  /*
  return (
    <div>
      <p>
        <b>locked_until: </b>
        <span>{client.locked_until ? moment(client.locked_until).format('DD.MM.YYYY') : ''}</span>
      </p>
      <p>
        <b>is_locked: </b>
        <span>{client.is_locked ? <Icon icon="small-tick" /> : ''}</span>
      </p>
    </div>
  );
   */
};
