import React from 'react';
import { useDispatch } from 'react-redux';
import { Form, Formik } from 'formik';
import { IconNames } from '@blueprintjs/icons';
import { userApi } from 'api/user';
import { InputField, SumbitButton } from 'components/ui/formik-controls';
import { logOut } from 'actions';
import * as S from './change-pwd.styles';
import { ChangePwdSchema } from './change-pwd-schema';
import { AppToaster } from '../../ui/toaster';

const emptyData = {
  password: '',
  newPassword: '',
  confirmationPassword: '',
};

export const ChangePwdPage = ({ userId }: { userId: number }) => {
  const dispatch = useDispatch();
  const handleSubmit = async (
    password: string,
    newPassword: string,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    try {
      await userApi.changePassword(userId, password, newPassword);
      dispatch(logOut());
    } catch (e) {
      /*
      AppToaster.show({
        message: `Невозможно обновить пароль. Введенные данные не соотвествуют требованиям.`,
        intent: 'danger',
      });
      */
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <div style={{ padding: '0 1em 1em 1em' }}>
      <h2>Сменить пароль</h2>
      <Formik
        initialValues={emptyData}
        validationSchema={ChangePwdSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmit(values.password, values.newPassword, setSubmitting);
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <S.Column>
              <InputField type="password" name="password" label="Пароль" />
              <InputField type="password" name="newPassword" label="Новый пароль" />
              <InputField type="password" name="confirmationPassword" label="Подтвердите новый пароль" />
              <SumbitButton isSubmitting={isSubmitting} intent="success" icon={IconNames.SAVED} text="Сохранить" />
            </S.Column>
          </Form>
        )}
      </Formik>
    </div>
  );
};
