import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import * as _ from 'lodash';
import { Button, Collapse } from '@blueprintjs/core';
import { Form, Formik, FormikHelpers } from 'formik';
import { partnerApi } from 'api/partner';
import {
  DateInputField,
  FileSelectField,
  InputField,
  SelectField,
  SelectFieldOption,
  SumbitButton,
  TextAreaField,
} from '../../ui/formik-controls';
import { BonusRegistryUpload } from './api/bonus-registry-types';
import { bonusRegistryApi } from './api/bonus-registry';
import { AppToaster } from '../../ui/toaster';
import * as S from './bonus-registry-form.styles';
import { BonusRegistrySchema } from './bonus-registry-schema';

const currentYear = moment().format('YYYY');
const maxDate = moment(`${Number(currentYear) + 10}`).toDate();

interface StateInterface {
  registry: Partial<BonusRegistryUpload>;
  bankMnemocodeOptions: SelectFieldOption<string | undefined>[];
  goalOptions: SelectFieldOption<number | undefined | null>[];
  goalTypesOptions: SelectFieldOption<string | undefined>[];
  loyaltiesOptions: SelectFieldOption<string | undefined>[];
}

const initialState: StateInterface = {
  registry: {
    bankMnemocode: undefined,
    goal: { goalId: undefined, goalName: undefined, startDate: undefined, finishDate: undefined, goalType: undefined },
    loyaltyMnemocode: undefined,
    period: undefined,
    description: undefined,
  },
  bankMnemocodeOptions: [],
  goalOptions: [],
  goalTypesOptions: [],
  loyaltiesOptions: [],
};

export const BonusRegistryForm = () => {
  const history = useHistory();

  const [state, setState] = useState<StateInterface>(() => initialState);

  const updState = (nextState: Partial<StateInterface>) => setState((prevState) => _.assign({}, prevState, nextState));

  useEffect(() => {
    partnerApi
      .getList()
      .catch(() => [])
      .then((partners) => {
        updState({
          bankMnemocodeOptions: _.map(partners, (partner) => ({
            value: partner?.mnemocode,
            caption: `${partner?.mnemocode} - ${partner?.components?.name}`,
          })),
        });
      });

    bonusRegistryApi
      .getGoals()
      .catch(() => [])
      .then((goals) => {
        updState({
          goalOptions: _.chain(goals)
            .map<SelectFieldOption<number | undefined | null>>((goal) => ({
              value: goal.goalId,
              caption: goal?.name || '',
            }))
            .unshift({
              value: null,
              caption: (<Button fill small text="Новая цель" rightIcon="new-object" />) as any,
            })
            .value(),
        });
      });

    updState({ loyaltiesOptions: [{ value: undefined, caption: 'Не задано' }] });

    bonusRegistryApi
      .getGoalTypes()
      .catch(() => [])
      .then((goaltypes) => {
        updState({ goalTypesOptions: _.map(goaltypes, (goaltype) => ({ value: goaltype, caption: goaltype })) });
      });
  }, []);

  useEffect(() => {
    if (state.registry.bankMnemocode) {
      bonusRegistryApi
        .getLoyalties(state?.registry?.bankMnemocode)
        .catch(() => [])
        .then((loyalties) => {
          updState({
            loyaltiesOptions: _.chain(loyalties)
              .map<SelectFieldOption<string | undefined>>((loyalty) => ({
                value: loyalty?.mnemocode,
                caption: loyalty?.name,
              }))
              .unshift({ value: undefined, caption: 'Не задано' })
              .value(),
          });
        });
    }
  }, [state.registry.bankMnemocode]);

  const handleSubmit = async (
    values: Partial<BonusRegistryUpload>,
    { setSubmitting }: FormikHelpers<Partial<BonusRegistryUpload>>
  ) => {
    try {
      await bonusRegistryApi.upload(values as BonusRegistryUpload);
      AppToaster.show({ message: `Реестр успешно сохранён`, intent: 'success' });
      history.push(`/bonus-registry`);
    } catch (e) {
      AppToaster.show({ message: `Реестр НЕ сохранён`, intent: 'danger' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <S.Section style={{ padding: '0 1em 1em 1em' }}>
      <Formik<Partial<BonusRegistryUpload>>
        enableReinitialize
        initialValues={state.registry}
        validationSchema={BonusRegistrySchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values }) => {
          /* eslint-disable */
          useEffect(() => {
            updState({ registry: values });
          }, [values]);
          return (
            <Form>
              <S.FormWrapper style={{ flexDirection: 'column', width: '400px' }}>
                <SelectField
                  options={state.bankMnemocodeOptions}
                  name="bankMnemocode"
                  label="Банк"
                  filterable={false}
                  placeholder="Выберите банк"
                />

                <SelectField
                  options={state.goalOptions}
                  name="goal[goalId]"
                  label="Цель"
                  filterable={true}
                  placeholder="Выберите цель"
                  popoverProps={{ popoverClassName: 'scrollable-tall' }}
                />

                <div style={{ marginLeft: '15px' }}>
                  <Collapse isOpen={state?.registry?.goal?.goalId === null}>
                    <InputField name="goal[goalName]" label="Название" />
                    <SelectField
                      options={state.goalTypesOptions}
                      name="goal[goalType]"
                      label="Тип"
                      filterable={false}
                      placeholder="Выберите тип цели"
                    />
                    <DateInputField
                      fill
                      name="goal[startDate]"
                      label="Дата начала"
                      placeholder="YYYY-MM-DD"
                      maxDate={maxDate}
                      formatDate={(date) => moment(date).format('YYYY-MM-DD')}
                      parseDate={(str) => moment(str, 'YYYY-MM-DD').toDate()}
                    />
                    <DateInputField
                      fill
                      name="goal[finishDate]"
                      label="Дата окончания"
                      placeholder="YYYY-MM-DD"
                      maxDate={maxDate}
                      formatDate={(date) => moment(date).format('YYYY-MM-DD')}
                      parseDate={(str) => moment(str, 'YYYY-MM-DD').toDate()}
                    />
                  </Collapse>
                </div>

                <SelectField
                  options={state.loyaltiesOptions}
                  name="loyaltyMnemocode"
                  label="Программа"
                  filterable={false}
                  placeholder="Не задано"
                  popoverProps={{
                    boundary: 'viewport',
                    popoverClassName: 'scrollable',
                  }}
                />

                <DateInputField
                  fill
                  name="period"
                  label="Клиентский период"
                  placeholder="YYYY-MM"
                  maxDate={maxDate}
                  formatDate={(date) => moment(date).format('YYYY-MM')}
                  parseDate={(str) => moment(str, 'YYYY-MM').toDate()}
                />

                <FileSelectField fill name="file" label="Файл .CSV" inputProps={{ accept: '.csv', id: 'file' }} />

                <TextAreaField fill name="description" label="Описание" rows={6} resize="none" maxLength={500} />
              </S.FormWrapper>
              <SumbitButton isSubmitting={isSubmitting} text="Загрузить в БД" />
            </Form>
          );
        }}
      </Formik>
    </S.Section>
  );
};
