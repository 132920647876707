export type CashbackActionListResponse = CashbackActionList[];
export type CashbackActionResponse = CashbackAction;

export type CashbackActionList = {
  id: number;
  title: string;
  partner: string;
  period: string;
  bonusCalculation: string;
  bonusValue: number;
  startAt: string;
  stopAt: string;
  displayDate: string;
  isForAll: boolean;
};

export enum CalculationRuleEnum {
  DISCOUNT = 'DISCOUNT',
  CASHBACK = 'CASHBACK',
}

export enum BonusCalculationEnum {
  FIX = 'FIX',
  PERCENT = 'PERCENT',
}

export enum BonusCurrencyEnum {
  RUB = 'RUB',
  USD = 'USD',
  POINT_PARTNER = 'POINT_PARTNER',
  POINT_BANK = 'POINT_BANK',
}

export interface Category {
  id: number;
  title: string;
}

export enum OfferTypeEnum {
  OFFLINE = 'OFFLINE',
  ONLINE = 'ONLINE',
}

export enum PaymentSystemEnum {
  ALL = 'ALL',
  MASTERCARD = 'MASTERCARD',
  VISA = 'VISA',
  MIR = 'MIR',
}

export enum TechTypeEnum {
  FIRST_PURCHASE_DISCOUNT = 'FIRST_PURCHASE_DISCOUNT',
  ALL_PURCHASE_DISCOUNT = 'ALL_PURCHASE_DISCOUNT',
}

export enum LimitTypeEnum {
  CARD = 'CARD',
  CLIENT = 'CLIENT',
}

export type CashbackAction = {
  id: number;
  amountFrom: number;
  amountTo: number;
  bonusCalculation: BonusCalculationEnum;
  bonusCurrency: BonusCurrencyEnum;
  bonusType: string;
  bonusValue: number;
  daysToExp: number;
  description: string;
  // discountId: string;
  displayDate: string;
  fullDescription: string;
  holdTime: number;
  iconUrl: string;
  isActivationRequired: boolean;
  isForAll: boolean;
  isPremiumOffer: boolean;
  isProlongation: boolean;
  offerType: OfferTypeEnum;
  partnerMnemocode: string;
  partnerShortDescription: string;
  paymentSystem: PaymentSystemEnum;
  period: string;
  startAt: string;
  stopAt: string;
  techType: TechTypeEnum;
  title: string;
  www: string;
  bonusCap: number;
  limitType: LimitTypeEnum;
  offerCategories: number[];
  calculationRule: CalculationRuleEnum;
  addresses: CashbackActionAddress[] | null;
};

export type CashbackActionAddress = {
  city: string;
  street: string;
  house: string;
  phone: string;
  work_time: string;
};

export type CashbackActionCategory = {
  id: number;
  name: string;
  iconUrl: string;
  temporal: boolean;
  active: boolean;
  activeFrom: string;
  activeTo: string;
};

export type CashbackActionUpdate = Omit<CashbackAction, 'iconUrl'> & { iconUrl: string | File };
export type CashbackActionEdit = Omit<CashbackAction, 'addresses'> & { addresses: string };

export type CashbackActionMerchantListResponse = CashbackActionMerchant[];
export type CashbackActionMerchantResponse = CashbackActionMerchant;

export type CashbackActionMerchant = {
  id: number;
  mcc: string;
  merchantId: string;
  terminalId: string;
  cardAcceptorName: string;
};

export type CashbackActionMerchantUpdate = Omit<CashbackActionMerchant, 'id'>;
