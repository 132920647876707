import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';
import { setTabs } from 'actions';
import { Tab, TabsRouterProps } from './tabs-router-types';

export const TabsRouter = (props: TabsRouterProps) => {
  const { route, tabs } = props;
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  useEffect(() => {
    const routes = tabs.map((tab) => `${route}${tab.path || ''}`);
    if (routes.includes(pathname)) {
      const items = tabs
        .map((tab) => ({ path: tab.path || '', caption: tab.caption, disabled: tab?.disabled }))
        .reverse();
      dispatch(setTabs({ path: route, items }));
    }
  }, [pathname]);
  return (
    <Switch>
      {tabs.reverse().map((tab: Tab) => {
        const href = `${path}${tab.path || ''}`;
        return (
          <Route key={href} path={href}>
            {tab.component}
          </Route>
        );
      })}
    </Switch>
  );
};
