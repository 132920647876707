import React from 'react';
import { URL } from 'utils/url-builder';
import { SectionPage } from 'components/ui/skeletons/section-page';
import { SectionPage as SectionPageComponent } from './section-page';
import { SectionList } from './section-list';

export const SectionsPage = () => {
  return (
    <SectionPage sectionListUrl={URL.sectionList()} listComponent={SectionList} pageComponent={SectionPageComponent} />
  );
};
