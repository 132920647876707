import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { BreadCrumbItem } from '../section-layout-types';

type BreadCrumbsProps = {
  items?: BreadCrumbItem[];
};

const Panel = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px 0 3px 10px;
`;

const Item = styled.span`
  padding: 5px 5px 5px 0;
  font-size: 120%;
`;

function getCaption({ path, caption }: BreadCrumbItem) {
  if (caption) {
    return caption;
  }
  if (/\/add$/.test(path)) {
    return 'Новая запись';
  }
  const paramsList = path.split('/');
  return paramsList[paramsList.length - 1];
}

const BreadCrumbsComponent = (props: BreadCrumbsProps) => {
  const { items } = props;
  if (!Array.isArray(items) || !items.length) {
    return <></>;
  }
  return (
    <Panel>
      {items.map((item, index) => (
        <Item key={item.path}>
          {Boolean(index) && <span style={{ paddingRight: '5px' }}>{'>'}</span>}
          <span>
            <Link to={item.path}>{getCaption(item)}</Link>
          </span>
        </Item>
      ))}
    </Panel>
  );
};

const mapStateToProps = (state: { breadCrumbs: BreadCrumbItem[] }) => {
  return state.breadCrumbs;
};

export const BreadCrumbs = connect(mapStateToProps)(BreadCrumbsComponent);
