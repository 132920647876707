import React from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import { usePartnerList } from 'api/partner-hooks';
import * as Yup from 'yup';
import * as S from './hooks.styles';
import {
  DateInputField,
  errorMessages,
  MultiSelectField,
  MultiSelectFieldItem,
  SelectField,
} from '../../../ui/formik-controls';
import type { FormFieldsFunction } from './form-fields';
import { SmsInterface } from '../api/report-types';
import { useReportCurrency, useReportGoalTypes } from '../api/report-hooks';

const state: Partial<SmsInterface> = {
  partnerMnemocode: undefined,
  period: undefined,
  currency: undefined,
  excludedGoals: undefined,
  minCreatedWhen: undefined,
};

const schema: Yup.Schema<object | undefined> = Yup.object().shape({
  partnerMnemocode: Yup.string().required(errorMessages.required),
  period: Yup.string().required(errorMessages.required),
  currency: Yup.array().of(Yup.string()).required(errorMessages.required),
  excludedGoals: Yup.array().of(Yup.number()).nullable().optional(),
  minCreatedWhen: Yup.string().optional(),
});

export const useSms: FormFieldsFunction<Partial<SmsInterface>> = () => {
  const partnerMnemocodeOptions = _.chain(usePartnerList())
    .get('data')
    .map((partner) => ({ value: partner?.mnemocode, caption: `${partner?.mnemocode} - ${partner?.components?.name}` }))
    .value();

  const currencyOptions: MultiSelectFieldItem<string>[] = _.chain(useReportCurrency())
    .get('data')
    .map((currency) => ({
      value: currency?.currency,
      caption: `${currency?.currency} - ${currency?.details || currency?.name}`,
    }))
    .value();

  const goalTypesOptions: MultiSelectFieldItem<number>[] = _.chain(useReportGoalTypes())
    .get('data')
    .map((code, goalType) => ({
      value: code,
      caption: `${code} - ${goalType}`,
    }))
    .value();

  function render() {
    return (
      <S.FormWrapper>
        <S.Column>
          <SelectField
            options={partnerMnemocodeOptions}
            name="partnerMnemocode"
            label="Партнер"
            filterable={false}
            placeholder="Выберите партнера"
          />
          <DateInputField
            name="period"
            label="Период"
            placeholder="YYYY-MM"
            formatDate={(date) => moment(date).format('YYYY-MM')}
            parseDate={(str) => moment(str, 'YYYY-MM').toDate()}
          />
          <MultiSelectField<string>
            name="currency"
            label="Валюты"
            items={currencyOptions}
            placeholder="Выберите валюты"
            popoverProps={{ boundary: 'viewport', position: 'right', popoverClassName: 'scrollable' }}
          />
        </S.Column>
        <S.Column>
          <MultiSelectField<number>
            name="excludedGoals"
            label="Исключенные типы целей"
            items={goalTypesOptions}
            placeholder="Выберите типы целей"
            popoverProps={{ boundary: 'viewport', position: 'bottom' }}
          />
          <DateInputField
            name="minCreatedWhen"
            label="Минимальное время создания"
            placeholder="YYYY-MM-DDTHH:mm:ss"
            formatDate={(date) => moment(date).format('YYYY-MM-DDTHH:mm:ss')}
            parseDate={(str) => moment(str, 'YYYY-MM-DDTHH:mm:ss').toDate()}
            timePrecision="second"
          />
        </S.Column>
      </S.FormWrapper>
    );
  }

  return {
    state,
    schema,
    render,
  };
};
