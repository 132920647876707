import _ from 'lodash';
import ReactResizeDetector from 'react-resize-detector';
import React, { CSSProperties, useRef } from 'react';
import { Button, Icon, InputGroup } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { useFilters, useTable, useSortBy, useBlockLayout, TableOptions, Cell, usePagination } from 'react-table';
import { Link } from 'react-router-dom';
import { Select } from '../form-controls/Select';
import * as S from './table-styles';
import './colors.scss';
import { FilterTypes } from './filter-types';

export type Sort = 'asc' | 'desc';
type TableProps<T extends {}> = TableOptions<T> & {
  onRowClick?: (item: T) => void;
  orderBy?: string;
  sort?: Sort;
  onSort?: (column: string, sort: Sort) => void;
  onFilter?: (column: string, value: string | number | boolean) => void;
  getRowStyle?: (row: T) => CSSProperties;
  rowLinkPrefix?: string;
  rowLinkIdProp?: string;
};

const TableWrapped = <T extends {}>(
  props: TableProps<T> & {
    height?: number;
    width?: number;
    top?: number;
    bottom?: number;
    resizeDetector: any;
  }
) => {
  const {
    columns,
    data,
    initialState,
    onRowClick,
    orderBy,
    sort = 'asc',
    onSort,
    width = 0,
    height = 0,
    resizeDetector,
    top = 0,
    bottom = 0,
    rowLinkPrefix,
    rowLinkIdProp,
  } = props;
  const columnsMapped = React.useMemo(
    // eslint-disable-next-line no-shadow
    () => columns.map(({ width, ...rest }) => ({ width, ...rest })),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columns, width]
  );
  const filterTypes = React.useMemo(
    () => ({
      [FilterTypes.Text]: (rows: [], id: string, filterValue: string) => {
        const path = id[0];
        return rows.filter((row: { original: object }) => {
          const cellValue = String(_.get(row.original, path));
          return cellValue !== undefined ? cellValue.toLowerCase().includes(String(filterValue).toLowerCase()) : true;
        });
      },
      [FilterTypes.Boolean]: (rows: [], id: string, filterValue: boolean) => {
        const path = id[0];
        return rows.filter((row: { original: object }) => {
          const cellValue = String(_.get(row.original, path));
          return cellValue !== undefined ? Boolean(cellValue) === Boolean(filterValue) : true;
        });
      },
    }),
    []
  );
  const defaultColumn = React.useMemo(
    () => ({
      width: 150,
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    canPreviousPage,
    previousPage,
    nextPage,
    canNextPage,
    pageCount,
    state: { pageIndex, pageSize },
    setPageSize,
    pageOptions,
    rows,
  } = useTable<T>(
    {
      columns: columnsMapped,
      data,
      initialState,
      filterTypes,
      defaultColumn,
    },
    useBlockLayout,
    useFilters,
    useSortBy,
    usePagination
  );
  const handleRowClick = (item: T) => () => {
    // eslint-disable-next-line no-unused-expressions
    onRowClick && onRowClick(item);
  };
  const handleSort = (event: any, column: string) => () => {
    event.stopPropagation();
    // eslint-disable-next-line no-unused-expressions
    onSort && onSort(column, sort === 'asc' ? 'desc' : 'asc');
  };
  const offsetTop = top || _.get(resizeDetector, 'current.observableElement.offsetTop', 0);
  const RenderRow = ({ row, index }: { row: any; index: number }) => {
    prepareRow(row);

    const rowContent = row.cells.map((cell: Cell) => (
      <S.Td {...cell.getCellProps()}>
        <S.CellContentWrapper>{cell.render('Cell')}</S.CellContentWrapper>
      </S.Td>
    ));

    const linkProps = rowLinkPrefix
      ? {
          as: Link,
          to: `${rowLinkPrefix}/${row.original[rowLinkIdProp || 'id']}`,
        }
      : {};

    return (
      <S.Tr
        {...linkProps}
        className="table_body_row"
        onClick={handleRowClick(row.original)}
        index={index}
        {...row.getRowProps()}
      >
        {rowContent}
      </S.Tr>
    );
  };
  return (
    <S.Table top={offsetTop} bottom={bottom} {...getTableProps()}>
      <S.Thead>
        {headerGroups.map((headerGroup) => (
          <S.Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <S.Th {...column.getHeaderProps(column.getSortByToggleProps())}>
                <S.HeaderContentWrapper>
                  <S.SortContainer clickable={Boolean(orderBy)} onClick={(event) => handleSort(event, column.id)}>
                    <div>
                      <b>{column.render('Header')}</b>
                    </div>
                    <div style={{ width: '16px' }}>
                      {column.isSorted ? (
                        <Icon icon={column.isSortedDesc ? IconNames.CARET_DOWN : IconNames.CARET_UP} />
                      ) : (
                        ''
                      )}
                    </div>
                  </S.SortContainer>
                  {column.Filter ? (
                    <S.FilterContainer onClick={(event) => event.stopPropagation()}>
                      {column.render('Filter')}
                    </S.FilterContainer>
                  ) : null}
                </S.HeaderContentWrapper>
              </S.Th>
            ))}
          </S.Tr>
        ))}
      </S.Thead>
      <S.Tbody {...getTableBodyProps()}>{page.map((row, index) => RenderRow({ row, index }))}</S.Tbody>
      {rows.length > pageSize && (
        <S.Tfoot>
          <S.PaginationContainer>
            <div>
              <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage} text="<<" />
            </div>
            <div>
              <Button onClick={() => previousPage()} disabled={!canPreviousPage} text="<" />
            </div>
            <div>
              <Button onClick={() => nextPage()} disabled={!canNextPage} text=">" />
            </div>
            <div>
              <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage} text=">>" />
            </div>
            <div>
              <span>Page</span>&nbsp;
              <b>
                {pageIndex + 1} of {pageOptions.length}
              </b>
            </div>
            <div>| Go to page:</div>
            <div>
              <InputGroup
                type="number"
                value={String(pageIndex + 1)}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: '70px' }}
              />
            </div>
            <div>
              <Select
                selectedValue={pageSize}
                onItemSelect={(item) => setPageSize(Number(item.value))}
                options={[10, 30, 50, 100].map((size) => ({ caption: `Show ${size}`, value: size }))}
              />
            </div>
          </S.PaginationContainer>
        </S.Tfoot>
      )}
    </S.Table>
  );
};
export const Table = <T extends {}>(props: TableProps<T>) => {
  const resizeDetector = useRef(null);
  return (
    <ReactResizeDetector
      ref={resizeDetector}
      handleHeight
      handleWidth
      render={({ width, height }: { width: number; height: number }): React.ReactElement => (
        <TableWrapped<T> width={width} height={height} resizeDetector={resizeDetector} {...props} />
      )}
    />
  );
};
