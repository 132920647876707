import styled from 'styled-components';

export const FormWrapper = styled.div`
  padding: 11px;
  /* height: 100%; */
  display: flex;

  form {
    width: 100%;
  }
`;

export const Column = styled.div`
  width: 50%;

  &:first-child {
    padding-right: 11px;
  }
`;

export const Fieldset = styled.fieldset`
  border-color: rgba(167, 182, 194, 0.5);
  border-radius: 3px;
  margin: 0 0 15px;
`;
