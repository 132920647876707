import React, { useCallback } from 'react';
import styled from 'styled-components';

type Event = React.MouseEvent | React.TouchEvent;

export interface ContainerBaseProps {
  className?: string;
  noPadding?: boolean;
  isSelected?: boolean;
  isColumnFlow?: boolean;
  children: React.ReactNode;
  onContainerClick?: (e?: any) => void;
}

export function ContainerBase({
  children,
  className,
  noPadding,
  isSelected,
  isColumnFlow,
  onContainerClick,
}: ContainerBaseProps) {
  const isClickable = Boolean(onContainerClick);

  const clickHandler = useCallback(
    (e: Event) => {
      if (isClickable) {
        if (onContainerClick) {
          onContainerClick(e);
        }
      }
    },
    [isClickable, onContainerClick]
  );

  return (
    <Container
      isSelected={isSelected}
      onClick={clickHandler}
      className={className}
      noPadding={noPadding}
      isClickable={isClickable}
      isColumnFlow={isColumnFlow}
    >
      {children}
    </Container>
  );
}

interface ContainerProps extends Pick<ContainerBaseProps, 'isSelected' | 'noPadding' | 'isColumnFlow'> {
  isClickable?: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  border-radius: 16px;
  background-color: #fff;
  transition: border 0.1s ease-in-out;
  cursor: ${({ isClickable }) => isClickable && 'pointer'};
  user-select: ${({ isClickable }) => isClickable && 'none'};
  padding: ${({ noPadding }) => (noPadding ? 'none' : '24px')};
  flex-direction: ${({ isColumnFlow }) => isColumnFlow && 'column'};
  border: 1px solid ${({ isSelected, theme }) => (isSelected ? theme.brand_color : '#dfe2f2')};
`;
