import React from 'react';
import { Button } from '@blueprintjs/core';
import { useHistory } from 'react-router-dom';
import { CashbackActionTable } from './cashback-action-table';
import * as S from './index.styles';
import { Tabs } from '../../ui/layouts/section-layout/components/tabs';
import { TabsRouter } from '../../ui/layouts/tabs-router';
import { CategoriesTable } from './categories-table';

export const CashbackActionPage = () => {
  const history = useHistory();

  return (
    <>
      <Tabs />
      <TabsRouter
        route="/cashback-action"
        tabs={[
          {
            caption: 'Кэшбэк-акции',
            component: (
              <S.Section>
                <S.Nav>
                  <Button intent="success" text="Загрузить" onClick={() => history.push(`/cashback-action/add`)} />
                </S.Nav>
                <S.Section>
                  <CashbackActionTable />
                </S.Section>
              </S.Section>
            ),
          },
          {
            caption: 'Категории',
            component: (
              <S.Section>
                <S.Nav>
                  <Button
                    intent="success"
                    text="Добавить"
                    onClick={() => history.push(`/cashback-action/categories/add`)}
                  />
                </S.Nav>
                <S.Section>
                  <CategoriesTable />
                </S.Section>
              </S.Section>
            ),
            path: '/categories',
          },
        ]}
      />
    </>
  );
};
