import { EntityTable, EntityTableColumn } from '../../ui/skeletons/entity-table';
import React from 'react';
import { Cell } from 'react-table';
import { Report, ReportStateEnum, ReportTypeEnum } from './api/report-types';
import { useReport } from './api/report-hooks';
import * as S from './index.styles';
import { Button } from '@blueprintjs/core';
import { API_URL_BASE_PATH } from '../../../api/base';
import moment from 'moment';

export interface ReportTableProps {
  type: ReportTypeEnum;
}

export const ReportTable = (props: ReportTableProps) => {
  const { type } = props;

  const handleDownload = (fileName: string) => {
    const url = `${API_URL_BASE_PATH}/report/file/${fileName}`;
    window.open(url, '_blank');
  };

  const columns: EntityTableColumn<Report>[] = React.useMemo(() => {
    return [
      {
        Header: 'Статус',
        accessor: 'reportState',
        id: 'reportState',
        width: 200,
        Cell: (cell: Cell) => cell.value,
      },
      {
        Header: 'Файл',
        accessor: 'fileName',
        id: 'fileName',
        Cell: (cell: Cell) => cell.value,
      },
      {
        Header: 'Создан (UTC)',
        accessor: 'createdWhen',
        id: 'createdWhen',
        width: 300,
        Cell: (cell: Cell) => moment.utc(cell.value).format('DD.MM.YYYY HH:mm:ss') || null,
      },
      {
        Header: 'Действия',
        width: 80,
        Cell: (cell: Cell) => (
          <Button
            intent="success"
            disabled={cell?.row?.values?.reportState !== ReportStateEnum.DONE}
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              handleDownload(cell?.row?.values?.fileName);
              event.stopPropagation();
            }}
          >
            Скачать
          </Button>
        ),
      },
    ];
  }, []);

  return (
    <S.Section>
      <EntityTable<Report & { id?: number }>
        useEntityList={useReport}
        params={[type]}
        columns={columns}
        initialSortBy="createdWhen"
        desc
      />
    </S.Section>
  );
};
