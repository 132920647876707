import React from 'react';
import { Button, IButtonProps } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

interface SumbitButtonProps extends Omit<IButtonProps, 'type'> {
  isSubmitting?: boolean;
}
export const SumbitButton = (props: SumbitButtonProps) => {
  const { isSubmitting, ...rest } = props;
  return (
    <div style={{ padding: '5px' }}>
      <Button
        type="submit"
        large
        text="Сохранить"
        intent="primary"
        loading={isSubmitting}
        icon={IconNames.SAVED}
        {...rest}
      />
    </div>
  );
};
