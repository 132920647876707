import React from 'react';
import { FormGroup, Button, MenuItem, IPopoverProps, InputGroupProps2 } from '@blueprintjs/core';
import { Select, ItemRenderer } from '@blueprintjs/select';
import { Field, FieldProps, useFormikContext } from 'formik';
import { FormikFieldProps } from './utils';
import './select-field.scss';

export interface SelectFieldOption<T> {
  value: T;
  caption: React.ReactNode;
  key?: unknown;
}
export interface SelectFieldProps<T> extends FormikFieldProps {
  options: SelectFieldOption<T>[];
  filterable?: boolean;
  placeholder?: string;
  onItemSelect?: (item: SelectFieldOption<T>, event?: React.SyntheticEvent<HTMLElement>) => void;
  popoverProps?: Partial<IPopoverProps> & object;
  inputProps?: InputGroupProps2;
  large?: boolean;
  fill?: boolean;
  onCompare?: (value1: T, value2: T) => boolean;
  style?: React.CSSProperties;
}

export function SelectField<T>(props: SelectFieldProps<T>) {
  const {
    name,
    label,
    inline,
    options = [],
    disabled,
    filterable = true,
    placeholder = '',
    onItemSelect,
    popoverProps,
    inputProps,
    large,
    fill = true,
    onCompare,
    style,
  } = props;
  const context = useFormikContext();

  const itemRenderer: ItemRenderer<SelectFieldOption<T>> = (item, { handleClick, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    if (
      !!query &&
      !item?.caption
        ?.toString()
        ?.toLowerCase()
        ?.includes(query?.toLowerCase())
    ) {
      return null;
    }

    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={String(item.key) || JSON.stringify(item.value)}
        onClick={handleClick}
        text={item.caption}
      />
    );
  };

  return (
    <Field name={name}>
      {({ field, meta, form }: FieldProps) => {
        // eslint-disable-next-line no-shadow
        const { name, value } = field;
        const handleItemSelect = (item: SelectFieldOption<T>, event?: React.SyntheticEvent<HTMLElement>) => {
          context.setFieldValue(name, item.value);
          if (onItemSelect) {
            onItemSelect(item, event);
          }
        };
        const activeItem = options.find((option) =>
          onCompare ? onCompare(option.value, value) : option.value === value
        );
        return (
          <FormGroup inline={inline} label={label} labelFor={name} labelInfo={meta.touched && meta.error} style={style}>
            <Select<SelectFieldOption<T>>
              disabled={disabled}
              items={options}
              filterable={filterable}
              // activeItem={activeItem}
              itemRenderer={itemRenderer}
              noResults={<MenuItem disabled text="No results" />}
              onItemSelect={handleItemSelect}
              inputProps={{ fill, name, ...inputProps }}
              popoverProps={{ fill, ...popoverProps }}
            >
              <Button
                large={large}
                disabled={disabled}
                fill={fill}
                text={activeItem?.caption || placeholder}
                rightIcon="double-caret-vertical"
                alignText="left"
              />
            </Select>
          </FormGroup>
        );
      }}
    </Field>
  );
}
