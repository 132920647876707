import React from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { IconNames } from '@blueprintjs/icons';
import { Tabs } from 'components/ui/layouts/section-layout/components/tabs';
import { TabsRouter } from 'components/ui/layouts/tabs-router';
import { Tab } from 'components/ui/layouts/tabs-router/tabs-router-types';
import { ClientCard } from './client-card';
import { ClientFormData } from './api/client-types';
import { ClientForm } from './client-form';
import { ClientFormLayout } from './client-form-layout';
import { useClientFormData, useClientUpdate } from './api/client-hooks';
import { userSelector } from '../../../selectors/auth';

export const ClientPage = () => {
  const { mnemocode } = useParams<{ [key: string]: string }>();
  const user = useSelector(userSelector);
  const tabs: Tab[] = [
    {
      caption: 'Карточка',
      component: <ClientCard />,
    },
  ];
  /*
  if (
    user.availableSections
      .find((section: any) => section.path === 'client')
      .actions.find((action: string) => action === 'updateClient')
  ) {
    tabs.push({
      path: '/form',
      caption: 'Форма',
      component: (
        <ClientFormLayout<ClientFormData>
          paramName="mnemocode"
          icon={IconNames.PROJECTS}
          sectionText="Партнеры"
          getEntityText={(data) => data?.primary_phone}
          component={ClientForm}
          useEntity={useClientFormData}
          useEntityMutate={useClientUpdate}
        />
      ),
    });
  }
  */
  return (
    <>
      <div style={{ padding: '10px', fontSize: '20px' }}>
        <span>{'< '}</span>
        <Link to="/client">назад</Link>
      </div>
      <Tabs />
      <TabsRouter route={`/client/${mnemocode}`} tabs={tabs} />
    </>
  );
};
