import * as _ from 'lodash';

/**
 * Null Undefined String Boolean Number Array Object Date File Blob...
 */
export function getType(value: unknown): string {
  return Object.prototype.toString.call(value).match(/^\[object\s(?<type>(.*))\]$/)?.groups?.type as string;
}

export function FormObject(object: unknown, property?: string): object | undefined {
  const type = getType(object);
  return ['Object', 'Array'].includes(type)
    ? _.isEmpty(object)
      ? { [property as any]: '' }
      : _.reduce(
          object as object,
          (result, value, key) => ({
            ...result,
            ...FormObject(value, property ? `${property}[${key}]` : `${key}`),
          }),
          {}
        )
    : property
    ? { [property]: object }
    : {};
}
