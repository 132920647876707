import React from 'react';
import { useParams } from 'react-router-dom';
import { Button, Card } from '@blueprintjs/core/lib/esm/components';
import { Formik, Form, FieldArray } from 'formik';
import {
  SwitchField,
  SumbitButton,
  NumericSelectField,
  InputField,
  NumericInputField,
} from 'components/ui/formik-controls';
import { createUseProgramList } from 'api/program-hooks';
import { ProductProgram } from './api/product-program-types';
import { ProductProgramSchema } from './product-program-schema';
import * as S from './product-program-form.styles';

const emptyReward = { title: '', details: '' };
const emptySpendingOption = {
  points: 0,
  spendingstart: 0,
  spendingend: 0,
};
const emptyCalculatorOption = {
  label: ' ',
  min: 0,
  max: 0,
  pointsmax: 0,
  spending: 0,
  spendingoptions: [emptySpendingOption],
  step: 0,
  tooltip: ' ',
  type: ' ',
};
const emptyData = {
  mnemocode: '',
  isActive: true,
  productId: 0,
  programId: 0,
  components: {
    calculatormax: '',
    calculatoroptions: [emptyCalculatorOption],
    pointstorubles: 0,
    rewards: [emptyReward],
  },
};

type AfterSubmitHandler = (data?: ProductProgram) => void;
type ProductFormProps = {
  initialValues?: ProductProgram;
  onSubmit: (data: ProductProgram) => Promise<ProductProgram | undefined>;
  onAfterSubmit?: AfterSubmitHandler;
};
export const ProductProgramForm = (props: ProductFormProps) => {
  const { initialValues = emptyData, onSubmit, onAfterSubmit } = props;
  const { partnerId, productProgramId } = useParams<{ [key: string]: string }>();
  const useProgramList = createUseProgramList(Number(partnerId));
  const { data } = useProgramList();
  const programOptions = data
    ? data.map(({ id, components }) => ({ value: id || 0, caption: components.name?.toString() || '' }))
    : [{ value: 0, caption: '' }];

  return (
    <Formik<ProductProgram>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={ProductProgramSchema}
      onSubmit={async (selectedProgram, { setSubmitting }) => {
        const program = data?.find((item) => item.id === selectedProgram.programId);
        const result = await onSubmit({ ...selectedProgram, program });
        setSubmitting(false);
        if (!result) {
          return;
        }
        if (onAfterSubmit) {
          onAfterSubmit();
        }
      }}
    >
      {({ values, isSubmitting }) => (
        <Form>
          <S.FormWrapper>
            <S.Column>
              <NumericSelectField
                disabled={productProgramId !== 'add'}
                name="programId"
                label="Программа"
                options={programOptions}
              />

              <SwitchField name="isActive" label="Активен" />

              <InputField name="components.calculatormax" label="Calculator max" />
              <NumericInputField name="components.pointstorubles" label="Points to rubles" />
              <FieldArray
                name="components.rewards"
                render={(arrayHelpers) => (
                  <>
                    <div>Rewards</div>
                    <Card>
                      {values.components.rewards.map((_, index) => {
                        const reward = `components.rewards[${index}]`;
                        return (
                          // eslint-disable-next-line react/no-array-index-key
                          <S.TableRowWithButton key={index}>
                            <InputField name={`${reward}.title`} label={index === 0 ? 'Title' : undefined} />
                            <InputField name={`${reward}.details`} label={index === 0 ? 'Details' : undefined} />
                            <Button onClick={() => arrayHelpers.remove(index)} icon="minus" />
                          </S.TableRowWithButton>
                        );
                      })}
                      <div>
                        <Button onClick={() => arrayHelpers.push(emptyReward)} icon="plus" />
                      </div>
                    </Card>
                  </>
                )}
              />
            </S.Column>
          </S.FormWrapper>
          <SumbitButton isSubmitting={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};
