import React from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { createUseProductList } from 'api/product-hooks';
import { Product } from 'api/product-types';
import { TableLayout } from 'components/ui/layouts/table-layout';
import { EntityTableColumn } from 'components/ui/layouts/table-layout/table-layout-types';
import { PartnerProductPage } from './partner-product-page';

export const PartnerProductsTable = ({ paramName }: { paramName: string }) => {
  const { partnerId } = useParams<{ [key: string]: string }>();
  const useProductList = createUseProductList(Number(partnerId));
  const { url } = useRouteMatch();

  const columns: EntityTableColumn<Product>[] = React.useMemo(
    () => [
      {
        Header: 'Мнемокод',
        accessor: 'mnemocode',
        id: 'mnemocode',
      },
      {
        Header: 'Название',
        accessor: 'components',
        id: 'name',
        Cell: ({ value }) => value?.name,
      },
      {
        Header: 'Тип',
        accessor: 'components',
        id: 'payment_org',
        Cell: ({ value }) => value?.payment_org || '',
      },
      {
        Header: 'Активна',
        accessor: 'isActive',
        Cell: ({ value }) => (value ? 'Да' : 'Нет'),
      },
      {
        Header: 'Класс',
        accessor: 'product_class',
        Cell: ({ value }) => value || '',
      },
    ],
    []
  );

  return (
    <TableLayout<Product>
      paramName={paramName}
      caption="Продукты"
      useEntityList={useProductList}
      entityComponent={() => <PartnerProductPage paramName={paramName} />}
      columns={columns}
      rowLinkPrefix={url}
      initialSortBy="name"
    />
  );
};
