import React, { useState, useEffect } from 'react';
import moment from 'moment';
import * as _ from 'lodash';
import { Column } from 'react-table';
import { useParams } from 'react-router-dom';
import { Table } from '../../ui/table';
import { Select } from '../../ui/form-controls/Select';
import { Bonus } from './api/client-types';
import { clientApi } from './api/client';
import * as S from './index.styles';
import { Loader } from '../../ui/shared/loader';

export enum GoalType {
  TRANSACTIONAL = 'TRANSACTIONAL',
  WELCOME = 'WELCOME',
  PROMOTIONAL = 'PROMOTIONAL',
  CORRECTIVE = 'CORRECTIVE',
  ONLINE_CLO = 'ONLINE_CLO',
  EXCHANGE_ACCRUAL = 'EXCHANGE_ACCRUAL',
  EXCHANGE_WITHDRAWAL = 'EXCHANGE_WITHDRAWAL',
  BURNOUT = 'BURNOUT',
}

const goalTypeMap = {
  [GoalType.TRANSACTIONAL]: 'Начисление бонусов',
  [GoalType.WELCOME]: 'Приветственные бонусы',
  [GoalType.PROMOTIONAL]: 'Промо бонусы',
  [GoalType.CORRECTIVE]: 'Корректировка бонусов',
  [GoalType.ONLINE_CLO]: 'Бонусы по спецпредложениям',
  [GoalType.EXCHANGE_ACCRUAL]: 'Начисление при обмене',
  [GoalType.EXCHANGE_WITHDRAWAL]: 'Списание при обмене',
  [GoalType.BURNOUT]: 'Сгорание бонусов',
};

const monthOptions = Array.from(Array(12).keys())
  .map((i) => new Date(0, i).toLocaleString('ru-RU', { month: '2-digit' }))
  .map((month) => ({
    caption: month,
    value: month,
  }));

const yearOptions = [
  moment()
    .year()
    .toString(),
  moment()
    .subtract(1, 'year')
    .year()
    .toString(),
].map((year) => ({
  caption: year,
  value: year,
}));

interface StateInterface {
  clientMnemocode: string | undefined;
  period: string | undefined;
  currency: string | undefined;
  bonus: Bonus | undefined;
}

const initialState: StateInterface = {
  clientMnemocode: undefined,
  period: moment().format('YYYY-MM'),
  currency: undefined,
  bonus: {
    accrual: 0,
    precalc: 0,
    hold: 0,
    error: 0,
    chargeOff: 0,
    amount: 0,
    totalProfit: 0,
    cards: [],
    movements: [],
  },
};

export const ClientBonus = () => {
  const { clientMnemocode, currency } = useParams();

  _.assign(initialState, { clientMnemocode, currency });

  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState<StateInterface>(() => initialState);

  const updState = (nextState: Partial<StateInterface>) => setState((prevState) => _.assign({}, prevState, nextState));

  const cardsСolumns = React.useMemo<Column<{ number: string }>[]>(
    () => [
      {
        Header: 'Номер',
        accessor: 'number',
      },
    ],
    []
  );
  const movementsСolumns = React.useMemo(
    () => [
      {
        Header: 'Тип',
        accessor: (row: { goalType: string } | { goalName: string }) =>
          'goalType' in row ? row.goalType : row.goalName,
        width: 400,
        Cell: ({ value }: { value: GoalType | string }) =>
          value in goalTypeMap ? goalTypeMap[value as GoalType] : value,
      },
      {
        Header: 'Предрасчет',
        accessor: 'precalc',
      },
      {
        Header: 'Зачислено',
        accessor: 'posted',
      },
      {
        Header: 'Ожидает зачисления',
        accessor: 'hold',
      },
      {
        Header: 'Ошибка зачисления',
        accessor: 'error',
      },
    ],
    []
  );

  useEffect(() => {
    if (state.clientMnemocode && state.period && state.currency) {
      setIsLoading(true);
      clientApi
        .getBonus(state.clientMnemocode, state.period, state.currency)
        .then((bonus) => {
          updState({ bonus });
        })
        .finally(() => setIsLoading(false));
    }
  }, [state.clientMnemocode, state.period, state.currency]);
  return (
    <S.Section>
      {isLoading ? (
        <S.LoaderWrapper>
          <Loader />
        </S.LoaderWrapper>
      ) : (
        <S.Section>
          <div style={{ padding: '1em' }}>
            <div style={{ padding: '1em 0' }}>
              <div style={{ fontSize: '160%', fontWeight: 'bold' }}>Бонусы за </div>
              <Select
                options={monthOptions}
                onItemSelect={(item) =>
                  updState({
                    period: moment(state.period, 'YYYY-MM')
                      .month(Number(item.value) - 1)
                      .format('YYYY-MM'),
                  })
                }
                selectedValue={moment(state.period, 'YYYY-MM').format('MM')}
              />
              <Select
                options={yearOptions}
                onItemSelect={(item) =>
                  updState({
                    period: moment(state.period, 'YYYY-MM')
                      .year(Number(item.value))
                      .format('YYYY-MM'),
                  })
                }
                selectedValue={moment(state.period, 'YYYY-MM').format('YYYY')}
              />
            </div>
            <div>
              <p>
                <b>Баланс:</b> <b style={{ color: 'green' }}>{state.bonus?.amount}</b>
              </p>
              <p>
                <b>Предрасчет:</b> <b style={{ color: 'green' }}>{state.bonus?.precalc}</b>
              </p>
              <p>
                <b>Зачислено:</b> <b style={{ color: 'green' }}>{state.bonus?.accrual}</b>
              </p>
              <p>
                <b>Ожидает зачисления:</b> <b style={{ color: 'orange' }}>{state.bonus?.hold}</b>
              </p>
              <p>
                <b>Ошибка зачисления:</b> <b style={{ color: 'red' }}>{state.bonus?.error}</b>
              </p>
              <p>
                <b>Сконвертировано:</b> <b style={{ color: 'green' }}>{state.bonus?.chargeOff}</b>
              </p>
            </div>
          </div>

          <div style={{ fontSize: '130%', fontWeight: 'bold' }}>Карты</div>
          <div style={{ height: `${((state.bonus?.cards?.length || 0) + 1) * 40}px` }}>
            <Table initialState={{}} data={state.bonus?.cards || []} columns={cardsСolumns} />
          </div>
          <Table initialState={{}} data={state.bonus?.movements || []} columns={movementsСolumns} />
        </S.Section>
      )}
    </S.Section>
  );
};
