import React from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { TableLayout } from 'components/ui/layouts/table-layout';
import { EntityTableColumn } from 'components/ui/layouts/table-layout/table-layout-types';
import { createUseProductProgramList } from './api/product-program-hooks';
import { ProductProgramPage } from './product-program-page';
import { ProductProgram } from './api/product-program-types';

export const ProductProgramsTable = ({ paramName }: { paramName: string }) => {
  const pathParams = useParams<{ [key: string]: string }>();
  const useProductProgramList = createUseProductProgramList(Number(pathParams.partnerId), Number(pathParams.productId));
  const { url } = useRouteMatch();
  const columns: EntityTableColumn<ProductProgram>[] = React.useMemo(
    () => [
      {
        Header: 'Программа',
        accessor: 'program',
        id: 'programName',
        Cell: ({ value }) => value?.components.name || '',
      },
      {
        Header: 'Активна',
        accessor: 'isActive',
        Cell: ({ value }) => (value ? 'Да' : 'Нет'),
      },
    ],
    []
  );

  return (
    <TableLayout<ProductProgram>
      caption="Программы"
      paramName={paramName}
      useEntityList={useProductProgramList}
      entityComponent={() => <ProductProgramPage paramName={paramName} />}
      columns={columns}
      rowLinkPrefix={url}
      initialSortBy="name"
    />
  );
};
