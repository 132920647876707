import moment from 'moment';
import React from 'react';
import { URL } from 'utils/url-builder';
import { EntityTable, EntityTableColumn } from 'components/ui/skeletons/entity-table';
import { useActionList } from './api/action-hooks';
import { Action } from './api/action-types';

export const ActionsTable = () => {
  const columns: EntityTableColumn<Action>[] = React.useMemo(
    () => [
      {
        Header: 'id',
        accessor: 'id',
      },
      {
        Header: 'время (МСК)',
        accessor: 'time',
        Cell: ({ value }) => moment.utc(value).format('HH:mm:ss, DD.MM.yyyy') || null,
      },
      {
        Header: 'логин',
        accessor: 'login',
      },
      {
        Header: 'событие',
        accessor: 'action',
      },
      {
        Header: 'результат',
        accessor: 'result',
      },
    ],
    []
  );

  return (
    <EntityTable<Action>
      useEntityList={useActionList}
      entityEditUrl={URL.actionView}
      columns={columns}
      initialSortBy="time"
      desc
    />
  );
};
