import React from 'react';
import { IconWireFrame, IconWireFrameType } from 'components/ui/icon';
import type { Icon } from '@whitelabel/common/dist/api';
import { getType } from 'utils';
import { IconProps } from './types';

export const IconPreview = ({ icon, size, color }: IconProps) => {
  const type = getType(icon);

  switch (type) {
    case 'File':
      return <img height={`${size}px`} width={`${size}px`} src={URL.createObjectURL(icon as File)} alt="" />;
    case 'String':
      return <img height={`${size}px`} width={`${size}px`} src={icon as string} alt="" />;
    case 'Object':
      return <IconWireFrame icon={(icon as Icon).name as IconWireFrameType} size={size} color={color} />;
    default:
      return null;
  }
};
