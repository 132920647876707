import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { URL } from '../../../utils/url-builder';
import { BonusRegistryPage } from './bonus-registry-page';
import { BonusRegistryAddPage } from './bonus-registry-add-page';
import { BonusRegistryErrorPage } from './bonus-registry-error-page';
import { IconNames } from '@blueprintjs/icons';
import * as S from './index.styles';
import { BreadcrumbRouteProps, BreadcrumbsRoute } from '../../ui/breadcrumbs-route';

const routes: BreadcrumbRouteProps[] = [
  { path: `${URL.bonusRegistrySection()}`, icon: IconNames.LIST_DETAIL_VIEW, text: 'Реестр' },
  { path: `${URL.bonusRegistrySection()}/add`, text: 'Загрузка реестров' },
  { path: `${URL.bonusRegistrySection()}/error/:id`, text: 'Список ошибок' },
];

export const BonusRegistryRouter = () => {
  return (
    <S.Section>
      <S.Header>
        <BreadcrumbsRoute routes={routes} />
      </S.Header>
      <S.Nav></S.Nav>
      <Switch>
        <Route path={`${URL.bonusRegistrySection()}`} exact component={BonusRegistryPage} />
        <Route path={`${URL.bonusRegistrySection()}/add`} component={BonusRegistryAddPage} />
        <Route path={`${URL.bonusRegistrySection()}/error/:id`} component={BonusRegistryErrorPage} />
      </Switch>
    </S.Section>
  );
};
