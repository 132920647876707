import React from 'react';
import * as S from './content-wrapper.styles';

type ContentWrapperProps = {
  children?: React.ReactNode;
};
export const ContentWrapper = (props: ContentWrapperProps) => {
  const { children } = props;
  return <S.Wrapper>{children}</S.Wrapper>;
};
