/* eslint-disable @typescript-eslint/camelcase */
import * as Yup from 'yup';
import { errorMessages } from 'components/ui/formik-controls';
import { CombinationsPeriodEnum, PositionEnum } from '../../../../api/modal-types';

export const PartnerModalSchema = Yup.object().shape({
  isActive: Yup.boolean(),
  title: Yup.string().required(errorMessages.required),
  position: Yup.mixed<PositionEnum>()
    .oneOf([...Object.values(PositionEnum)])
    .required(errorMessages.required),
  description: Yup.string()
    .optional()
    .nullable(),
  icon: Yup.string()
    .transform((value: any, originalValue: any) => originalValue?.name)
    .optional()
    .nullable(),
  button: Yup.string()
    .optional()
    .nullable(),
  buttonLink: Yup.string()
    .optional()
    .nullable(),
  clients: Yup.array()
    .optional()
    .nullable(),
  products: Yup.array()
    .optional()
    .nullable(),
  combinations: Yup.array()
    .optional()
    .nullable(),
  combinationsPeriod: Yup.array().when('combinations', {
    is: (val) => Boolean(val?.length),
    then: (schema: Yup.ArraySchema<unknown>) => schema.required(errorMessages.required),
    otherwise: (schema: Yup.ArraySchema<unknown>) => schema.optional().nullable()
  }),
  options: Yup.array()
    .optional()
    .nullable(),
  optionsPeriod: Yup.array().when('options', {
    is: (val) => Boolean(val?.length),
    then: (schema: Yup.ArraySchema<unknown>) => schema.required(errorMessages.required),
    otherwise: (schema: Yup.ArraySchema<unknown>) => schema.optional().nullable()
  }),
  path: Yup.string()
    .optional()
    .nullable(),
  appearOnEnter: Yup.boolean().required(errorMessages.required),
  appearancesCount: Yup.number().required(errorMessages.required),
  dateStart: Yup.string()
    .optional()
    .nullable(),
  dateEnd: Yup.string()
    .optional()
    .nullable(),
  timer: Yup.number()
    .optional()
    .nullable(),
  image: Yup.string()
    .transform((value: any, originalValue: any) =>
      originalValue instanceof File ? originalValue?.name : originalValue
    )
    .when('position', {
      is: PositionEnum.HOME_BANNER,
      then: (schema: any) => schema.required(errorMessages.required),
      otherwise: (schema: any) => schema.optional(),
    })
    .nullable(),
  order: Yup.number()
    .when('position', {
      is: PositionEnum.HOME_BANNER,
      then: (schema: any) => schema.required(errorMessages.required),
      otherwise: (schema: any) => schema.optional(),
    })
    .nullable(),
});
