import React from 'react';
import moment from 'moment';
import * as S from './hooks.styles';
import * as Yup from 'yup';
import { DateInputField, errorMessages } from '../../../ui/formik-controls';
import { FormFieldsFunction } from './form-fields';
import { ActiveCardsSummaryInterface } from '../api/report-types';

const state: Partial<ActiveCardsSummaryInterface> = {
  reportPeriodStart: undefined,
  reportPeriodFinish: undefined,
};

const schema: Yup.Schema<ActiveCardsSummaryInterface | undefined> = Yup.object().shape({
  reportPeriodStart: Yup.string().required(errorMessages.required),
  reportPeriodFinish: Yup.string().required(errorMessages.required),
});

export const useActiveCardsSummary: FormFieldsFunction<Partial<ActiveCardsSummaryInterface>> = () => {
  function render() {
    return (
      <S.FormWrapper>
        <S.Column>
          <DateInputField
            name="reportPeriodStart"
            label="Дата начала периода"
            placeholder="YYYY-MM-DD"
            formatDate={(date) => moment(date).format('YYYY-MM-DD')}
            parseDate={(str) => moment(str, 'YYYY-MM-DD').toDate()}
          />
          <DateInputField
            name="reportPeriodFinish"
            label="Дата окончания периода"
            placeholder="YYYY-MM-DD"
            formatDate={(date) => moment(date).format('YYYY-MM-DD')}
            parseDate={(str) => moment(str, 'YYYY-MM-DD').toDate()}
          />
        </S.Column>
      </S.FormWrapper>
    );
  }

  return {
    state,
    schema,
    render,
  };
};
