import styled from 'styled-components';

export const Section = styled.section`
  height: 100%;
  overflow-y: hidden;
`;

export const Header = styled.header`
  margin: 10px;
`;

export const Nav = styled.nav`
  margin: 10px;
`;
