import { Action, createReducer } from 'typesafe-actions';
import { setTabs } from 'actions';
import { TabItem } from 'components/ui/layouts/section-layout/section-layout-types';

export interface TabsState {
  readonly path: string;
  readonly items: TabItem[];
}

const initialState: TabsState = {
  path: '',
  items: [] as TabItem[],
};

export default createReducer<TabsState, Action>(initialState).handleAction(setTabs, (state, { payload }) => {
  if (state.path === payload.path) {
    return state;
  }
  return { path: payload.path, items: [...payload.items] };
});
