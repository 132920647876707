import React from 'react';
import { FormGroup, Button, MenuItem } from '@blueprintjs/core';
import { Select, ItemRenderer } from '@blueprintjs/select';
import { Field, FieldProps, useFormikContext } from 'formik';
import { FormikFieldProps } from './utils';
import { highlightText } from './numeric-select-field-utils';
import * as S from './numeric-select-field.styles';

interface NumericSelectOption {
  value: number | string | any;
  caption: string;
  key?: any;
}
interface NumericSelectFieldProps extends FormikFieldProps {
  options: NumericSelectOption[];
  filterable?: boolean;
  placeholder?: string;
  onItemSelect?: (item: any, event?: React.SyntheticEvent<HTMLElement>) => void;
}
const NumericSelect = Select.ofType<NumericSelectOption>();
const renderSuggestItem: ItemRenderer<NumericSelectOption> = (item, { handleClick, modifiers, query }) => {
  if (!modifiers.matchesPredicate) {
    return null;
  }

  return (
    <S.MenuItem
      active={modifiers.active}
      disabled={modifiers.disabled}
      key={item.key || item.value}
      onClick={handleClick}
      text={highlightText(item.caption, query)}
    />
  );
};

export const NumericSelectField = (props: NumericSelectFieldProps) => {
  const { name, label, inline, options, disabled, filterable = true, placeholder = '', onItemSelect } = props;
  const context = useFormikContext();

  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => {
        // eslint-disable-next-line no-shadow
        const { name, value } = field;
        const handleItemSelect = (item: NumericSelectOption, event?: React.SyntheticEvent<HTMLElement>) => {
          context.setFieldValue(name, item.value);
          if (onItemSelect) {
            onItemSelect(item, event);
          }
        };
        const activeItem = options.find((option) => option.value === value);
        return (
          <FormGroup inline={inline} label={label} labelFor={name} labelInfo={meta.touched && meta.error}>
            <NumericSelect
              disabled={disabled}
              items={options}
              filterable={filterable}
              // activeItem={activeItem}
              itemRenderer={renderSuggestItem}
              noResults={<MenuItem disabled text="No results" />}
              onItemSelect={handleItemSelect}
              inputProps={{ fill: true }}
              popoverProps={{ fill: true }}
            >
              <Button
                disabled={disabled}
                fill
                text={<S.SelectButtonText>{activeItem?.caption || placeholder}</S.SelectButtonText>}
                rightIcon="double-caret-vertical"
                alignText="left"
              />
            </NumericSelect>
          </FormGroup>
        );
      }}
    </Field>
  );
};
