import styled from 'styled-components';

export const Section = styled.section`
  height: 100%;
`;

export const LoaderWrapper = styled.div`
  height: 100%;
  width: 100%;
`;
