import React from 'react';
import { useParams } from 'react-router-dom';

import { useOffer } from './api/offer-hooks';

export const OfferPage = () => {
  const { id } = useParams<{ [key: string]: string }>();
  const { data } = useOffer(Number(id));
  const description = data?.offer.data.description || '';
  return (
    <>
      <p>
        <b>- Название: </b>
        {data?.offer?.name}
      </p>
      <p>
        <b>- Принадлежность банку: </b>
        {data?.partner?.components?.name}
      </p>
      <p>
        <b>- Активность: </b>
        {data?.offer?.isActive ? 'активный' : 'не активный'}
      </p>
      <p>
        <b>- онлайн/оффлайн: </b>
        {data?.type === 'online' ? 'онлайн' : 'оффлайн'}
      </p>
      <p>
        <b>- Партнерская ссылка: </b>
        {data?.offer?.externalLink}
      </p>
      <p>
        <b>- Описание: </b>
        <span dangerouslySetInnerHTML={{ __html: description }} />
      </p>
      <p>
        <b>- Размер платежа: </b>
        {data?.offer?.data?.payment_size}
      </p>
    </>
  );
};
