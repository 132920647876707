import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { URL } from 'utils/url-builder';

type SectionPageProps = {
  sectionListUrl: string;
  listComponent: () => JSX.Element;
  pageComponent?: () => JSX.Element;
};
export const SectionPage = (props: SectionPageProps) => {
  const { sectionListUrl, listComponent, pageComponent } = props;
  return (
    <Switch>
      <Route path={URL.listUrl()} component={listComponent} />
      {pageComponent && <Route path={URL.sectionPagePaths} component={pageComponent} />}
      <Redirect to={sectionListUrl} />
    </Switch>
  );
};
