import styled from 'styled-components';

export const FormWrapper = styled.div`
  padding: 11px;
  /* height: 100%; */
  display: flex;

  form {
    width: 100%;
  }
`;

export const Column = styled.div`
  width: 50%;

  &:first-child {
    padding-right: 11px;
  }
`;

export const SubheaderWrap = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: #10161a2b;
  padding: 10px 5px 10px 20px;
`;

export const Subheader = styled.h2`
  margin: 0;
`;

export const Table = styled.table`
  border-collapse: collapse;
`;

export const Td = styled.td`
  border: 1px solid black;
  padding: 5px;
`;

export const Th = styled.th`
  border: 1px solid black;
  padding: 5px;
`;

export const TdCenter = styled(Td)`
  text-align: center;
`;

export const TdTop = styled(Td)`
  vertical-align: top;
`;

export const InputWrapper = styled.div`
  margin: 10px;
`;

export const ModalWrapper = styled.div`
  padding: 1em;
  width: 500px;
`;
