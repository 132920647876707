import React from 'react';
import * as S from './page-skeleton.styles';

type PageSkeletonProps = {
  breadcrumbs: React.ReactNode;
  actions?: React.ReactNode;
  content: React.ReactNode;
};

export const PageSkeleton = (props: PageSkeletonProps) => {
  const { breadcrumbs, actions, content } = props;
  return (
    <>
      <div style={{ padding: '5px' }}>{breadcrumbs}</div>
      <div style={{ padding: '5px' }}>{actions}</div>
      <div style={{ overflowY: 'hidden', height: '100%', marginTop: '10px' }}>{content}</div>
    </>
  );
};
