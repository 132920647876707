export const errorMessages = {
  required: 'поле обязательно для заполнения',
  email: 'введите корректный email',
  onlyDigits: 'только цифры',
  passwordsNotMatch: 'пароли не совпадают',
  passwordComplexity:
    'не соответствует требованиям сложности: (A-Z, a-z, 0-9, специальные символы), длина не менее 8 символов',
  invalidDate: 'некорректная дата',
};

export interface FormikFieldProps {
  name: string;
  label?: string;
  inline?: boolean;
  disabled?: boolean;
}
