"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Role = void 0;
var Role;
(function (Role) {
    Role["SUDO"] = "SUDO";
    Role["USER"] = "USER";
    Role["AGENT"] = "AGENT";
    Role["ADMIN"] = "ADMIN";
    Role["ACL"] = "ACL";
    Role["GPADMIN"] = "GPADMIN";
    Role["GPUSER"] = "GPUSER";
})(Role = exports.Role || (exports.Role = {}));
