import React from 'react';
import { useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { InputField, SwitchField, SumbitButton, NumericSelectField } from 'components/ui/formik-controls';
import { PartnerWidgetSchema } from './partner-widget-schema';
import * as S from './partner-widget-form.styles';
import { PartnerWidget } from './api/widget-types';
import { createUseAllWidgetList } from './api/widget-hooks';

const emptyData = {
  isActive: false,
  position: '',
  widgetId: 0,
};

type AfterSubmitHandler = (data?: PartnerWidget) => void;
type ProductFormProps = {
  initialValues?: PartnerWidget;
  onSubmit: (user: PartnerWidget) => Promise<PartnerWidget | undefined>;
  onAfterSubmit?: AfterSubmitHandler;
};
export const PartnerWidgetForm = (props: ProductFormProps) => {
  const { initialValues = emptyData, onSubmit, onAfterSubmit } = props;
  const { widgetId } = useParams<{ [key: string]: string }>();
  const useAllWidgetList = createUseAllWidgetList();
  const { data } = useAllWidgetList();
  const widgetOptions = data
    ? data.map(({ id, name }) => ({ value: id || 0, caption: name || '' }))
    : [{ value: 0, caption: '' }];
  return (
    <Formik<PartnerWidget>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={PartnerWidgetSchema}
      onSubmit={async (partnerWidget, { setSubmitting }) => {
        const result = await onSubmit(partnerWidget);
        setSubmitting(false);
        if (!result) {
          return;
        }
        if (onAfterSubmit) {
          onAfterSubmit();
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <S.FormWrapper>
            <S.Column>
              <NumericSelectField
                disabled={widgetId !== 'add'}
                name="widgetId"
                label="Виджет"
                options={widgetOptions}
              />
              <InputField name="position" label="Позиция" />
              <SwitchField name="isActive" label="Активен" />
            </S.Column>
          </S.FormWrapper>
          <SumbitButton isSubmitting={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};
