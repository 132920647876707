import React from 'react';
import { BonusRegistryTable } from './bonus-registry-table';
import { Button } from '@blueprintjs/core';
import { useHistory } from 'react-router-dom';
import * as S from './index.styles';

export const BonusRegistryPage = () => {
  const history = useHistory();

  return (
    <S.Section>
      <S.Nav>
        <Button intent="success" text="Загрузить реестр" onClick={() => history.push(`/bonus-registry/add`)} />
      </S.Nav>
      <S.Section>
        <BonusRegistryTable />
      </S.Section>
    </S.Section>
  );
};
