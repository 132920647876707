import React from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import { isLogged } from 'selectors/auth';
import { URL } from 'utils/url-builder';
import ProtectedRoute from 'components/ui/protected-route';
import { SignInPage } from 'components/pages/sign-in';
import { MainPage } from 'components/pages/main';

const App = () => {
  const logged = useSelector(isLogged);

  if (logged === undefined) {
    return null;
  }

  return (
    <Switch>
      <ProtectedRoute allowed={!logged} redirectToUrl={URL.home()} path={URL.signIn()} component={SignInPage} />
      <ProtectedRoute allowed={logged} redirectToUrl={URL.signIn()} component={MainPage} />
    </Switch>
  );
};

export default App;
