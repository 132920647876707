import * as _ from 'lodash';
import { BaseApi } from '../../../../api/base';
import {
  CashbackActionListResponse,
  CashbackActionMerchantResponse,
  CashbackActionMerchantListResponse,
  CashbackActionResponse,
  CashbackActionUpdate,
  CashbackActionMerchantUpdate,
  CashbackActionCategory,
} from './cashback-action-types';
import { FormObject } from '../../../../utils';

class CashbackActionApi extends BaseApi {
  getList = async (): Promise<CashbackActionListResponse> => {
    const result = await this.fetch<CashbackActionListResponse>(`/cashback-action`);
    return result;
  };

  getItem = async (id: number): Promise<CashbackActionResponse> => {
    const result = await this.fetch<CashbackActionResponse>(`/cashback-action/${id}`);
    return result;
  };

  editItem = async (id: number, data: CashbackActionUpdate): Promise<void> => {
    const formData = new FormData();
    const formObject = FormObject(data);
    for (const [name, value] of Object.entries(formObject as Record<string, any>)) {
      if (value !== null && value !== undefined) {
        formData.append(name, value);
      }
    }

    await this.fetch<void>(`/cashback-action/${id}`, {
      method: 'put',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    });
  };

  deleteItem = async (id: number): Promise<void> => {
    await this.fetch<any>(`/cashback-action/${id}`, { method: 'DELETE' });
  };

  getMerchantList = async (id: number): Promise<CashbackActionMerchantListResponse> => {
    const result = await this.fetch<CashbackActionMerchantListResponse>(`/cashback-action/${id}/merchants`);
    return result;
  };

  addMerchantItem = async (id: number, data: CashbackActionMerchantUpdate): Promise<void> => {
    await this.fetch<void>(`/cashback-action/${id}/merchants`, {
      method: 'POST',
      data,
    });
  };

  getMerchantItem = async (id: number, merchantId: number): Promise<CashbackActionMerchantResponse> => {
    const result = await this.fetch<CashbackActionMerchantResponse>(`/cashback-action/${id}/merchants/${merchantId}`);
    return result;
  };

  editMerchantItem = async (id: number, merchantId: number, data: CashbackActionMerchantUpdate): Promise<void> => {
    await this.fetch<void>(`/cashback-action/${id}/merchants/${merchantId}`, {
      method: 'PUT',
      data,
    });
  };

  deleteMerchantItem = async (id: number, merchantId: number): Promise<void> => {
    await this.fetch<any>(`/cashback-action/${id}/merchants/${merchantId}`, { method: 'DELETE' });
  };

  getCategories = async (): Promise<CashbackActionCategory[]> => {
    // eslint-disable-next-line no-return-await
    return await this.fetch<CashbackActionCategory[]>('/cashback-action/categories');
  };

  getCategory = async (id: string): Promise<CashbackActionCategory> => {
    const categories = await this.getCategories();

    return _.find(categories, { id: parseInt(id, 10) })!;
  };

  deleteCategory = async (id: number): Promise<void> => {
    await this.fetch<any>(`/cashback-action/categories/${id}`, { method: 'DELETE' });
  };

  addCategory = async (data: Omit<CashbackActionCategory, 'id' | 'temporal'>): Promise<void> => {
    const formData = new FormData();
    const formObject = FormObject(data);
    for (const [name, value] of Object.entries(formObject as Record<string, any>)) {
      if (value !== null && value !== undefined) {
        formData.append(name, value);
      }
    }

    await this.fetch<void>(`/cashback-action/categories`, {
      method: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    });
  };

  editCategory = async (id: number, data: CashbackActionCategory): Promise<void> => {
    const formData = new FormData();
    const formObject = FormObject(data);
    for (const [name, value] of Object.entries(formObject as Record<string, any>)) {
      if (value !== null && value !== undefined) {
        formData.append(name, value);
      }
    }

    await this.fetch<void>(`/cashback-action/categories/${id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    });
  };
}

export const cashbackActionApi = new CashbackActionApi();
