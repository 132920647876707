import React, { useState, useEffect } from 'react';
import moment from 'moment';
import * as _ from 'lodash';
import { useParams } from 'react-router-dom';
import { clientApi } from './api/client';
import { OptionsDetails } from './api/client-types';
import { Table } from '../../ui/table';
import * as S from './index.styles';
import { Loader } from '../../ui/shared/loader';

export const ClientOptions = () => {
  const { clientMnemocode, cardMnemocode } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState<OptionsDetails[]>([]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Опция',
        accessor: 'option.name',
      },
      {
        Header: 'Даты',
        accessor: 'option',
        width: 200,
        Cell: ({ value }: any) =>
          _.chain([value?.startDate, value?.finishDate])
            .compact()
            .map((date) => moment(date).format('DD.MM.YYYY'))
            .join(' - ')
            .value(),
      },
      {
        Header: 'Привязка',
        accessor: 'program.name',
      },
      {
        Header: 'Валюта',
        accessor: 'program.currency.currency',
        width: 100,
      },
      {
        Header: 'Даты',
        accessor: 'program',
        width: 200,
        Cell: ({ value }: any) =>
          _.chain([value?.startDate, value?.finishDate])
            .compact()
            .map((date) => moment(date).format('DD.MM.YYYY'))
            .join(' - ')
            .value(),
      },
    ],
    []
  );
  useEffect(() => {
    if (clientMnemocode && cardMnemocode) {
      setIsLoading(true);
      clientApi
        .getOptionsDateils(clientMnemocode, cardMnemocode)
        .then((optionsDetails) => setDetails(optionsDetails))
        .finally(() => setIsLoading(false));
    }
  }, [clientMnemocode, cardMnemocode]);

  return (
    <S.Section>
      {isLoading ? (
        <S.LoaderWrapper>
          <Loader />
        </S.LoaderWrapper>
      ) : (
        <S.Section>
          <Table initialState={{ sortBy: [{ id: 'date' }] }} data={details} columns={columns as any} />
        </S.Section>
      )}
    </S.Section>
  );
};
