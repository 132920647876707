import React from 'react';
import { Formik, Form } from 'formik';
import {
  InputField,
  SwitchField,
  SumbitButton,
  MultiSelectField,
  MultiSelectFieldItem,
  StaticSelectField,
} from 'components/ui/formik-controls';
import { Product } from 'api/product-types';
import { PartnerProductSchema } from './partner-product-schema';
import * as S from './partner-product-form.styles';
import { API_URL_BASE_PATH } from '../../../../api/base';

const emptyData = {
  mnemocode: '',
  isActive: true,
  // eslint-disable-next-line @typescript-eslint/camelcase
  product_class: '',
  components: {
    name: '',
    // eslint-disable-next-line @typescript-eslint/camelcase
    payment_org: '',
    banner: '',
    logo: '',
  },
  features: [],
};

type AfterSubmitHandler = (data?: Product) => void;
type ProductFormProps = {
  initialValues?: Product;
  onSubmit: (user: Product) => Promise<Product | undefined>;
  onAfterSubmit?: AfterSubmitHandler;
};
export const PartnerProductForm = (props: ProductFormProps) => {
  const { initialValues = emptyData, onSubmit, onAfterSubmit } = props;

  const featuresItems: MultiSelectFieldItem<string>[] = [
    { value: 'options', caption: 'options' },
    { value: 'programs', caption: 'programs' },
  ];

  return (
    <Formik<Product>
      enableReinitialize
      initialValues={initialValues}
      validationSchema={PartnerProductSchema}
      onSubmit={async (product, { setSubmitting }) => {
        const result = await onSubmit(product);
        setSubmitting(false);
        if (!result) {
          return;
        }
        if (onAfterSubmit) {
          onAfterSubmit();
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <S.FormWrapper>
            <S.Column>
              <InputField name="mnemocode" label="Мнемокод" />
              <InputField name="components.name" label="Название" />
              <InputField name="product_class" label="Product class" />
              <InputField name="components.payment_org" label="Payment org" />
              <MultiSelectField<string> name="features" label="Настройки" items={featuresItems} placeholder="" />
              <SwitchField name="isActive" label="Активен" />
            </S.Column>

            <S.Column>
              <StaticSelectField
                url={`${API_URL_BASE_PATH}/yandex/object-storage`}
                name="components.banner"
                label="Logo"
              />
              <StaticSelectField
                url={`${API_URL_BASE_PATH}/yandex/object-storage`}
                name="components.logo"
                label="Icon"
              />
            </S.Column>
          </S.FormWrapper>
          <SumbitButton isSubmitting={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};
