import React from 'react';
import { FormGroup, InputGroup } from '@blueprintjs/core';
import { Field, FieldProps } from 'formik';
import { FormikFieldProps } from './utils';

interface InputFieldProps extends FormikFieldProps {
  type?: string;
  placeholder?: string;
}
export const InputField = (props: InputFieldProps) => {
  const { name, label, type, inline = false, disabled = false, placeholder } = props;
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <FormGroup inline={inline} label={label} labelFor={name} labelInfo={meta.touched && meta.error}>
          <InputGroup
            type={type}
            id={name}
            disabled={disabled}
            placeholder={placeholder}
            {...field}
            intent={meta.touched && meta.error ? 'warning' : 'none'}
          />
        </FormGroup>
      )}
    </Field>
  );
};
