"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
tslib_1.__exportStar(require("./login-result.interface"), exports);
tslib_1.__exportStar(require("./profile.interface"), exports);
tslib_1.__exportStar(require("./available-section.interface"), exports);
tslib_1.__exportStar(require("./auth-payload.interface"), exports);
tslib_1.__exportStar(require("./login.interface"), exports);
tslib_1.__exportStar(require("./login-as-admin-result.interface"), exports);
tslib_1.__exportStar(require("./change-partner.interface"), exports);
tslib_1.__exportStar(require("./change-partner-result.interface"), exports);
