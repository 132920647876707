import { userApi } from 'api/user';
import { UserListResponse, UserResponse, User } from 'api/user-types';
import {
  createUseEntityList,
  createUseEntity,
  createUseEntityAdd,
  createUseEntityUpdate,
  createUseEntityDelete,
} from 'api/base-hooks';

export const useUserList = createUseEntityList<UserListResponse>('user', userApi.getList);
export const useUser = createUseEntity<UserResponse>('user', userApi.getItem);
export const useUserAdd = createUseEntityAdd<User>('user', userApi.addItem);
export const useUserUpdate = createUseEntityUpdate<User>('user', userApi.editItem);
export const useUserDelete = createUseEntityDelete<number>('user', userApi.deleteItem);
