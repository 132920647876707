import React from 'react';
import { FormGroup } from '@blueprintjs/core';
import { DateInput, DateInputProps } from '@blueprintjs/datetime';
import { parse, format } from 'date-fns';
import { useField, useFormikContext, getIn } from 'formik';
import { FormikFieldProps } from './utils';
import { localeUtils } from './date-time-input-utils';

const DATE_FORMAT = 'dd.LL.yyyy HH:mm';
const DEFAULT_PLACEHOLDER = 'дд.мм.гггг чч:мм';
const defaultFormatDate = (date: Date) => format(date, DATE_FORMAT);
const defaultParseDate = (str: string) => parse(str, DATE_FORMAT, new Date());

interface DateInputFieldProps extends Partial<DateInputProps>, FormikFieldProps {}
export const DateInputField = (props: DateInputFieldProps) => {
  const {
    name,
    label,
    inline,
    formatDate = defaultFormatDate,
    parseDate = defaultParseDate,
    placeholder = DEFAULT_PLACEHOLDER,
    ...rest
  } = props;

  const [field, , helpers] = useField<any>(name);
  const { errors } = useFormikContext();
  const value: any = React.useMemo(
    () =>
      typeof field.value === 'string'
        ? parseDate(field.value as string)
        : field.value instanceof Date
        ? field.value
        : null,
    [field.value]
  );
  const handleChange = (date: Date) => {
    const val = date instanceof Date ? formatDate(date) : null;
    helpers.setValue(val);
  };
  const fieldError = getIn(errors, name);
  return (
    <FormGroup inline={inline} label={label || (fieldError ? ' ' : null)} labelFor={name} labelInfo={fieldError}>
      <DateInput
        maxDate={new Date('2026-12-31')}
        value={value}
        formatDate={formatDate}
        parseDate={parseDate}
        placeholder={placeholder}
        locale="ru"
        localeUtils={localeUtils}
        {...rest}
        onChange={handleChange}
        inputProps={{ name }}
      />
    </FormGroup>
  );
};
