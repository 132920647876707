import React from 'react';
import { Switch } from '@blueprintjs/core';
import { Field, FieldProps } from 'formik';
import { FormikFieldProps } from './utils';

export const SwitchField = (props: FormikFieldProps) => {
  const { name, label, disabled } = props;
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => {
        return <Switch disabled={disabled} id={name} label={label} checked={field.value} onChange={field.onChange} />;
      }}
    </Field>
  );
};
