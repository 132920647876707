import { BaseApi } from 'api/base';
import * as Types from './offer-types';

class OfferApi extends BaseApi {
  getList = async (params: any[] = []): Promise<Types.OfferListResponse> => {
    const bankMnemocode = params[0] ? params[0] : '';
    const result = await this.fetch<Types.OfferListResponse>(`/offer/partner/offers/${bankMnemocode}`);
    return result;
  };

  getItem = async (id: number): Promise<Types.OfferResponse> => {
    const result = await this.fetch<Types.OfferResponse>(`/offer/partner/offer/${id}`);
    return result;
  };
}

export const offerApi = new OfferApi();
