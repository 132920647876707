import { BaseApi } from 'api/base';
import * as Types from './tag-types';

class TagApi extends BaseApi {
  getList = async (params: any[] = []): Promise<Types.TagListResponse> => {
    const bankMnemocode = params[0] ? params[0] : '';
    const result = await this.fetch<Types.TagListResponse>('/tag', { params: { bankMnemocode } });
    return result;
  };

  getItem = async (id: number, params: { bankMnemocode: string }): Promise<Types.TagResponse> => {
    const result = await this.fetch<Types.TagResponse>(`/tag/${id}`);
    return result;
  };

  getTagOffers = async (id: number, params: { bankMnemocode: string }): Promise<Types.TagResponse> => {
    const result = await this.fetch<Types.TagResponse>(`/tag/${id}/${params.bankMnemocode}`);
    return result;
  };

  editItem = async (id: number, data: Types.Tag): Promise<Types.TagResponse> => {
    await this.fetch<void>(`/tag/${id}`, { method: 'put', data });
    return data;
  };

  addItem = async (data: Types.Tag): Promise<Types.TagResponse> => {
    const result = await this.fetch<Types.TagResponse>(`/tag`, { method: 'post', data });
    return result;
  };

  deleteItem = async (id: number): Promise<number> => {
    await this.fetch<void>(`/tag/${id}`, { method: 'delete' });
    return id;
  };
}

export const tagApi = new TagApi();
