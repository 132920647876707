import { createAction } from 'typesafe-actions';
import { ProfileResponse } from 'api/auth-types';
import { BreadCrumbItem, TabItem } from 'components/ui/layouts/section-layout/section-layout-types';

export const increment = createAction('INCREMENT', (value) => ({ value }))<{ value: number }>();
export const decrement = createAction('DECREMENT', (value) => ({ value }))<{ value: number }>();

export const appStarted = createAction('APP_STARTED')();

export const logIn = createAction('LOG_IN')();

export const logOut = createAction('LOG_OUT')();

export const profileReceived = createAction('PROFILE_RECEIVED', (profile: ProfileResponse) => ({ profile }))<{
  profile: ProfileResponse;
}>();

export const addBreadCrumb = createAction('ADD_BREAD_CRUMB', (breadCrumbItem: BreadCrumbItem) => breadCrumbItem)<
  BreadCrumbItem
>();

export const addBreadCrumbs = createAction('ADD_BREAD_CRUMBS', (breadCrumbItems: BreadCrumbItem[]) => breadCrumbItems)<
  BreadCrumbItem[]
>();

export const removeBreadCrumb = createAction('REMOVE_BREAD_CRUMB', (breadCrumbItem: BreadCrumbItem) => breadCrumbItem)<
  BreadCrumbItem
>();

export const removeBreadCrumbs = createAction('REMOVE_BREAD_CRUMBS', (paths: string[]) => paths)<string[]>();

export const setTabs = createAction('SET_TABS', ({ path, items }) => ({ path, items }))<{
  path: string;
  items: TabItem[];
}>();

export const removeTabs = createAction('REMOVE_TABS', () => undefined)<undefined>();
