import React from 'react';
import { useParams } from 'react-router-dom';
import { EntityForm } from 'components/ui/layouts/entity-form';
import { TabsRouter } from 'components/ui/layouts/tabs-router';
import {
  createUseProductProgram,
  createUseProductProgramAdd,
  createUseProductProgramUpdate,
  createUseProductProgramDelete,
} from './api/product-program-hooks';
import { ProductProgram } from './api/product-program-types';
import { ProductProgramForm } from './product-program-form';

export const ProductProgramPage = ({ paramName }: { paramName: string }) => {
  const { partnerId, productId, productProgramId } = useParams<{ [key: string]: string }>();
  const useProductProgram = createUseProductProgram(Number(partnerId));
  const useProductProgramAdd = createUseProductProgramAdd(Number(partnerId), Number(productId));
  const useProductProgramUpdate = createUseProductProgramUpdate(Number(partnerId));
  const useProductProgramDelete = createUseProductProgramDelete(Number(partnerId));

  return (
    <TabsRouter
      route={`/partner/${partnerId}/product/${productId}/product-program/${productProgramId}`}
      tabs={[
        {
          caption: 'Карточка',
          component: (
            <EntityForm<ProductProgram>
              paramName={paramName}
              listUrl={`/partner/${partnerId}/product/${productId}/product-program`}
              getEntityText={(data) => 'редактирование записи'}
              component={ProductProgramForm}
              useEntity={useProductProgram}
              useEntityAdd={useProductProgramAdd}
              useEntityMutate={useProductProgramUpdate}
              useEntityDelete={useProductProgramDelete}
            />
          ),
        },
      ]}
    />
  );
};
