import React from 'react';
import { Pc } from '../api/client-types';

export const PcPopover = ({ card }: { card: Pc }) => {
  return (
    <div>
      <div>
        <b>mnemocode: </b>
        <span>{card?.mnemocode}</span>
      </div>
      <div>
        <b>entryId: </b>
        <span>{card.entryId}</span>
      </div>
      <div>
        <b>Код продукта: </b>
        <span>{card?.type}</span>
      </div>
      <div>
        <b>ExternalId: </b>
        <span>{card?.externalId}</span>
      </div>
      <div>
        <b>productMnemocode: </b>
        <span>{card.product.mnemocode}</span>
      </div>
      <div>
        <b>currency: </b>
        <span>{card.currency.currency}</span>
      </div>
    </div>
  );

  /*
  return (
    <div>
      {card.external_id && (
        <div>
          <b>external_id: </b>
          <span>{card.external_id}</span>
        </div>
      )}
      <div>
        <b>mnemocode: </b>
        <span>{card.mnemocode}</span>
      </div>
      <div>
        <b>productMnemocode: </b>
        <span>{card.product.mnemocode}</span>
      </div>
      <div>
        <b>currency: </b>
        <span>{card.product_currency}</span>
      </div>
      <div>
        <b>Код продукта: </b>
        <span>{_.get(card, 'attribute20.value', '')}</span>
      </div>
    </div>
  );
 */
};
