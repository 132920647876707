import React from 'react';
import * as S from './hooks.styles';
import * as Yup from 'yup';
import { DateInputField, errorMessages, SelectField } from '../../../ui/formik-controls';
import { FormFieldsFunction } from './form-fields';
import { SchemaTypeEnum, SummaryInterface, TransactionStatusEnum } from '../api/report-types';
import * as _ from 'lodash';
import { usePartnerList } from '../../../../api/partner-hooks';
import moment from 'moment';

const state: Partial<SummaryInterface> = {
  bankMnemocode: undefined,
  createdWhen: undefined,
  schema: undefined,
  startDate: undefined,
  finishDate: undefined,
};

const schema: Yup.Schema<object | undefined> = Yup.object().shape({
  bankMnemocode: Yup.string().required(errorMessages.required),
  createdWhen: Yup.string().required(errorMessages.required),
  schema: Yup.string().required(errorMessages.required),
  startDate: Yup.string().required(errorMessages.required),
  finishDate: Yup.string().required(errorMessages.required),
});

export const useSummary: FormFieldsFunction<Partial<SummaryInterface>> = () => {
  const bankMnemocodeOptions = _.chain(usePartnerList())
    .get('data')
    .map((partner) => ({ value: partner?.mnemocode, caption: `${partner?.mnemocode} - ${partner?.components?.name}` }))
    .value();

  const schemaOptions = _.chain(SchemaTypeEnum)
    .map((schema) => ({ value: schema, caption: schema }))
    .value();

  function render() {
    return (
      <S.FormWrapper>
        <S.Column>
          <SelectField
            options={bankMnemocodeOptions}
            name="bankMnemocode"
            label="Банк"
            filterable={false}
            placeholder="Выберите банк"
          />
          <SelectField
            options={schemaOptions}
            name="schema"
            label="Схема"
            placeholder="Выберите схему"
            filterable={false}
          />
          <DateInputField
            name="createdWhen"
            label="Время создания"
            placeholder="YYYY-MM-DDTHH:mm:ss"
            formatDate={(date) => moment(date).format('YYYY-MM-DDTHH:mm:ss')}
            parseDate={(str) => moment(str, 'YYYY-MM-DDTHH:mm:ss').toDate()}
            timePrecision="second"
          />
        </S.Column>
        <S.Column>
          <DateInputField
            name="startDate"
            label="Начало периода проводки"
            placeholder="YYYY-MM-DDTHH:mm:ss"
            formatDate={(date) => moment(date).format('YYYY-MM-DDTHH:mm:ss')}
            parseDate={(str) => moment(str, 'YYYY-MM-DDTHH:mm:ss').toDate()}
            timePrecision="second"
          />
          <DateInputField
            name="finishDate"
            label="Окончание периода проводки"
            placeholder="YYYY-MM-DDTHH:mm:ss"
            formatDate={(date) => moment(date).format('YYYY-MM-DDTHH:mm:ss')}
            parseDate={(str) => moment(str, 'YYYY-MM-DDTHH:mm:ss').toDate()}
            timePrecision="second"
          />
        </S.Column>
      </S.FormWrapper>
    );
  }

  return {
    state,
    schema,
    render,
  };
};
