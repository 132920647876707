import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@blueprintjs/core';
import * as S from '../index.styles';
import { CashbackActionMerchantTable } from './cashback-action-merchant-table';

export const CashbackActionMerchantsPage = () => {
  const { id } = useParams();
  const history = useHistory();

  return (
    <S.Section>
      <S.Nav>
        <Button intent="success" text="Создать" onClick={() => history.push(`/cashback-action/${id}/merchant/add`)} />
      </S.Nav>
      <S.Section>
        <CashbackActionMerchantTable id={id} />
      </S.Section>
    </S.Section>
  );
};
