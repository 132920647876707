import { useQuery, useMutation, useQueryCache, TypedQueryFunction } from 'react-query';
import { ApiError } from 'api/base';

export type ApiListArgs = readonly [any];
export const createUseEntityList = <T>(section: string, queryFn: TypedQueryFunction<T, ApiListArgs>) => (
  params?: ApiListArgs
) => {
  const queryKey = `${section}-list${params ? '-' : ''}${params ? params?.join('-') : ''}`;
  return useQuery<T, ApiError>(queryKey, () => queryFn(params));
};

type ApiItemArgs = [any, any?];
export const createUseEntity = <T>(section: string, apiFunc: TypedQueryFunction<T, ApiItemArgs>) => (
  id: number | string,
  params?: any
) => {
  return useQuery<T, ApiError>(
    `${section}:${id}`,
    () => {
      return apiFunc(id, params);
    },
    { enabled: Boolean(id) }
  );
};

type ApiAddArgs<T> = [T];
export const createUseEntityAdd = <T extends { id?: number }>(
  section: string,
  apiFunc: TypedQueryFunction<T, ApiAddArgs<T>>
) => () => {
  const cache = useQueryCache();
  return useMutation(async (entity: T) => apiFunc(entity), {
    // onError: (error) => {
    //   console.log('ERROR', error);
    // },
    // onMutate: (data) => {
    // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
    // console.log('CHECK_1', data);
    // cache.cancelQueries(`${section}-list`);
    // // Snapshot the previous value
    // const previousUsers = cache.getQueryData(`${section}-list`);
    // // Optimistically update to the new value
    // cache.setQueryData<T[]>(`${section}-list`, (old = []) => [...old, data]);
    // // Return the snapshotted value
    // return () => cache.setQueryData(`${section}-list`, previousUsers);
    // },
    onSettled: (data, error) => {
      if (error || !data) {
        return;
      }
      cache.cancelQueries(`${section}-list`);
      cache.setQueryData<T[]>(`${section}-list`, (old = []) => [...old, data]);
    },
    onSuccess: (data) => {
      cache.setQueryData(`${section}:${data.id}`, data);
    },
  });
};

type ApiEditArgs<T> = [number, T];
export const createUseEntityUpdate = <T extends { id?: number }>(
  section: string,
  apiFunc: TypedQueryFunction<T, ApiEditArgs<T>>
) => () => {
  const cache = useQueryCache();
  return useMutation(async (data: { id: number; entity: T }) => apiFunc(data.id, data.entity), {
    // onMutate: (data) => {
    //   // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
    //   cache.cancelQueries(`${section}-list`);
    //   // Snapshot the previous value
    //   const previousList = cache.getQueryData(`${section}-list`);
    //   // Optimistically update to the new value
    //   cache.setQueryData<T[]>(`${section}-list`, (old = []) =>
    //     old.map((item) => (item.id !== data.id ? item : data.entity))
    //   );
    //   // Return the snapshotted value
    //   return () => cache.setQueryData(`${section}-list`, previousList);
    // },
    onSettled: (data, error) => {
      if (error || !data) {
        return;
      }
      cache.cancelQueries(`${section}-list`);
      cache.setQueryData<T[]>(`${section}-list`, (old = []) => old.map((item) => (item.id !== data.id ? item : data)));
    },
    onSuccess: (data) => {
      cache.setQueryData(`${section}:${data.id}`, data);
    },
  });
};

type ApiDeleteItemArgs = [number];
export const createUseEntityDelete = <T>(section: string, apiFunc: TypedQueryFunction<T, ApiDeleteItemArgs>) => () => {
  const cache = useQueryCache();
  return useMutation(async (id: number) => apiFunc(id), {
    onSuccess: (id) => {
      cache.setQueryData(`${section}:${id}`, undefined);
      cache.invalidateQueries(`${section}-list`);
    },
  });
};

type ApiRestoreItemArgs = [number];
export const createUseEntityRestore = <T>(
  section: string,
  apiFunc: TypedQueryFunction<T, ApiRestoreItemArgs>
) => () => {
  const cache = useQueryCache();
  return useMutation(async (id: number) => apiFunc(id), {
    onSuccess: (id) => {
      cache.setQueryData(`${section}:${id}`, id);
      cache.invalidateQueries(`${section}-list`);
    },
  });
};
