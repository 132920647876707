import { RootState } from 'reducers';
import { createSelector } from 'reselect';

export const isLogged = (state: RootState) => state.auth.logged;

export const getUserProfile = (state: RootState) => state.auth.profile;
export const getUsername = (state: RootState) => state.auth.profile?.username;
export const getUserRole = (state: RootState) => state.auth.profile?.role;
export const getIsSudo = (state: RootState) => state.auth.profile?.role === 'SUDO';
export const getAvailableSections = (state: RootState) =>
  state.auth.profile?.availableSections.filter((section) => section.hasUi);

export const authenticationState = (state: RootState) => state.auth;
export const userSelector = createSelector<RootState, ReturnType<typeof authenticationState>, any>(
  authenticationState,
  (authentication) => authentication.profile
);
