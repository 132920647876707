import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Formik, Form } from 'formik';
import { InputField, SwitchField, SumbitButton, NumericSelectField } from 'components/ui/formik-controls';
import { User } from 'api/user-types';
import { NewUserSchema, EditUserSchema } from './user-schema';
import * as S from './user-form.styles';
import { getUserRole, userSelector } from '../../../selectors/auth';
import { Role } from '../../../utils/types';
import { usePartnerList } from '../../../api/partner-hooks';
import * as _ from 'lodash';

const emptyData = {
  email: '',
  password: '',
  passwordConfirmation: '',
  isActive: true,
  role: Role.USER,
  requestSignatureKey: '',
  partnerRole: 0,
  partnerId: 0,
  deletedWhen: false,
};

interface UserFormValues extends User {
  passwordConfirmation: string;
}

type AfterSubmitHandler = (data?: User) => void;
type UserFormProps = {
  initialValues?: User;
  onSubmit: (user: User) => Promise<User | undefined>;
  onAfterSubmit?: AfterSubmitHandler;
};
export const UserForm = (props: UserFormProps) => {
  const { initialValues = emptyData, onSubmit, onAfterSubmit } = props;

  const user = useSelector(userSelector);
  const formInitialValues = initialValues ? { passwordConfirmation: '', ...initialValues } : emptyData;
  formInitialValues.partnerId = formInitialValues.partnerId || user.partnerId;

  const userRole = useSelector(getUserRole);

  const roleOptions: { value: Role; caption: Role }[] = _.chain(Role)
    .thru((role) => (userRole !== Role.SUDO ? _.omit(role, [Role.SUDO, Role.GPADMIN, Role.GPUSER]) : role))
    .map((role) => ({ value: role, caption: role }))
    .value();

  const partners = usePartnerList();
  const partnerOptions: { value: number; caption: string }[] = _.chain(partners?.data)
    .map((partner) => ({ value: partner?.id || 0, caption: partner?.components?.name || '' }))
    .value();

  return (
    <Formik<UserFormValues>
      enableReinitialize
      initialValues={formInitialValues}
      validationSchema={initialValues.email ? EditUserSchema : NewUserSchema}
      onSubmit={async (values: UserFormValues, { setSubmitting }) => {
        const { passwordConfirmation, ...user } = values;
        const result = await onSubmit(user);
        setSubmitting(false);
        if (!result) {
          return;
        }
        if (onAfterSubmit) {
          onAfterSubmit();
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <S.Fieldset disabled={initialValues?.deletedWhen}>
            <S.FormWrapper>
              <S.Column>
                <InputField name="email" label="Логин" />
                <InputField name="name" label="Имя" />
                <NumericSelectField
                  name="role"
                  label="Роль"
                  options={roleOptions}
                  disabled={initialValues?.deletedWhen}
                />
                {userRole === 'SUDO' && (
                  <NumericSelectField
                    name="partnerId"
                    label="Банк"
                    options={partnerOptions}
                    disabled={initialValues?.deletedWhen}
                  />
                )}
                <InputField type="password" name="password" label="Пароль" />
                <InputField type="password" name="passwordConfirmation" label="Подтвердите пароль" />
                <SwitchField name="isActive" label="Активен" />
              </S.Column>
            </S.FormWrapper>
            <SumbitButton isSubmitting={isSubmitting} />
          </S.Fieldset>
        </Form>
      )}
    </Formik>
  );
};
