import React from 'react';
import { useParams } from 'react-router-dom';

import { useSection } from './api/section-hooks';

export const SectionPage = () => {
  const { id } = useParams<{ [key: string]: string }>();
  const { data } = useSection(Number(id));
  return (
    <>
      <div>
        <div style={{ padding: '10px 0 0 10px' }}>
          <b>Название: </b>
          <span>{data?.title}</span>
        </div>
        <div style={{ padding: '10px 0 0 10px' }}>
          <b>Путь: </b>
          <span>{data?.path}</span>
        </div>
        <div style={{ padding: '10px 0 0 10px' }}>
          <b>Активность: </b>
          <span>{data?.isActive ? 'активный' : 'не активный'}</span>
        </div>
        <div style={{ padding: '10px 0 0 10px' }}>
          <b>Возможные действия: </b>
          <span>{data?.actions.join(', ')}</span>
        </div>
      </div>
    </>
  );
};
