import produce from 'immer';
import { Action, createReducer } from 'typesafe-actions';
import { logIn, logOut, profileReceived } from 'actions';
import { WritableDraft } from 'immer/dist/types/types-external';
import { ProfileResponse } from '../api/auth-types';

export interface AuthState {
  readonly logged?: boolean;
  readonly profile?: ProfileResponse;
}

const initialState: AuthState = {
  logged: undefined,
  profile: undefined,
};

export default createReducer<AuthState, Action>(initialState)
  .handleAction(logIn, (state) => {
    return produce(state, (draft) => {
      draft.logged = true;
    });
  })
  .handleAction(logOut, (state) => {
    return produce(state, (draft) => {
      draft.logged = false;
      draft.profile = undefined;
    });
  })
  .handleAction(profileReceived, (state, { payload }) => {
    return produce(
      state,
      (draft: WritableDraft<{ logged: boolean; profile: { userId: number; username: string } }>) => {
        draft.logged = true;
        draft.profile = payload.profile;
      }
    );
  });
