import React, { useState } from 'react';
import { Icon, InputGroup, Switch } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

export enum FilterTypes {
  Text = 'text',
  Boolean = 'boolean',
  Number = 'number',
}

export function TextColumnFilter({ column: { filterValue, setFilter } }: { column: any }) {
  const [isActive, setIsActive] = useState(false);
  const handleIconClick = () => {
    const isSetFilter = !isActive;
    setFilter(isSetFilter ? '' : undefined);
    setIsActive(isSetFilter);
  };
  return (
    <div style={{ display: 'flex', alignItems: 'center', minHeight: '30px' }}>
      <div style={{ marginRight: '5px' }}>
        <Icon onClick={() => handleIconClick()} icon={IconNames.FILTER} />
      </div>
      {isActive && (
        <InputGroup value={filterValue || ''} onChange={(e: any) => setFilter(e.target.value || undefined)} />
      )}
    </div>
  );
}

export function BooleanColumnFilter({ column: { filterValue, preFilteredRows, setFilter } }: { column: any }) {
  const [isActive, setIsActive] = useState(false);
  const handleIconClick = () => {
    const isSetFilter = !isActive;
    setFilter(isSetFilter ? true : undefined);
    setIsActive(isSetFilter);
  };
  return (
    <div style={{ display: 'flex', alignItems: 'center', minHeight: '30px' }}>
      <div style={{ marginRight: '5px' }}>
        <Icon onClick={() => handleIconClick()} icon={IconNames.FILTER} />
      </div>
      {isActive && (
        <Switch
          style={{ margin: '0' }}
          checked={filterValue || false}
          onChange={(e: any) => setFilter(e.target.checked)}
        />
      )}
    </div>
  );
}
