import {
  createUseEntityList,
  createUseEntity,
  createUseEntityAdd,
  createUseEntityUpdate,
  createUseEntityDelete,
} from 'api/base-hooks';
import { tagApi } from './tag';
import { TagListResponse, TagResponse } from './tag-types';

const SECTION = 'tag';

export const useTagList = createUseEntityList<TagListResponse>(SECTION, tagApi.getList);
export const useTag = createUseEntity<TagResponse>(SECTION, tagApi.getItem);
export const useTagAdd = createUseEntityAdd<TagResponse>(SECTION, tagApi.addItem);
export const useTagUpdate = createUseEntityUpdate<TagResponse>(SECTION, tagApi.editItem);
export const useTagDelete = createUseEntityDelete<number>(SECTION, tagApi.deleteItem);
