import React, { useState } from 'react';
import { Button, MenuItem } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { Role } from '../../../utils/types';
import { RoleAccessesTable } from './role-access/role-accesses-table';
import { RoleSettingsForm } from './role-settings/role-settings-form';
import * as _ from 'lodash';
import { useSelector } from 'react-redux';
import { getUserRole } from '../../../selectors/auth';

interface NumericSelectOption {
  value: Role;
  caption: Role;
}

const itemRenderer: ItemRenderer<NumericSelectOption> = (item, { handleClick, modifiers, query }) => {
  return <MenuItem onClick={handleClick} key={item.value} text={item.caption} />;
};

export const RolePage = () => {
  const [role, setRole] = useState<Role>(Role.ADMIN);

  const userRole = useSelector(getUserRole);
  const roleOptions: NumericSelectOption[] = _.chain(Role)
    .omit([Role.SUDO])
    .thru((role) => (userRole !== Role.SUDO ? _.omit(role, [Role.GPADMIN, Role.GPUSER]) : role))
    .map((role) => ({ value: role, caption: role }))
    .value();

  const handlePartnerSelect = (item: NumericSelectOption) => {
    const selectedRole = roleOptions.find(({ value }) => value === item.value) || { value: Role.ADMIN };
    setRole(selectedRole.value);
  };
  return (
    <div style={{ padding: '10px' }}>
      <div>
        <div>Выберите роль</div>
        <Select items={roleOptions} filterable={false} itemRenderer={itemRenderer} onItemSelect={handlePartnerSelect}>
          <Button text={role || 'Выберите роль'} rightIcon="caret-down" />
        </Select>
      </div>
      <h3>Настройки</h3>
      <RoleSettingsForm role={role} />
      <h3>Доступные разделы</h3>
      <RoleAccessesTable role={role} />
    </div>
  );
};
