import React, { useState } from 'react';
import { useQueryCache } from 'react-query';
import { Button, MenuItem } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { baseApi } from '../../../../../api/base';
import { Section } from '../../../section/api/section-types';

interface NumericSelectOption {
  value: number;
  caption: string;
}

const itemRenderer: ItemRenderer<NumericSelectOption> = (item, { handleClick }) => {
  return <MenuItem onClick={handleClick} key={item.value} text={item.caption} />;
};

export const AddRoleAccessModal = ({
  availableSectionIds,
  role,
  hideModal,
}: {
  role: string;
  hideModal: (result: any) => void;
  availableSectionIds: number[];
}) => {
  const queryCache = useQueryCache();
  const sections = queryCache.getQueryData('section-list') as Section[];
  const sectionOptions = (sections as Section[])
    .filter((item) => !availableSectionIds.includes(item.id))
    .map((item) => ({ value: item.id, caption: item.title }));
  const handleSectionSelect = async (item: NumericSelectOption) => {
    const section = (sections as Section[]).find((section: Section) => section.id === item.value) as Section;
    setSection(section as Section);
  };
  const defaultSectionOption = (sections as Section[]).find(
    (section: Section) => section.id === sectionOptions[0].value
  ) as Section;
  const [section, setSection] = useState(defaultSectionOption);
  const handleSave = async () => {
    const response = await baseApi.fetch<any>(`/role/access`, {
      method: 'POST',
      data: {
        role,
        section,
      },
    });
    hideModal({ id: response.id, actions: [], path: section.path, role, section, hasUi: response.hasUi });
  };
  return (
    <div style={{ padding: '2em' }}>
      <h3>Выберите секцию</h3>
      <div>
        <Select
          items={sectionOptions}
          filterable={false}
          itemRenderer={itemRenderer}
          onItemSelect={handleSectionSelect}
        >
          <Button text={section.title} rightIcon="caret-down" />
        </Select>
      </div>
      <div style={{ padding: '10px 0' }}>
        <Button onClick={handleSave} intent="primary">
          Сохранить
        </Button>
      </div>
    </div>
  );
};
