import React from 'react';
import { URL } from 'utils/url-builder';
import { EntityTable, EntityTableColumn } from 'components/ui/skeletons/entity-table';
import { useSectionList } from './api/section-hooks';
import { Section } from './api/section-types';

export const SectionsTable = () => {
  const columns: EntityTableColumn<Section>[] = React.useMemo(
    () => [
      {
        Header: 'Название',
        accessor: 'title',
      },
      {
        Header: 'Активность',
        accessor: 'isActive',
        Cell: ({ value }) => (value ? 'активный' : 'не активный'),
      },
      {
        Header: 'Действия',
        accessor: 'actions',
        Cell: ({ value }) => value.join(', '),
      },
    ],
    []
  );

  return (
    <EntityTable<Section>
      useEntityList={useSectionList}
      entityEditUrl={URL.sectionView}
      columns={columns}
      initialSortBy="title"
    />
  );
};
