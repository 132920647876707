import { IconTypeWithFile } from '@whitelabel/common/api';

export type ModalsResponse = Modal[];
export type ModalResponse = Modal;

export enum PositionEnum {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
  MIDDLE = 'MIDDLE',
  HOME_BANNER = 'HOME_BANNER',
}

export enum CombinationsPeriodEnum {
  PRESENT = 'PRESENT',
  FUTURE = 'FUTURE',
}

export enum OptionsPeriodEnum {
  PAST = 'PAST',
  PRESENT = 'PRESENT',
  FUTURE = 'FUTURE',
}

export type Modal = {
  id: number;
  isActive: boolean;
  title: string;
  position: PositionEnum;
  description: string;
  icon: IconTypeWithFile;
  button?: string;
  buttonLink?: string;
  clients?: number[];
  products?: string[];
  combinations?: string[];
  combinationsPeriod?: CombinationsPeriodEnum[];
  options?: string[];
  optionsPeriod?: OptionsPeriodEnum[];
  path?: string;
  appearOnEnter: boolean;
  appearancesCount: number;
  dateStart?: string;
  dateEnd?: string;
  timer?: number;
  createdWhen: Date;
  activatedWhen: Date;
  deletedWhen: Date;
  image?: string;
  order?: number;
};
