"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const tslib_1 = require("tslib");
tslib_1.__exportStar(require("./get-result.interface"), exports);
tslib_1.__exportStar(require("./get-id-param.interface"), exports);
tslib_1.__exportStar(require("./get-id-result.interface"), exports);
tslib_1.__exportStar(require("./post-body.interface"), exports);
tslib_1.__exportStar(require("./post-result.interface"), exports);
tslib_1.__exportStar(require("./delete-param.interface"), exports);
tslib_1.__exportStar(require("./put-param.interface"), exports);
tslib_1.__exportStar(require("./put-body.interface"), exports);
tslib_1.__exportStar(require("./put-uploaded-files.interface"), exports);
tslib_1.__exportStar(require("./get-merchants-param.interface"), exports);
tslib_1.__exportStar(require("./get-merchant-param.interface"), exports);
tslib_1.__exportStar(require("./get-merchant-result.iterface"), exports);
tslib_1.__exportStar(require("./post-merchant-param.interface"), exports);
tslib_1.__exportStar(require("./post-merchant-body.interface"), exports);
tslib_1.__exportStar(require("./put-merchant-param.interface"), exports);
tslib_1.__exportStar(require("./put-merchant-body.interface"), exports);
tslib_1.__exportStar(require("./delete-merchant-param.interface"), exports);
