import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #444;
`;

export const SignInPlaceholder = styled.div`
  width: 350px;
`;
