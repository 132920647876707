import * as Yup from 'yup';
import { errorMessages } from 'components/ui/formik-controls';

export const BonusRegistrySchema = Yup.object().shape({
  bankMnemocode: Yup.string().required(errorMessages.required),
  goal: Yup.object().shape({
    goalId: Yup.number()
      .nullable(true)
      .transform((value: any, originalValue: any) => (originalValue === null ? 0 : originalValue))
      .required(errorMessages.required),

    goalName: Yup.string().when('goalId', (goalId: number, field: Yup.StringSchema) =>
      goalId ? field : field.required(errorMessages.required)
    ),
    goalType: Yup.string().when('goalId', (goalId: number, field: Yup.StringSchema) =>
      goalId ? field : field.required(errorMessages.required)
    ),
    startDate: Yup.string().when('goalId', (goalId: number, field: Yup.StringSchema) =>
      goalId ? field : field.required(errorMessages.required)
    ),
    finishDate: Yup.string().optional(),
  }),
  loyaltyMnemocode: Yup.string(),
  period: Yup.string().required(errorMessages.required),
  file: Yup.string()
    .transform((value: any, originalValue: any) => originalValue?.name)
    .required(errorMessages.required),
  description: Yup.string().optional(),
});
