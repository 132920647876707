import React from 'react';
import { useParams } from 'react-router-dom';
import { IconNames } from '@blueprintjs/icons';
import { URL } from 'utils/url-builder';
import { EntitySkeleton } from 'components/ui/skeletons/entity/entity';
import { useTag, useTagAdd, useTagUpdate, useTagDelete } from './api/tag-hooks';
import { Tag } from './api/tag-types';
import { TagForm } from './tag-form';

export const TagPage = () => {
  const { tabId } = useParams<{ [key: string]: string }>();
  return (
    <EntitySkeleton<Tag>
      icon={IconNames.PROJECTS}
      listUrl={URL.tagList()}
      sectionText="Теги КЛО"
      getEntityText={(data) => data?.nameRu}
      form={TagForm}
      useEntity={useTag}
      useEntityAdd={useTagAdd}
      useEntityMutate={useTagUpdate}
      useEntityDelete={useTagDelete}
      params={{ tabId }}
    />
  );
};
