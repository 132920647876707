import moment from 'moment';
import { CashbackAction } from './api/cashback-action-types';

export const isCashbackActionNew = (cashbackActionPartial: Pick<CashbackAction, 'startAt'>) => {
  const startAtDate = moment.utc(cashbackActionPartial.startAt);
  const currentDate = moment.utc();

  if (currentDate.diff(startAtDate, 'day') < 14) {
    return true;
  }

  return false;
};

export const isCashbackActionActive = (cashbackActionPartial: Pick<CashbackAction, 'startAt' | 'stopAt'>) => {
  const { startAt, stopAt } = cashbackActionPartial;
  const startAtDate = moment.utc(startAt);
  const stopAtDate = moment.utc(`${stopAt}T23:59:59`);
  const currentDate = moment.utc();

  if (startAt && currentDate >= startAtDate && (!stopAt || (stopAt && currentDate <= stopAtDate))) {
    return true;
  }

  return false;
};
