import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TabsRouter } from 'components/ui/layouts/tabs-router';
import { addBreadCrumbs } from 'actions';
import { TabsRouterProps } from '../tabs-router/tabs-router-types';
import { BreadCrumbs } from './components/breadCrumbs';
import { Tabs } from './components/tabs';
import { BreadCrumbItem } from './section-layout-types';

function getBreadCrumbsByPath(path: string): BreadCrumbItem[] {
  return path
    .split('/')
    .filter((item) => item)
    .reduce((acc, item, index, items) => {
      const path = `/${items.slice(0, index + 1).join('/')}`;
      acc.push({ path });
      return acc;
    }, [] as BreadCrumbItem[]);
}

export const SectionLayout = (props: TabsRouterProps) => {
  const { route, tabs } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = history.location;
  useEffect(() => {
    const breadCrumbs = getBreadCrumbsByPath(pathname);
    dispatch(addBreadCrumbs(breadCrumbs));
  }, [pathname, dispatch]);
  return (
    <>
      <BreadCrumbs />
      <Tabs />
      <TabsRouter route={route} tabs={tabs} />
    </>
  );
};
