import React from 'react';
import { useParams } from 'react-router-dom';
import { EntityForm } from 'components/ui/layouts/entity-form';
import { Tab } from 'components/ui/layouts/tabs-router/tabs-router-types';
import { TabsRouter } from 'components/ui/layouts/tabs-router';
import { PartnerWidgetForm } from './partner-widget-form';
import { PartnerWidget } from './api/widget-types';
import { createUseWidget, createUseWidgetAdd, createUseWidgetUpdate, createUseWidgetDelete } from './api/widget-hooks';

export const PartnerWidgetPage = ({ paramName }: { paramName: string }) => {
  const { partnerId, widgetId } = useParams<{ [key: string]: string }>();
  const useWidget = createUseWidget(Number(partnerId));
  const useWidgetAdd = createUseWidgetAdd(Number(partnerId));
  const useWidgetUpdate = createUseWidgetUpdate(Number(partnerId));
  const useWidgetDelete = createUseWidgetDelete(Number(partnerId));
  const tabs: Tab[] = [
    {
      caption: 'Карточка',
      component: (
        <EntityForm<PartnerWidget>
          paramName={paramName}
          listUrl={`/partner/${partnerId}/widget`}
          getEntityText={(data) => data?.widget?.name}
          component={PartnerWidgetForm}
          useEntity={useWidget}
          useEntityAdd={useWidgetAdd}
          useEntityMutate={useWidgetUpdate}
          useEntityDelete={useWidgetDelete}
        />
      ),
    },
  ];

  return <TabsRouter route={`/partner/${partnerId}/widget/${widgetId}`} tabs={tabs} />;
};
