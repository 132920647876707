import React, { useState } from 'react';
import { useQueryCache } from 'react-query';
import { EntityTable, EntityTableColumn } from 'components/ui/skeletons/entity-table';
import { Select, ItemRenderer } from '@blueprintjs/select';
import { MenuItem, Button, FileInput } from '@blueprintjs/core';
import { useTagList } from './api/tag-hooks';
import { usePartnerList } from '../../../api/partner-hooks';
import { Partner } from '../../../api/partner-types';
import { Tag } from './api/tag-types';
import { TextColumnFilter } from '../../ui/table/filter-types';
import { useModal } from '../../ui/modal';
import { AddOffersToTagModal } from './components/AddOffersToTagModal';
import { Offer } from '../offer/api/offer-types';
import { URL } from '../../../utils/url-builder';
import { baseApi } from '../../../api/base';
import { Loader } from '../../ui/shared/loader';

interface NumericSelectOption {
  value: number;
  caption: string;
}

const itemRenderer: ItemRenderer<NumericSelectOption> = (item, { handleClick, modifiers, query }) => {
  return <MenuItem onClick={handleClick} key={item.value} text={item.caption} />;
};

function OffersCell(row: { tag: Tag }) {
  const queryCache = useQueryCache();
  const { showModal, ModalRender, hideModal } = useModal({});
  const handleButtonClick = (e: MouseEvent) => {
    e.stopPropagation();
    showModal(
      <AddOffersToTagModal
        tag={row.tag}
        onHideModal={({ tagId, selectedOffers }) => {
          const queryKey = `tag-list-${row.tag.bankMnemocode}`;
          const list = queryCache.getQueryData(queryKey) as Tag[];
          const newList = list.map((item) => {
            if (item.id === tagId) {
              return { ...item, offerToTag: selectedOffers.map((offer: { id: number; name: string }) => ({ offer })) };
            }
            return item;
          });
          queryCache.setQueryData(queryKey, newList);
          hideModal();
        }}
      />
    );
  };
  return (
    <div>
      <ModalRender />
      <div style={{ padding: '3px' }}>
        {row.tag.offerToTag.map((item: { offer: Offer }) => item.offer.name).join(', ')}
      </div>
      <Button outlined onClick={(e: any) => handleButtonClick(e)} text=" + / - " intent="success" />
    </div>
  );
}

function BannerCell(row: { tag: Tag }) {
  const [isLoading, setIsLoading] = useState(false);
  const queryCache = useQueryCache();
  const handleFileUpload = async (event: any) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append('banner', file);
    formData.append('tagId', String(row.tag.id));
    setIsLoading(true);
    const result = await baseApi.fetch(`/tag/banner/upload`, {
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    });
    const queryKey = `tag-list-${row.tag.bankMnemocode}`;
    const list = queryCache.getQueryData(queryKey) as Tag[];
    const newList = list.map((item) => {
      if (item.id === row.tag.id) {
        return { ...item, mainBanner: (result as any).src };
      }
      return item;
    });
    queryCache.setQueryData(queryKey, newList);
    setIsLoading(false);
  };
  return (
    <div style={{ padding: '5px' }}>
      {isLoading ? (
        <div style={{ padding: '10px' }}>
          <Loader size={100} />
        </div>
      ) : (
        <>
          <div>
            <img style={{ maxWidth: '150px' }} alt="Главный баннер" src={row.tag.mainBanner} />
          </div>
          <FileInput
            onClick={(e: any) => e.stopPropagation()}
            style={{ maxWidth: '160px' }}
            onInputChange={handleFileUpload}
            inputProps={{ accept: '.jpg, .jpeg, .JPG, .JPEG, .png, .PNG, .svg' }}
            text="Файл .JPG"
          />
        </>
      )}
    </div>
  );
}

export const TagsTable = () => {
  const queryCache = useQueryCache();
  const columns: EntityTableColumn<Tag>[] = React.useMemo(
    () => [
      {
        Header: 'Название',
        accessor: 'nameRu',
        id: 'nameRu',
        filter: 'text',
        Filter: TextColumnFilter,
      },
      {
        Header: 'Баннер',
        accessor: 'mainBanner',
        id: 'mainBanner',
        Cell: ({ row }) => <BannerCell tag={row.original} />,
      },
      {
        Header: 'Тип',
        accessor: 'type',
        id: 'type',
      },
      {
        Header: 'Мнемокод банка',
        accessor: 'bankMnemocode',
        id: 'bankMnemocode',
      },
      {
        Header: 'location',
        accessor: 'location',
        id: 'location',
      },
      {
        Header: 'Активность',
        accessor: 'isActive',
        id: 'isActive',
        Cell: ({ value }) => (value ? 'X' : ''),
      },
      {
        Header: 'offers',
        accessor: 'offerToTag',
        id: 'offerToTag',
        Cell: ({ row }) => <OffersCell tag={row.original} />,
      },
    ],
    []
  );
  const partners = usePartnerList();
  const partnerOptions = Array.isArray(partners.data)
    ? partners?.data?.map(({ id, mnemocode, components: { name } }: Partner) => ({
        value: id || 0,
        caption: `${mnemocode} - ${name}`,
      }))
    : [{ value: 0, caption: '' }];
  const handlePartnerSelect = (item: NumericSelectOption) => {
    const selectedPartner: Partner | undefined = partners?.data?.find(({ id }: Partner) => id === item.value);
    if (selectedPartner && selectedPartner.mnemocode !== partner.mnemocode) {
      setPartner(selectedPartner);
      queryCache.setQueryData('tag-list-selectedPartner', selectedPartner);
    }
  };
  let initialPartner = { components: { name: 'Выберите банк' } } as Partner;
  const cachePartner = queryCache.getQueryData('tag-list-selectedPartner');
  if (cachePartner) {
    initialPartner = cachePartner as Partner;
  }
  const [partner, setPartner] = useState(initialPartner);
  return (
    <>
      <div style={{ padding: '0 0 5px 5px' }}>
        <Select
          items={partnerOptions}
          filterable={false}
          itemRenderer={itemRenderer}
          onItemSelect={handlePartnerSelect}
        >
          <Button text={partner.components.name} rightIcon="caret-down" />
        </Select>
      </div>
      {!partner.mnemocode ? null : (
        <EntityTable<Tag | any>
          useEntityList={useTagList}
          columns={columns}
          entityEditUrl={URL.tagView}
          params={[partner.mnemocode]}
          initialSortBy="name"
        />
      )}
    </>
  );
};
