import { RestrictionsSectionNameType } from '@whitelabel/common/api';
import { RestrictionsFormValuesType } from '../types';

function getFullRestrictionName<T extends string>(name: string, postfix = ''): T {
  return `restriction${name.charAt(0).toUpperCase() + name.slice(1) + postfix}` as T;
}

export function getRestrictionSectionsFullNames(section: RestrictionsSectionNameType) {
  const fieldSectionName = getFullRestrictionName<keyof RestrictionsFormValuesType>(section);
  const fieldNameFrom = getFullRestrictionName<keyof RestrictionsFormValuesType>(section, 'FromDateTime');
  const fieldNameTo = getFullRestrictionName<keyof RestrictionsFormValuesType>(section, 'ToDateTime');
  const fieldMessage = getFullRestrictionName<keyof RestrictionsFormValuesType>(section, 'Message');
  const fieldIcon = getFullRestrictionName<keyof RestrictionsFormValuesType>(section, 'Icon');

  return { fieldSectionName, fieldNameFrom, fieldNameTo, fieldMessage, fieldIcon };
}
