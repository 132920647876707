import styled from 'styled-components';
import { MenuItem as MenuItemBase } from '@blueprintjs/core';

const width = 200;
export const MenuItem = styled(MenuItemBase)`
  min-width: ${width}px;
`;

export const SelectButtonText = styled.div`
  min-width: ${width}px;
`;
