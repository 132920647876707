import styled from 'styled-components';

export const FormWrapper = styled.div`
  padding: 11px;
  display: flex;
  form {
    width: 100%;
  }
`;

export const InputWrapper = styled.div`
  margin: 10px 10px 10px 0;
  width: 250px;
`;
