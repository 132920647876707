import React from 'react';
import { CashbackActionAddForm } from './cashback-action-add-form';
import * as S from './index.styles';

export const CashbackActionAddPage = () => {
  return (
    <S.Section>
      <S.Nav></S.Nav>
      <S.Section>
        <CashbackActionAddForm />
      </S.Section>
    </S.Section>
  );
};
