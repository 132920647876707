import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, Form, FormikHelpers } from 'formik';
import moment from 'moment/moment';
import { CashbackActionCategory } from './api/cashback-action-types';
import * as S from './cashback-action-edit-form.styles';
import { InputField, SumbitButton, StaticSelectField, DateInputField, SwitchField } from '../../ui/formik-controls';
import { cashbackActionApi } from './api/cashback-action';
import { AppToaster } from '../../ui/toaster';
import { API_URL_BASE_PATH } from '../../../api/base';
import { Loader } from '../../ui/shared/loader';

export const CategoryAddEditForm = () => {
  const { id } = useParams();
  const isEditMode = Boolean(id);
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [cashbackActionCategory, setCashbackActionCategory] = useState<Omit<CashbackActionCategory, 'id'>>({
    name: '',
    iconUrl: '',
    active: true,
    activeFrom: moment().format('YYYY-MM-DDTHH:mm'),
    activeTo: moment().add(1, 'M').format('YYYY-MM-DDTHH:mm'),
    temporal: true,
  });

  useEffect(() => {
    if (!isEditMode) {
      setIsLoading(false);
      return;
    }

    cashbackActionApi
      .getCategory(id)
      .then((data) => {
        setCashbackActionCategory(data);
      })
      .catch(() => history.push(`/cashback-action/categories`))
      .finally(() => setIsLoading(false));
  }, [history, id, isEditMode]);

  const handleSubmitAdd = async (
    values: CashbackActionCategory,
    { setSubmitting }: FormikHelpers<CashbackActionCategory>
  ) => {
    try {
      const { temporal, ...valuesFinal } = values;

      await cashbackActionApi.addCategory(valuesFinal);
      AppToaster.show({ message: `Категория успешно добавлена`, intent: 'success' });
      history.push(`/cashback-action/categories`);
    } catch (e) {
      AppToaster.show({ message: `Категория НЕ добавлена`, intent: 'danger' });
    } finally {
      setSubmitting(false);
    }
  };

  const handleSubmitEdit = async (
    values: CashbackActionCategory,
    { setSubmitting }: FormikHelpers<CashbackActionCategory>
  ) => {
    try {
      await cashbackActionApi.editCategory(id, values);
      AppToaster.show({ message: `Категория успешно сохранена`, intent: 'success' });
      history.push(`/cashback-action/categories`);
    } catch (e) {
      AppToaster.show({ message: `Категория НЕ сохранена`, intent: 'danger' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <S.Section>
      {isLoading ? (
        <S.LoaderWrapper>
          <Loader />
        </S.LoaderWrapper>
      ) : (
        <Formik<CashbackActionCategory>
          enableReinitialize
          initialValues={cashbackActionCategory as CashbackActionCategory}
          onSubmit={isEditMode ? handleSubmitEdit : handleSubmitAdd}
        >
          {({ isSubmitting }) => (
            <Form>
              <S.FormWrapper>
                <S.Column>
                  <SwitchField name="active" label="Активна" disabled={!cashbackActionCategory.temporal} />
                  {cashbackActionCategory.temporal && (
                    <>
                      <DateInputField
                        name="activeFrom"
                        label="Дата начала"
                        placeholder="YYYY-MM-DDTHH:mm"
                        formatDate={(date) => moment(date).format('YYYY-MM-DDTHH:mm')}
                        parseDate={(str) => moment(str, 'YYYY-MM-DDTHH:mm').toDate()}
                        timePrecision="minute"
                      />
                      <DateInputField
                        name="activeTo"
                        label="Дата окончания"
                        placeholder="YYYY-MM-DDTHH:mm"
                        formatDate={(date) => moment(date).format('YYYY-MM-DDTHH:mm')}
                        parseDate={(str) => moment(str, 'YYYY-MM-DDTHH:mm').toDate()}
                        timePrecision="minute"
                      />
                    </>
                  )}
                  <InputField name="name" label="Название" disabled={!cashbackActionCategory.temporal} />
                  <StaticSelectField
                    disabled={!cashbackActionCategory.temporal}
                    url={`${API_URL_BASE_PATH}/yandex/object-storage`}
                    name="iconUrl"
                    label="Иконка"
                  />
                </S.Column>
              </S.FormWrapper>
              {cashbackActionCategory.temporal && <SumbitButton isSubmitting={isSubmitting} />}
            </Form>
          )}
        </Formik>
      )}
    </S.Section>
  );
};
