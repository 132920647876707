"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OptionsPeriodEnum = exports.CombinationsPeriodEnum = exports.PositionEnum = void 0;
var PositionEnum;
(function (PositionEnum) {
    PositionEnum["TOP"] = "TOP";
    PositionEnum["BOTTOM"] = "BOTTOM";
    PositionEnum["MIDDLE"] = "MIDDLE";
    PositionEnum["HOME_BANNER"] = "HOME_BANNER";
})(PositionEnum = exports.PositionEnum || (exports.PositionEnum = {}));
var CombinationsPeriodEnum;
(function (CombinationsPeriodEnum) {
    CombinationsPeriodEnum["PRESENT"] = "PRESENT";
    CombinationsPeriodEnum["FUTURE"] = "FUTURE";
})(CombinationsPeriodEnum = exports.CombinationsPeriodEnum || (exports.CombinationsPeriodEnum = {}));
var OptionsPeriodEnum;
(function (OptionsPeriodEnum) {
    OptionsPeriodEnum["PAST"] = "PAST";
    OptionsPeriodEnum["PRESENT"] = "PRESENT";
    OptionsPeriodEnum["FUTURE"] = "FUTURE";
})(OptionsPeriodEnum = exports.OptionsPeriodEnum || (exports.OptionsPeriodEnum = {}));
