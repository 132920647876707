import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import auth from './auth';
import breadCrumbs from './breadCrumbs';
import tabs from './tabs';

export const createRootReducer = (historyObject: History) =>
  combineReducers({
    router: connectRouter(historyObject),
    auth,
    breadCrumbs,
    tabs,
  });

export type RootState = ReturnType<ReturnType<typeof createRootReducer>>;
export default createRootReducer;
