import React from 'react';
import { useUserList } from 'api/user-hooks';
import * as _ from 'lodash';
import { usePartnerList } from 'api/partner-hooks';
import { User } from 'api/user-types';
import { URL } from 'utils/url-builder';
import { EntityTable, EntityTableColumn } from 'components/ui/skeletons/entity-table';
import { BooleanColumnFilter, TextColumnFilter } from '../../ui/table/filter-types';

export const UsersTable = () => {
  const partners = usePartnerList();

  const banks = _.chain(partners?.data)
    .map((partner) => _.at(partner, ['id', 'components.name']))
    .fromPairs()
    .value();

  const columns: EntityTableColumn<User>[] = React.useMemo(
    () => [
      {
        Header: 'UserId',
        accessor: 'id',
        width: 80,
      },
      {
        Header: 'Login',
        accessor: 'email',
        filter: 'text',
        Filter: TextColumnFilter,
      },
      {
        Header: 'Имя',
        accessor: 'name',
        filter: 'text',
        Filter: TextColumnFilter,
      },
      {
        Header: 'Банк',
        accessor: 'partnerId',
        Cell: ({ value }) => banks[value] || null,
        width: 150,
      },
      {
        Header: 'Активен',
        accessor: 'isActive',
        filter: 'equals',
        Filter: BooleanColumnFilter,
        Cell: ({ value }) => (value ? 'Да' : 'Нет'),
        width: 80,
      },
      {
        Header: 'Роль',
        accessor: 'role',
        filter: 'text',
        Filter: TextColumnFilter,
        width: 80,
      },
      {
        Header: 'Удален',
        accessor: 'deletedWhen',
        filter: 'equals',
        Filter: BooleanColumnFilter,
        Cell: ({ value }) => (value ? 'Да' : 'Нет'),
        width: 80,
      },
    ],
    [banks]
  );

  return (
    <EntityTable<User> useEntityList={useUserList} entityEditUrl={URL.userView} columns={columns} initialSortBy="id" />
  );
};
