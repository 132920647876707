import { productApi } from 'api/product';
import { ProductListResponse, ProductResponse, Product } from 'api/product-types';
import {
  createUseEntityList,
  createUseEntity,
  createUseEntityAdd,
  createUseEntityUpdate,
  createUseEntityDelete,
} from 'api/base-hooks';

const SECTION = 'partner-product';

export const createUseProductList = (partnerId: number) =>
  createUseEntityList<ProductListResponse>(`${SECTION}:${partnerId}`, () => productApi.getList(partnerId));

export const createUseProduct = (partnerId: number) =>
  createUseEntity<ProductResponse>(`${SECTION}:${partnerId}`, (productId: number) =>
    productApi.getItem(partnerId, productId)
  );

export const createUseProductAdd = (partnerId: number) =>
  createUseEntityAdd<Product>(`${SECTION}:${partnerId}`, (data) => productApi.addItem(partnerId, data));

export const createUseProductUpdate = (partnerId: number) =>
  createUseEntityUpdate<Product>(`${SECTION}:${partnerId}`, (productId, data) =>
    productApi.editItem(partnerId, productId, data)
  );

export const createUseProductDelete = (partnerId: number) =>
  createUseEntityDelete<number>(`${SECTION}:${partnerId}`, (productId) => productApi.deleteItem(partnerId, productId));
