import { BaseApi } from '../../../../api/base';
import {
  ReportCurrencyResponse,
  ReportGoalTypesResponse,
  ReportResponse,
  ReportTypeEnum,
  ReportTypesResponse,
  ReportTypesResult,
} from './report-types';
import * as _ from 'lodash';

class ReportApi extends BaseApi {
  getTypes = async (): Promise<ReportTypesResult> => {
    const response = await this.fetch<ReportTypesResponse>(`/report/types`);
    const result = _.map(response, (type) => ({ type }));
    return result as ReportTypesResult;
  };

  getReport = async (type: ReportTypeEnum): Promise<ReportResponse> => {
    const response = await this.fetch<ReportResponse>(`/report/${type}`);
    return response;
  };

  postReport = async <T>(type: ReportTypeEnum, data: T): Promise<void> => {
    await this.fetch<void>(`/report/${type}`, {
      method: 'post',
      data,
    });
  };

  getCurrency = async (): Promise<ReportCurrencyResponse> => {
    const response = await this.fetch<ReportCurrencyResponse>(`/report/currency`);
    return response;
  };

  getGoalTypes = async (): Promise<ReportGoalTypesResponse> => {
    const response = await this.fetch<ReportGoalTypesResponse>(`/report/goaltypes`);
    return response;
  };
}

export const reportApi = new ReportApi();
