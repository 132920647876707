import React from 'react';
import { Form, Formik } from 'formik';
import { Tag } from './api/tag-types';
import * as S from './tag-form.styles';
import { InputField, SumbitButton, SwitchField } from '../../ui/formik-controls';
import { TagSchema } from './tag-schema';

const emptyData = {
  type: '',
  bankMnemocode: '',
  nameRu: '',
  mainBanner: '',
  location: '',
  isActive: false,
  offerToTag: [],
};

type AfterSubmitHandler = (data?: Tag) => void;
type TagFormProps = {
  initialValues?: Tag;
  onSubmit: (tag: Tag) => Promise<Tag | undefined>;
  onAfterSubmit?: AfterSubmitHandler;
};
export const TagForm = (props: TagFormProps) => {
  const { initialValues = emptyData, onSubmit, onAfterSubmit } = props;
  const data = { ...emptyData, ...initialValues } as Tag;
  return (
    <>
      <Formik<Tag>
        enableReinitialize
        initialValues={data}
        validationSchema={TagSchema}
        onSubmit={async (tag, { setSubmitting }) => {
          const result = await onSubmit(tag);
          setSubmitting(false);
          if (!result) {
            return;
          }
          if (onAfterSubmit) {
            onAfterSubmit();
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <S.FormWrapper>
              <S.Column>
                <InputField name="type" label="Тип" />
                <InputField name="bankMnemocode" label="Мнемокод банка" />
                <InputField name="nameRu" label="Имя (по русски)" />
                <InputField name="location" label="Локация" />
                <SwitchField name="isActive" label="Активен" />
              </S.Column>
            </S.FormWrapper>
            <SumbitButton isSubmitting={isSubmitting} />
          </Form>
        )}
      </Formik>
    </>
  );
};
