import React, { useState, useEffect } from 'react';
import moment from 'moment';
import * as _ from 'lodash';
import { Column } from 'react-table';
import { useParams } from 'react-router-dom';
import { Button } from '@blueprintjs/core/lib/esm/components';
import { baseApi } from '../../../api/base';
import { clientApi } from './api/client';
import { Table } from '../../ui/table';
import { Select } from '../../ui/form-controls/Select';
import { Balance, BalanceTransaction, OptionsDetails } from './api/client-types';
import * as S from './index.styles';
import { Loader } from '../../ui/shared/loader';

const monthOptions = Array.from(Array(12).keys())
  .map((i) => new Date(0, i).toLocaleString('ru-RU', { month: '2-digit' }))
  .map((month) => ({
    caption: month,
    value: month,
  }));

const yearOptions = Array.from(Array(5).keys())
  .map((i) =>
    moment()
      .subtract(i, 'year')
      .year()
      .toString()
  )
  .map((year) => ({
    caption: year,
    value: year,
  }));

const statusMap = {
  accrual: 'Зачислено',
  posted: 'Зачислено',
  hold: 'Ожидает зачисления',
  precalc: 'Предрасчет',
  error: 'Ошибка зачисления',
} as Record<string, string>;

interface StateInterface {
  period: string | undefined;
  balance: Balance | undefined;
  optionsDetails: OptionsDetails[] | undefined;
}

const emptyBalance: Balance = {
  accrual: 0,
  error: 0,
  hold: 0,
  precalc: 0,
  total: 0,
  transactions: [],
};

const initialState: StateInterface = {
  period: moment().format('YYYY-MM'),
  balance: emptyBalance,
  optionsDetails: undefined,
};

export const ClientBalance = () => {
  const { clientMnemocode, cardMnemocode } = useParams();

  const [isDetailsLoading, setIsDetailsLoading] = useState(false);
  const [isBalanceLoading, setIsBalanceLoading] = useState(false);
  const [state, setState] = useState<StateInterface>(initialState);

  const isLoading = isBalanceLoading || isDetailsLoading;

  const updState = (nextState: Partial<StateInterface>) => setState((prevState) => _.assign({}, prevState, nextState));

  const columns = React.useMemo<Column<BalanceTransaction>[]>(
    () => [
      {
        Header: 'Начислено Баллов',
        accessor: 'amount',
        width: 170,
      },
      {
        Header: 'Валюта',
        accessor: 'currencyName',
        width: 80,
      },
      {
        Header: 'Покупка (Руб.)',
        accessor: 'amountRub',
        width: 140,
        Cell: ({ value }: { value: number }) =>
          value
            ? Number(value).toLocaleString('ru-RU', {
                useGrouping: false,
                minimumFractionDigits: 2,
              })
            : '',
      },
      {
        Header: 'Категория',
        accessor: 'goalName',
      },
      {
        Header: 'Дата',
        accessor: 'transactionDate',
        width: 100,
        Cell: ({ value }: any) => value || '',
      },
      {
        Header: 'MCC',
        accessor: 'mcc',
        width: 60,
        Cell: ({ value }: any) => value || '',
      },
      {
        Header: 'Категория',
        accessor: 'mccName',
        width: 170,
        Cell: ({ value }: any) => value || '',
      },
      {
        Header: 'Торговая точка',
        accessor: 'merchDesc',
        Cell: ({ value }: any) => value || '',
      },
      {
        Header: 'Статус начисления',
        accessor: 'status',
        width: 170,
        Cell: ({ value }: any) => statusMap[value],
      },
    ],
    []
  );

  useEffect(() => {
    if (clientMnemocode && cardMnemocode) {
      setIsDetailsLoading(true);
      clientApi
        .getOptionsDateils(clientMnemocode, cardMnemocode)
        .then((optionsDetails) => {
          updState({ optionsDetails });
        })
        .finally(() => setIsDetailsLoading(false));
    }
  }, [clientMnemocode, cardMnemocode]);

  useEffect(() => {
    if (clientMnemocode && cardMnemocode && state.period && state.optionsDetails) {
      updState({ balance: emptyBalance });

      const periodOptionsDetails = state.optionsDetails.find((optionsDetail) => {
        return (
          'program' in optionsDetail &&
          moment(state.period).isBetween(
            optionsDetail.program.startDate,
            optionsDetail.program.finishDate || Date.now(),
            'month',
            '[]'
          )
        );
      });

      if (!periodOptionsDetails) {
        return;
      }

      setIsBalanceLoading(true);
      clientApi
        .getBalance(clientMnemocode, cardMnemocode, state.period, periodOptionsDetails.program.currency.currency)
        .then((balance) => {
          updState({ balance });
        })
        .finally(() => setIsBalanceLoading(false));
    }
  }, [clientMnemocode, cardMnemocode, state.period, state.optionsDetails]);

  const downloadExcel = async () => {
    const res: any = await baseApi.fetch(`/client/balance/download`, {
      params: {
        // eslint-disable-next-line @typescript-eslint/camelcase
        card_mnemocode: cardMnemocode,
        // eslint-disable-next-line @typescript-eslint/camelcase
        profile_id: clientMnemocode,
        period: state.period,
      },
    });
    const url = window.URL.createObjectURL(
      new Blob([new Uint8Array(res.data)], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
      })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `detailbalance-${clientMnemocode}.xlsx`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <S.Section>
      {isLoading ? (
        <S.LoaderWrapper>
          <Loader />
        </S.LoaderWrapper>
      ) : (
        <S.Section>
          <div style={{ padding: '1em' }}>
            <div style={{ padding: '1em 0' }}>
              <div style={{ fontSize: '160%', fontWeight: 'bold' }}>Баланс за </div>
              <Select
                options={monthOptions}
                onItemSelect={(item) =>
                  updState({
                    period: moment(state.period, 'YYYY-MM')
                      .month(Number(item.value) - 1)
                      .format('YYYY-MM'),
                  })
                }
                selectedValue={moment(state.period, 'YYYY-MM').format('MM')}
              />
              <Select
                options={yearOptions}
                onItemSelect={(item) =>
                  updState({
                    period: moment(state.period, 'YYYY-MM')
                      .year(Number(item.value))
                      .format('YYYY-MM'),
                  })
                }
                selectedValue={moment(state.period, 'YYYY-MM').format('YYYY')}
              />
            </div>
            <div>
              <p>
                <b>Зачислено:</b> <b style={{ color: 'green' }}>{state.balance?.accrual}</b>
              </p>
              <p>
                <b>Ошибка зачисления:</b> <b style={{ color: 'red' }}>{state.balance?.error}</b>
              </p>
              <p>
                <b>Ожидает зачисления:</b> <b style={{ color: 'orange' }}>{state.balance?.hold}</b>
              </p>
              <p>
                <b>Предрасчет:</b> <b style={{ color: 'green' }}>{state.balance?.precalc}</b>
              </p>
              <p>
                <b>Общий баланс:</b> <b style={{ color: 'green' }}>{state.balance?.total}</b>
              </p>
            </div>
            <div>
              <Button small intent="primary" onClick={downloadExcel}>
                Скачать Excel
              </Button>
            </div>
          </div>
          <Table<BalanceTransaction> data={state.balance?.transactions || []} columns={columns} />
        </S.Section>
      )}
    </S.Section>
  );
};
