import React, { useState, useCallback } from 'react';
import { AnimatePresence } from 'framer-motion';

import { Modal } from './Modal';

interface RenderModalProps {
  showCloseBtnOnMobile?: boolean;
  width?: number;
  windowedOnMobile?: boolean;
}

interface UseModalProps {
  onModalClose?: () => void;
  isVisibleOnMount?: boolean;
}

export function useModal({ onModalClose, isVisibleOnMount }: UseModalProps) {
  const [body, setBody] = useState();
  const [isVisible, setIsVisible] = useState(isVisibleOnMount);
  const showModal = useCallback((body) => {
    setBody(body);
    setIsVisible(true);
  }, []);
  const hideModal = useCallback(() => {
    if (typeof onModalClose === 'function') {
      onModalClose();
    }

    setIsVisible(false);
  }, [onModalClose]);
  function ModalRender({ showCloseBtnOnMobile, windowedOnMobile }: RenderModalProps) {
    return (
      <AnimatePresence>
        {Boolean(isVisible) && (
          <Modal showCloseBtnOnMobile={showCloseBtnOnMobile} closeModal={hideModal} windowedOnMobile={windowedOnMobile}>
            {body}
          </Modal>
        )}
      </AnimatePresence>
    );
  }

  return {
    showModal,
    hideModal,
    ModalRender,
  };
}
