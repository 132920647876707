import React from 'react';
import * as S from './sidebar.styles';

type SidebarProps = {
  children?: React.ReactNode;
};
export const Sidebar = (props: SidebarProps) => {
  const { children } = props;
  return <S.Aside>{children}</S.Aside>;
};
