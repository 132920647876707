import { ReactNode } from 'react';
import { Schema } from 'yup';
import { ReportTypeEnum } from '../api/report-types';
import { useActiveCardsSummary } from './active-cards-summary';
import { useBurnout } from './burnout';
import { useBasic } from './basic';
import { useCards } from './cards';
import { useClo } from './clo';
import { useMonthly } from './monthly';
import { useSms } from './sms';
import { useSummary } from './summary';

export interface FormFieldsInterface<T> {
  schema: Schema<object | undefined> | undefined;
  state: T | undefined;
  render: () => ReactNode;
}

export type FormFieldsFunction<T extends object = {}> = () => FormFieldsInterface<T>;

const mapTypeFormFields: Partial<{ [key in ReportTypeEnum]: FormFieldsFunction }> = {
  [ReportTypeEnum.ACTIVE_CARDS_SUMMARY]: useActiveCardsSummary,
  [ReportTypeEnum.BURNOUT]: useBurnout,
  [ReportTypeEnum.BASIC_NEW]: useBasic,
  [ReportTypeEnum.BASIC_POSTED]: useBasic,
  [ReportTypeEnum.CARDS]: useCards,
  [ReportTypeEnum.CLO]: useClo,
  [ReportTypeEnum.MONTHLY]: useMonthly,
  [ReportTypeEnum.SMS]: useSms,
  [ReportTypeEnum.SUMMARY]: useSummary,
};

const notMapFunction: FormFieldsFunction = () => ({ schema: undefined, state: undefined, render: () => undefined });

export const useFormFileds = (type: ReportTypeEnum): FormFieldsFunction => {
  return mapTypeFormFields[type] || notMapFunction;
};
