import React from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as _ from 'lodash';
import moment from 'moment';
import {
  InputField,
  SwitchField,
  SumbitButton,
  NumericSelectField,
  DateInputField,
  StaticSelectField,
} from 'components/ui/formik-controls';
import { FileField } from 'components/ui/formik-controls/file-field';
import { AuthorizeType, Program } from 'api/program-types';
import { PartnerProgramSchema } from './partner-program-schema';
import * as S from './partner-program-form.styles';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../../selectors/auth';
import { Role } from '../../../../utils/types';
import { API_URL_BASE_PATH } from '../../../../api/base';

/* eslint-disable @typescript-eslint/camelcase */
const emptyData = {
  precalculationPeriod: undefined,

  mnemocode: '',

  currency: '',
  productClass: '',
  isActive: true,

  components: {
    name: '',
    caption: '',

    hint: '',
    icon: '',
    logo: '',
    placeholder: '',

    signup_notice: '',

    entry_nr_input_mask: '',
    entry_nr_mask: '',
    currency_names: '',
    description: '',
    howToGet: '',
    howToUse: '',
    howToParticipate: '',
    banner: '',
    canRegister: false,
    canAuthorize: false,
    isPartnerProgram: false,

    authorizeType: null,
    signup_result_button_text: '',
    signup_result_message: '',
    signup_registration_hint: '',
    site_url: '',
  },
};
/* eslint-enable @typescript-eslint/camelcase */

type AfterSubmitHandler = (data?: Program) => void;
type ProgramFormProps = {
  initialValues?: Program;
  onSubmit: (program: Program) => Promise<Program | undefined>;
  onAfterSubmit?: AfterSubmitHandler;
};
export const PartnerProgramForm = (props: ProgramFormProps) => {
  const { initialValues = emptyData, onSubmit, onAfterSubmit } = props;
  const data = { ...emptyData, ...initialValues };
  const user = useSelector(userSelector);

  const authorizeTypeOptions = [
    { value: null, caption: '...' },
    { value: AuthorizeType.ONLINE, caption: 'Онлайн' },
    { value: AuthorizeType.DELAYED_ONLINE, caption: 'Онлайн авторизация с отсрочкой х-дней' },
    { value: AuthorizeType.DELAYED_CHARGES, caption: 'Отложенная авторизация с первыми начислениями' },
  ];

  if (!data.components.canRegister) {
    data.components.canRegister = false;
  }
  if (!data.components.canAuthorize) {
    data.components.canAuthorize = false;
  }
  if (!data.components.isPartnerProgram) {
    data.components.isPartnerProgram = false;
  }
  const handleSubmit = async (program: Program, { setSubmitting }: FormikHelpers<Program>) => {
    const result = await onSubmit(program);
    setSubmitting(false);
    if (!result) {
      return;
    }
    if (onAfterSubmit) {
      onAfterSubmit();
    }
  };
  const handleValidate = (program: Program) => {
    // TODO: hack for check not empty file field
    program.files?.forEach((file) => {
      _.set(program, file?.path, URL.createObjectURL(file.data));
    });
  };
  const log = (...args: any[]) => {
    console.log(...args);
    return true;
  };
  return (
    <Formik<Program>
      enableReinitialize
      initialValues={data}
      validationSchema={PartnerProgramSchema}
      onSubmit={handleSubmit}
      validate={handleValidate}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <S.FormWrapper>
            <S.Column>
              {!!values?.precalculationPeriod && [Role.SUDO, Role.GPADMIN].includes(user?.role) && (
                <S.Fieldset>
                  <legend>Расчетный период</legend>
                  <DateInputField
                    fill
                    name="precalculationPeriod.period"
                    label="Выбор даты"
                    placeholder="YYYY-MM"
                    formatDate={(date) => moment(date).format('YYYY-MM')}
                    parseDate={(str) => moment(str, 'YYYY-MM').toDate()}
                  />
                  <SwitchField name="precalculationPeriod.allowServiceUpdate" label="Перезаписать значение сервиса" />
                </S.Fieldset>
              )}
              <InputField name="mnemocode" label="Мнемокод" />
              <InputField name="currency" label="Валюта" />
              <InputField name="productClass" label="Класс" />
              <SwitchField name="isActive" label="Активен" />
              <InputField name="components.name" label="Название" />
              <InputField name="components.caption" label="Caption" />
              <InputField name="components.hint" label="Hint" />
              <StaticSelectField
                url={`${API_URL_BASE_PATH}/yandex/object-storage`}
                name="components.logo"
                label="Logo"
              />
              <StaticSelectField
                url={`${API_URL_BASE_PATH}/yandex/object-storage`}
                name="components.icon"
                label="Icon"
              />
              <InputField name="components.placeholder" label="Placeholder" />
              <SwitchField name="components.isPartnerProgram" label="Принадлежность к банковским баллам (милям)" />
            </S.Column>

            <S.Column>
              <InputField name="components.signup_notice" label="Signup notice" />
              <InputField name="components.entry_nr_input_mask" label="Entry nr input mask" />
              <InputField name="components.entry_nr_mask" label="Entry nr mask" />
              <InputField name="components.currency_names" label="Currency Names" />
              <InputField name="components.description" label="Description" />
              <InputField name="components.howToGet" label="HowToGet" />
              <InputField name="components.howToUse" label="HowToUse" />
              <InputField name="components.howToParticipate" label="howToParticipate" />
              <StaticSelectField
                url={`${API_URL_BASE_PATH}/yandex/object-storage`}
                name="components.banner"
                label="Banner"
              />
              <SwitchField name="components.canRegister" label="Можно Зарегистрироваться" />
              <SwitchField name="components.canAuthorize" label="Можно Авторизоваться" />

              <NumericSelectField
                options={authorizeTypeOptions as any}
                name="components.authorizeType"
                label="Authorize type"
                filterable={false}
              />

              <InputField name="components.signup_result_button_text" label="Signup result button text" />
              <InputField name="components.signup_result_message" label="Signup result message" />
              <InputField name="components.signup_registration_hint" label="Signup registration hint" />
              <InputField name="components.site_url" label="Site url" />
            </S.Column>
          </S.FormWrapper>
          <SumbitButton isSubmitting={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};
