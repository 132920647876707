export type ProgramListResponse = Program[];
export type ProgramResponse = Program;
export type ProgramAddResponse = { id: number };

export enum AuthorizeType {
  ONLINE = 'online',
  DELAYED_ONLINE = 'delayed_online',
  DELAYED_CHARGES = 'delayed_charges',
}

export type PrecalculationPeriod = {
  allowServiceUpdate: boolean;
  period: string;
};

export type Program = {
  precalculationPeriod?: PrecalculationPeriod;

  id?: number;
  mnemocode: string;
  currency: string;
  productClass: string;
  isActive: boolean;
  partner?: string;
  name?: string;
  components: {
    name: string;
    caption: string;
    hint: string;
    icon: string;
    logo: string;
    placeholder: string;
    signup_notice: string;
    entry_nr_input_mask: string;
    entry_nr_mask: string;
    currency_names?: string;
    description?: string;
    howToGet?: string;
    howToUse?: string;
    howToParticipate?: string;
    banner?: string;
    canRegister: boolean;
    canAuthorize: boolean;
    isPartnerProgram: boolean;

    authorizeType?: AuthorizeType | null;
    signup_result_button_text?: string;
    signup_result_message?: string;
    signup_registration_hint?: string;
    site_url?: string;
  };
  files?: { data: File; path: string }[];
};
