import {
  createUseEntity,
  createUseEntityAdd,
  createUseEntityUpdate,
  createUseEntityDelete,
  createUseEntityList,
} from 'api/base-hooks';
import { programApi } from './program';
import { ProgramListResponse, ProgramResponse, Program } from './program-types';

const SECTION = 'partner-program';

export const createUseProgramList = (partnerId: number) =>
  createUseEntityList<ProgramListResponse>(`${SECTION}:${partnerId}`, () => programApi.getList(partnerId));

export const createUseProgram = (partnerId: number) =>
  createUseEntity<ProgramResponse>(`${SECTION}:${partnerId}`, (programId: number) =>
    programApi.getItem(partnerId, programId)
  );

export const createUseProgramAdd = (partnerId: number) =>
  createUseEntityAdd<Program>(`${SECTION}:${partnerId}`, (data) => programApi.addItem(partnerId, data));

export const createUseProgramUpdate = (partnerId: number) =>
  createUseEntityUpdate<Program>(`${SECTION}:${partnerId}`, (programId, data) =>
    programApi.editItem(partnerId, programId, data)
  );

export const createUseProgramDelete = (partnerId: number) =>
  createUseEntityDelete<number>(`${SECTION}:${partnerId}`, (programId) => programApi.deleteItem(partnerId, programId));
