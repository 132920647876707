import { BaseApi } from 'api/base';
import { PartnerWidget, Widget } from './widget-types';

class PartnerWidgetApi extends BaseApi {
  getWidgetsByPartner = async (partnerId: number): Promise<PartnerWidget[]> => {
    const result = await this.fetch<PartnerWidget[]>(`/partner/${partnerId}/widget`);
    return result;
  };

  getAllWidgets = async (): Promise<Widget[]> => {
    const result = await this.fetch<Widget[]>(`/partner/all/widgets`);
    return result;
  };

  getItem = async (partnerId: number, widgetId: number): Promise<PartnerWidget> => {
    const result = await this.fetch<PartnerWidget>(`/partner/${partnerId}/widget/${widgetId}`);
    return result;
  };

  addItem = async (partnerId: number, data: PartnerWidget): Promise<PartnerWidget> => {
    const result = await this.fetch<PartnerWidget>(`/partner/${partnerId}/widget`, {
      method: 'post',
      data: { partnerId, ...data },
    });
    return { id: result.id, ...data };
  };

  editItem = async (partnerId: number, widgetId: number, data: PartnerWidget): Promise<PartnerWidget> => {
    await this.fetch<PartnerWidget>(`/partner/${partnerId}/widget/${widgetId}`, {
      method: 'put',
      data,
    });
    return data;
  };

  deleteItem = async (partnerId: number, widgetId: number): Promise<number> => {
    await this.fetch<PartnerWidget>(`/partner/${partnerId}/widget/${widgetId}`, { method: 'delete' });
    return widgetId;
  };
}

export const partnerWidgetApi = new PartnerWidgetApi();
