import { parse, format } from 'date-fns';
import { ru } from 'date-fns/locale';

type Months = [string, string, string, string, string, string, string, string, string, string, string, string];

const formatDate = (date: Date, format_?: string | string[], locale?: string): string => format(date, 'D MMM');

function formatDay(d: Date, locale = 'en') {
  return format(d, 'dddd, d MMM');
}

function formatMonthTitle(d: Date, locale = 'en') {
  return format(d, 'MMMM YYYY');
}

function formatWeekdayShort(i: number, locale: string) {
  return ru.localize?.day(i, { width: 'short' });
}

function formatWeekdayLong(i: number, locale: string) {
  return ru.localize?.day(i, { width: 'wide' });
}

function getFirstDayOfWeek(locale: string) {
  return ru.options?.weekStartsOn || 0;
}

const getMonths = (locale?: string): Months =>
  Array(12)
    .fill(null)
    .map((_, index) => ru?.localize?.month(index, { width: 'wide' }) || `${index}`) as Months;

const parseDate = (str: string, format?: string, locale?: string) => parse(str, format || 'DD.mm.yyyy', new Date());

// http://react-day-picker.js.org/api/LocaleUtils
export const localeUtils = {
  formatDate,
  formatDay,
  formatMonthTitle,
  formatWeekdayShort,
  formatWeekdayLong,
  getFirstDayOfWeek,
  getMonths,
  parseDate,
};
