import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@blueprintjs/core';
import { getUserProfile } from 'selectors/auth';
import { baseApi } from 'api/base';
import { IconNames } from '@blueprintjs/icons';
import * as S from './header.styles';
import { PartnerSelect } from './components/PartnerSelect';
import { Role } from '../../utils/types';

export const Header = () => {
  const user = useSelector(getUserProfile);

  const handleLogout = () => {
    baseApi.logOut();
  };

  const rolesPartnerSelect = [Role.SUDO, Role.GPADMIN, Role.GPUSER];

  return (
    <S.Header>
      <S.TopLeft>
        <h1>GoPoints Admin</h1>
      </S.TopLeft>
      <S.TopCenter>{rolesPartnerSelect.includes(user?.role as Role) && <PartnerSelect user={user} />}</S.TopCenter>
      <S.TopRight>
        <span>{user?.username}&nbsp;&nbsp;</span>
        <Button icon={IconNames.LOG_OUT} onClick={handleLogout} />
      </S.TopRight>
    </S.Header>
  );
};
