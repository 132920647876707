import React from 'react';
import { EntityTable, EntityTableColumn } from '../../ui/skeletons/entity-table';
import { BonusRegistryError } from './api/bonus-registry-types';
import { useBonusRegistryError } from './api/bonus-registry-hooks';
import * as S from './bonus-registry-table.styles';
import { Cell } from 'react-table';

export interface BonusRegistryErrorTableProps {
  id: string;
}

export const BonusRegistryErrorTable = (props: BonusRegistryErrorTableProps) => {
  const { id } = props;
  const columns: EntityTableColumn<BonusRegistryError>[] = React.useMemo(() => {
    return [
      {
        Header: 'ID клиента',
        accessor: 'clientId',
        id: 'clientId',
        width: 120,
      },
      {
        Header: 'ID карты',
        accessor: 'cardId',
        id: 'cardId',
        width: 120,
      },
      {
        Header: 'Сумма',
        accessor: 'amount',
        id: 'amount',
        width: 80,
      },
      {
        Header: 'Error Message',
        accessor: 'errorMessage',
        id: 'errorMessage',
        width: '100%',
        Cell: (cell: Cell) => <S.ValueWrapper title={cell.value}>{cell.value}</S.ValueWrapper>,
      },
      {
        Header: '№ строки',
        accessor: 'rowNumber',
        id: 'rowNumber',
        width: 100,
      },
    ];
  }, []);

  return (
    <EntityTable<BonusRegistryError>
      params={[id]}
      useEntityList={useBonusRegistryError}
      columns={columns}
      initialSortBy="rowNumber"
    />
  );
};
