import React from 'react';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { ContentState, convertToRaw, EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styled, { css } from 'styled-components';

interface Props {
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
  value: string;
  onChange: (value: string) => void;
}

const htmlToState = (str: string) => {
  const contentBlock = htmlToDraft(str);
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  return EditorState.createWithContent(contentState);
};

const stateToHtml = (editorState: EditorState) => {
  return draftToHtml(convertToRaw(editorState.getCurrentContent()));
};

export const WysiwygEditor = ({ value, onChange, disabled = false }: Props) => {
  const [editorState, setEditorState] = React.useState<EditorState>(htmlToState(value));

  // useEffect(() => {
  //   let editorHtml  = stateToHtml(editorState)
  //   let finalValue = stateToHtml(htmlToState(value))
  //
  //   if (finalValue !== editorHtml) {
  //     setEditorState(htmlToState(value));
  //   }
  // }, [value, editorState]);

  return (
    <Root $isDisabled={disabled}>
      <Editor
        editorState={editorState}
        toolbarClassName="toolbarClassName"
        wrapperClassName="wrapperClassName"
        editorClassName="wysiwyg-editor"
        toolbar={{
          options: ['inline', 'list', 'textAlign', 'link', 'emoji', 'image', 'remove', 'history'],
        }}
        onEditorStateChange={(editorState: EditorState) => {
          setEditorState(editorState);

          let htmlValue = stateToHtml(editorState);
          if (htmlValue.match(/<p><\/p>(\n)?/)) {
            htmlValue = '';
          }

          onChange(htmlValue);
        }}
      />
    </Root>
  );
};

const Root = styled.div<{ $isDisabled: boolean }>`
  ${({ $isDisabled }) =>
    $isDisabled &&
    css`
      opacity: 0.8;
      pointer-events: none;
    `}

  .wysiwyg-editor {
    height: 250px;
    background: white;

    .public-DraftEditor-content {
      padding-top: 1px;
    }

    .public-DraftStyleDefault-block {
      padding: 0 12px 12px 12px;
    }
  }
`;
