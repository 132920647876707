import React from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { TableLayout } from 'components/ui/layouts/table-layout';
import { EntityTableColumn } from 'components/ui/layouts/table-layout/table-layout-types';
import { PartnerProgramPage } from './partner-program-page';
import { createUseProgramList } from '../../../../api/program-hooks';
import { Program } from '../../../../api/program-types';

export const PartnerProgramsTable = ({ paramName }: { paramName: string }) => {
  const { partnerId } = useParams<{ [key: string]: string }>();
  const useProgramList = createUseProgramList(Number(partnerId));
  const { url } = useRouteMatch();

  const columns: EntityTableColumn<Program>[] = React.useMemo(
    () => [
      {
        Header: 'Мнемокод',
        accessor: 'mnemocode',
      },
      {
        Header: 'Название',
        accessor: 'components',
        id: 'name',
        Cell: ({ value }) => value?.name,
      },
      {
        Header: 'Валюта',
        accessor: 'currency',
      },
      {
        Header: 'Класс',
        accessor: 'productClass',
      },
      {
        Header: 'Активна',
        accessor: 'isActive',
        Cell: ({ value }) => (value ? 'Да' : 'Нет'),
      },
    ],
    []
  );

  return (
    <TableLayout<Program>
      paramName={paramName}
      caption="Программы"
      useEntityList={useProgramList}
      entityComponent={() => <PartnerProgramPage paramName={paramName} />}
      columns={columns}
      rowLinkPrefix={url}
      initialSortBy="name"
    />
  );
};
