import React, { useState } from 'react';
import { Cell } from 'react-table';
import { Button, Alert, Intent, ButtonGroup } from '@blueprintjs/core';
import { OfferCategoryInterface } from '@whitelabel/common/dist/services';
import { EntityTable, EntityTableColumn } from '../../ui/skeletons/entity-table';
import { useCashbackActionCategories } from './api/cashback-action-hooks';
import * as TS from './cashback-action-table.styles';
import * as S from './index.styles';
import { AppToaster } from '../../ui/toaster';
import { cashbackActionApi } from './api/cashback-action';
import { CashbackActionCategory } from './api/cashback-action-types';

export const CategoriesTable = () => {
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [reload, setReload] = useState<number>(Date.now());

  const handleDeleteCancel = () => setDeleteId(null);
  const handleDeleteConfirm = async () => {
    if (deleteId !== null) {
      cashbackActionApi
        .deleteCategory(deleteId)
        .then(() => {
          setReload(Date.now());
          AppToaster.show({ message: `Категория успешно удалена`, intent: 'success' });
        })
        .catch(() => AppToaster.show({ message: `Категория НЕ удалена`, intent: 'danger' }))
        .finally(() => setDeleteId(null));
    }
  };

  const columns: EntityTableColumn<CashbackActionCategory>[] = React.useMemo(() => {
    return [
      {
        Header: 'Id',
        accessor: 'id',
        width: 80,
        Cell: (cell: Cell) => <TS.ValueWrapper title={cell.value}>{cell.value}</TS.ValueWrapper>,
      },
      {
        Header: 'Имя',
        accessor: 'name',
        Cell: (cell: Cell) => <TS.ValueWrapper title={cell.value}>{cell.value}</TS.ValueWrapper>,
      },
      {
        Header: 'Статус',
        accessor: 'active',
        Cell: (cell: Cell) => (
          <TS.ValueWrapper title={cell.value ? 'Активна' : 'Не активна'}>
            {cell.value ? 'Активна' : 'Не активна'}
          </TS.ValueWrapper>
        ),
      },
      {
        Header: 'Дата начала',
        accessor: 'activeFrom',
        Cell: (cell: Cell<CashbackActionCategory>) => {
          return !cell.row.original.temporal ? '∞' : <TS.ValueWrapper title={cell.value}>{cell.value}</TS.ValueWrapper>;
        },
      },
      {
        Header: 'Дата окончания',
        accessor: 'activeTo',
        Cell: (cell: Cell<CashbackActionCategory>) => {
          return !cell.row.original.temporal ? '∞' : <TS.ValueWrapper title={cell.value}>{cell.value}</TS.ValueWrapper>;
        },
      },
      {
        Header: 'Действия',
        width: 100,
        Cell: (cell: Cell<CashbackActionCategory>) => {
          return (
            cell.row.original.temporal && (
              <Button
                intent="danger"
                disabled={false}
                onClick={(event: React.MouseEvent<HTMLElement>) => {
                  setDeleteId(cell.row.values.id);
                  event.stopPropagation();
                  event.preventDefault();
                }}
              >
                Удалить
              </Button>
            )
          );
        },
      },
      {
        Header: ' ',
        width: 10,
      },
    ];
  }, []);

  return (
    <S.Section>
      <Alert
        isOpen={deleteId !== null}
        icon="trash"
        intent={Intent.DANGER}
        cancelButtonText="Отмена"
        confirmButtonText="Удалить"
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
      >
        <h4>Подтвердите удаление Категории!</h4>
      </Alert>
      <EntityTable<CashbackActionCategory>
        key={reload}
        useEntityList={useCashbackActionCategories}
        columns={columns}
        initialSortBy="id"
        desc
        rowLinkPrefix="/cashback-action/categories"
      />
    </S.Section>
  );
};
