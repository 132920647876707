import { Partner } from 'api/partner-types';
import moment from 'moment';
import { PartnerRestrictions, restrictionsSections } from '@whitelabel/common/dist/api';
import { RestrictionsFormValuesType } from '../types';
import { getRestrictionSectionsFullNames } from './get-full-restriction-name';

export function getFormInitialValuesWithRestrictions(inputValues: Partner) {
  const restrictions = inputValues.restrictions ?? ({} as PartnerRestrictions);

  const valuesRestrictions: Partial<RestrictionsFormValuesType> = {};

  restrictionsSections.forEach((section) => {
    const { fieldSectionName, fieldNameFrom, fieldNameTo, fieldMessage, fieldIcon } =
      getRestrictionSectionsFullNames(section);
    const currentRestrictionsSection = restrictions[section];

    if (currentRestrictionsSection && currentRestrictionsSection.from && currentRestrictionsSection.to) {
      (valuesRestrictions as any)[fieldSectionName] = true;
      (valuesRestrictions as any)[fieldNameFrom] = toUTC(currentRestrictionsSection.from);
      (valuesRestrictions as any)[fieldNameTo] = toUTC(currentRestrictionsSection.to);
      (valuesRestrictions as any)[fieldMessage] = currentRestrictionsSection.message;
      (valuesRestrictions as any)[fieldIcon] = currentRestrictionsSection.icon;
    } else {
      (valuesRestrictions as any)[fieldSectionName] = false;
      (valuesRestrictions as any)[fieldNameFrom] = null;
      (valuesRestrictions as any)[fieldNameTo] = null;
      (valuesRestrictions as any)[fieldMessage] = null;
      (valuesRestrictions as any)[fieldIcon] = null;
    }
  });

  return { ...inputValues, ...(valuesRestrictions as RestrictionsFormValuesType) };
}

function toUTC(input: string) {
  return moment.utc(input).format('YYYY-MM-DD HH:mm:ss');
}
