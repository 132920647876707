import styled from 'styled-components';

export const FormWrapper = styled.div`
  padding: 11px;
  /* height: 100%; */
  display: flex;

  form {
    width: 100%;
  }
`;

export const Column = styled.div`
  width: 50%;

  &:first-child {
    padding-right: 11px;
  }
`;

export const TableRowWithButton = styled.div`
  display: flex;
  align-items: flex-end;

  > div {
    flex-grow: 1;
    margin-right: 5px;
  }
  > :last-child {
    flex-shrink: 0;
    height: 30px;
    margin-bottom: 15px;
  }
`;
