import React from 'react';
import { IconNames } from '@blueprintjs/icons';
import { URL } from 'utils/url-builder';
import { EntityList } from 'components/ui/skeletons/entity-list';
import { TagsTable } from './tags-table';

export const TagList = () => {
  return (
    <EntityList
      sectionText="Теги спецпредложений"
      icon={IconNames.CALCULATOR}
      listUrl={URL.tagList()}
      content={<TagsTable />}
      entityAddUrl={URL.tagAdd()}
    />
  );
};
