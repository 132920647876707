import { BaseApi } from '../../../../api/base';
import {
  BonusRegistryErrorResponse,
  BonusRegistryListResponse,
  BonusRegistryGoalsResponse,
  BonusRegistryGoalTypesResponse,
  BonusRegistryLoyaltiesResponse,
  BonusRegistryUpload,
} from './bonus-registry-types';
import { Goal } from '../../../../api/goal-types';
import { FormObject } from '../../../../utils';
import * as _ from 'lodash';

class BonusRegistryApi extends BaseApi {
  getList = async (params: any[] = []): Promise<BonusRegistryListResponse> => {
    const result = await this.fetch<BonusRegistryListResponse>(`/bonus/registry`);
    return result;
  };

  getError = async (id: string): Promise<BonusRegistryErrorResponse> => {
    const result = await this.fetch<BonusRegistryErrorResponse>(`/bonus/registry/${id}`);
    return result || [];
  };

  getGoals = async (): Promise<Goal[]> => {
    const result = await this.fetch<BonusRegistryGoalsResponse>(`/bonus/goals`);
    return result || [];
  };

  getGoalTypes = async (): Promise<string[]> => {
    const result = await this.fetch<BonusRegistryGoalTypesResponse>(`/bonus/goaltypes`);
    return result || [];
  };

  getLoyalties = async (bankMnemocode: string): Promise<BonusRegistryLoyaltiesResponse> => {
    const result = await this.fetch<BonusRegistryLoyaltiesResponse>(`/bonus/loyalties/${bankMnemocode}`);
    return result || [];
  };

  upload = async (data: BonusRegistryUpload): Promise<void> => {
    const formObject = FormObject(data);
    const formData = new FormData();

    _.chain(formObject)
      .omitBy(_.isNil)
      .forEach((value, key) => formData.append(key, value))
      .value();

    await this.fetch<void>(`/bonus/registry`, {
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    });
  };
}

export const bonusRegistryApi = new BonusRegistryApi();
