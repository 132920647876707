import React from 'react';
import { useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { InputField, SumbitButton, SwitchField, TextAreaField } from 'components/ui/formik-controls';
import { Partner } from 'api/partner-types';
import { PartnerSchema } from './partner-schema';
import * as S from './partner-form.styles';
import { getUserRole } from '../../../selectors/auth';
import {
  addRestrictionsValuesForSubmit,
  FullFormValuesType,
  getFormInitialValuesWithRestrictions,
  PartnerRestrictions,
} from './partner-restrictions';

const emptyData: Partner = {
  mnemocode: '',
  isActive: true,
  apiKey: '',
  domainName: '',
  components: {
    name: '',
    banner: '',
    currencyName: '',
    description: '',
    rulesLink: '',
  },
  prcMode: undefined,
  restrictions: {},
};

type PartnerFormProps = {
  initialValues?: Partner;
  onSubmit: (user: Partner) => Promise<Partner | undefined>;
  onAfterSubmit?: (data?: Partner) => void;
};

export const PartnerForm = (props: PartnerFormProps) => {
  const { initialValues = emptyData, onSubmit, onAfterSubmit } = props;
  const userRole = useSelector(getUserRole);
  const fullFormInitialValues = getFormInitialValuesWithRestrictions(initialValues);
  const { partnerId } = useParams<{ [key: string]: string }>();
  const isCreationMode = Number.isNaN(Number(partnerId));

  return (
    <>
      <Formik<FullFormValuesType>
        enableReinitialize
        initialValues={getFormInitialValuesWithRestrictions(initialValues)}
        validationSchema={PartnerSchema}
        onSubmit={async (formValues, { setSubmitting }) => {
          const result = await onSubmit(addRestrictionsValuesForSubmit(formValues));
          setSubmitting(false);
          if (!result) return;
          if (onAfterSubmit) onAfterSubmit();
        }}
      >
        {({ values, isSubmitting }) => (
          <Form>
            <S.FormWrapper>
              <S.Column>
                <InputField name="mnemocode" label="Мнемокод" />
                <InputField name="apiKey" label="API ключ" />
                <InputField name="domainName" label="Домен" />
                {userRole === 'SUDO' && <InputField name="requestSignatureKey" label="Request Signature Key" />}
                <InputField name="prcMode" label="prcMode" />
                <SwitchField name="isActive" label="Активен" />
                {!isCreationMode && <PartnerRestrictions values={values} initialValues={fullFormInitialValues} />}
              </S.Column>
              <S.Column>
                <InputField name="components.name" label="Название" />
                <InputField name="components.banner" label="Баннер" />
                <InputField name="components.currencyName" label="Валюта" />
                <InputField name="components.rulesLink" label="Ссылка на правила" />
                <TextAreaField name="components.description" label="Описание" />
              </S.Column>
            </S.FormWrapper>
            <SumbitButton isSubmitting={isSubmitting} />
          </Form>
        )}
      </Formik>
    </>
  );
};
