import React from 'react';
import { usePartnerList } from 'api/partner-hooks';
import { Partner } from 'api/partner-types';
import { EntityTableColumn } from 'components/ui/skeletons/entity-table';
import { TableLayout } from 'components/ui/layouts/table-layout';
import { useRouteMatch } from 'react-router-dom';
import { PartnerPage } from './partner-page';

export const PartnersTable = ({ paramName }: { paramName: string }) => {
  const { url } = useRouteMatch();

  const columns: EntityTableColumn<Partner>[] = React.useMemo(
    () => [
      {
        Header: 'Название',
        accessor: 'components',
        id: 'name',
        Cell: ({ value }) => value?.name,
      },
      {
        Header: 'Мнемокод',
        accessor: 'mnemocode',
      },
      {
        Header: 'Домен',
        accessor: 'domainName',
      },
      {
        Header: 'Баннер',
        accessor: 'components',
        Cell: ({ value }) => value?.banner || '',
      },
    ],
    []
  );
  return (
    <TableLayout<Partner>
      paramName={paramName}
      caption="Партнёры"
      useEntityList={usePartnerList}
      entityComponent={() => <PartnerPage paramName={paramName} />}
      columns={columns}
      rowLinkPrefix={url}
      initialSortBy="name"
    />
  );
};
