import {
  createUseEntity,
  createUseEntityDelete,
  createUseEntityList,
  createUseEntityUpdate,
} from '../../../../api/base-hooks';
import {
  CashbackActionListResponse,
  CashbackActionResponse,
  CashbackActionMerchantListResponse,
  CashbackActionMerchantResponse,
} from './cashback-action-types';
import { cashbackActionApi } from './cashback-action';

const SECTION = 'cashback-action';

export const useCashbackActionList = createUseEntityList<CashbackActionListResponse>(
  SECTION,
  cashbackActionApi.getList
);

export const useCashbackAction = createUseEntity<CashbackActionResponse>(SECTION, cashbackActionApi.getItem);
export const useCashbackActionUpdate = createUseEntityUpdate<any>(SECTION, cashbackActionApi.editItem);
export const useCashbackActionDelete = createUseEntityDelete(SECTION, cashbackActionApi.deleteItem);

export const useCashbackActionMerchantList = createUseEntityList<CashbackActionMerchantListResponse>(
  SECTION,
  cashbackActionApi.getMerchantList
);

export const useCashbackActionMerchant = createUseEntity<CashbackActionMerchantResponse>(
  SECTION,
  cashbackActionApi.getMerchantItem
);

export const useCashbackActionCategories = createUseEntityList(
  `${SECTION}-categories`,
  cashbackActionApi.getCategories
);
