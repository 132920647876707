import styled from 'styled-components';

export const Menu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

type ItemProps = {
  selected?: boolean;
};
export const Item = styled.li<ItemProps>`
  background: #1a2226;
  cursor: pointer;
  ${({ selected }) => `color: ${selected ? '#fff' : '#b8c7ce'};`}
  padding: 10px 25px 10px 15px;
  ${({ selected }) => `border-left: 3px solid ${selected ? '#3c8dbc' : 'transparent'};`}// a {
`;
