import styled from 'styled-components';

export const ValueWrapper = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  /*
  :hover {
    overflow: visible;
    position: absolute;
    width: auto;
    height: 36px;
    padding: 6px;
  }
  */
`;
