import React from 'react';
import { useParams } from 'react-router-dom';
import { EntityForm } from 'components/ui/layouts/entity-form';
import { TabsRouter } from 'components/ui/layouts/tabs-router';
import { Tab } from 'components/ui/layouts/tabs-router/tabs-router-types';
import { PartnerProgramForm } from './partner-program-form';
import {
  createUseProgram,
  createUseProgramAdd,
  createUseProgramUpdate,
  createUseProgramDelete,
} from 'api/program-hooks';
import { Program } from 'api/program-types';

export const PartnerProgramPage = ({ paramName }: { paramName: string }) => {
  const { partnerId, programId } = useParams<{ [key: string]: string }>();

  const useProgram = createUseProgram(Number(partnerId));
  const useProgramAdd = createUseProgramAdd(Number(partnerId));
  const useProgramUpdate = createUseProgramUpdate(Number(partnerId));
  const useProgramDelete = createUseProgramDelete(Number(partnerId));

  const tabs: Tab[] = [
    {
      caption: 'Карточка',
      component: (
        <EntityForm<Program>
          paramName={paramName}
          listUrl={`/partner/${partnerId}/program`}
          getEntityText={(data) => data?.components?.name}
          component={PartnerProgramForm}
          useEntity={useProgram}
          useEntityAdd={useProgramAdd}
          useEntityMutate={useProgramUpdate}
          useEntityDelete={useProgramDelete}
        />
      ),
    },
  ];
  return <TabsRouter route={`/partner/${partnerId}/program/${programId}`} tabs={tabs} />;
};
