import {
  createUseEntity,
  createUseEntityAdd,
  createUseEntityUpdate,
  createUseEntityDelete,
  createUseEntityList,
} from 'api/base-hooks';
import { modalApi } from './modal';
import { ModalsResponse, ModalResponse } from './modal-types';

const SECTION = 'partner-modal';

export const createUseModalList = (partnerId: number) =>
  createUseEntityList<ModalsResponse>(`${SECTION}:${partnerId}`, () => modalApi.getList(partnerId));

export const createUseModal = (partnerId: number) =>
  createUseEntity<ModalResponse>(`${SECTION}:${partnerId}`, (modalId: number) => modalApi.getItem(partnerId, modalId));

export const createUseModalAdd = (partnerId: number) =>
  createUseEntityAdd<ModalResponse>(`${SECTION}:${partnerId}`, (data) => modalApi.addItem(partnerId, data));

export const createUseModalUpdate = (partnerId: number) =>
  createUseEntityUpdate<ModalResponse>(`${SECTION}:${partnerId}`, (modalId, data) =>
    modalApi.editItem(partnerId, modalId, data)
  );

export const createUseModalDelete = (partnerId: number) =>
  createUseEntityDelete<number>(`${SECTION}:${partnerId}`, (modalId) => modalApi.deleteItem(partnerId, modalId));
