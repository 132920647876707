"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Actions = void 0;
var Actions;
(function (Actions) {
    Actions["LOGIN"] = "LOGIN";
    // CHANGE_PASSWORD = 'CHANGE_PASSWORD',
    Actions["VIEW_CLIENT"] = "VIEW_CLIENT";
    Actions["VIEW_BALANCE"] = "VIEW_BALANCE";
    Actions["VIEW_BONUS"] = "VIEW_BONUS";
    // LOCK_CLIENT = 'LOCK_CLIENT',
    // UNLOCK_CLIENT = 'UNLOCK_CLIENT',
    Actions["CHANGE_CLIENT_LOCK"] = "CHANGE_CLIENT_LOCK";
    Actions["CHANGE_OPTION"] = "CHANGE_OPTION";
    Actions["CREATE_USER"] = "CREATE_USER";
    // LOCK_USER = 'LOCK_USER',
    // UNLOCK_USER = 'UNLOCK_USER',
    Actions["LOGIN_AS_CLIENT"] = "LOGIN_AS_CLIENT";
    Actions["UPDATE_USER"] = "CHANGE_USER";
})(Actions = exports.Actions || (exports.Actions = {}));
