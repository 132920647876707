import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Tab, Tabs } from '@blueprintjs/core';
import { IconNames, IconName } from '@blueprintjs/icons';
import { Breadcrumbs, BreadcrumbItem } from 'components/ui/breadcrumbs';
import { PageSkeleton } from 'components/ui/page-skeleton';
import { QueryResult, MutationResultPair } from 'react-query';
import { ApiError } from 'api/base';
import { URL } from 'utils/url-builder';
import { AppToaster } from 'components/ui/toaster';

type EntityTab = {
  name: string;
  caption: string;
  component: () => JSX.Element;
  pageComponent?: () => JSX.Element;
};

export const BreadcrumbsContext = React.createContext<BreadcrumbItem[] | null>(null);
export const TabsContext = React.createContext<EntityTab[] | undefined>(undefined);

type FormProps<T> = {
  initialValues?: T;
  onSubmit: (user: T) => Promise<T | undefined>;
  onAfterSubmit?: (data?: T) => void;
};

export type EntitySkeletonProps<T> = {
  icon?: IconName;
  listUrl: string;
  sectionText: string;
  getEntityText: (data: T | undefined) => string | undefined;
  form: (props: FormProps<T>) => JSX.Element;
  useEntity: (id: number, params?: any) => QueryResult<T, ApiError>;
  useEntityAdd: () => MutationResultPair<T, unknown, T, unknown>;
  useEntityMutate: () => MutationResultPair<T, unknown, { id: number; entity: T }, unknown>;
  useEntityDelete: () => MutationResultPair<number, unknown, number, unknown>;
  tabs?: EntityTab[];
  params?: any;
};

export const EntitySkeleton = <T extends {}>(props: EntitySkeletonProps<T>) => {
  const {
    listUrl,
    icon,
    sectionText,
    getEntityText,
    form: Form,
    useEntity,
    useEntityAdd,
    useEntityMutate,
    useEntityDelete,
    tabs,
    params,
  } = props;
  const breadcrumbs = useContext(BreadcrumbsContext);
  const history = useHistory();
  const { section, id, tabId, childId } = useParams<{ [key: string]: string }>();
  const childRecordId = childId === 'add' ? undefined : childId;
  const recordId = tabId !== URL.defaultTab ? childRecordId || id : id;
  // const { data, isLoading } = useEntity(Number(childId) || Number(id));
  const { data }: { data: any } = useEntity(breadcrumbs ? Number(childId) : Number(id), params);
  const [addEntity] = useEntityAdd();
  const [mutateEntity] = useEntityMutate();
  const [deleteEntity] = useEntityDelete();

  let items: BreadcrumbItem[] = breadcrumbs || [
    { href: listUrl, icon, text: sectionText },
    {
      icon: id ? IconNames.EDIT : IconNames.ADD,
      href: id && URL.viewUrl(section, id, URL.defaultTab),
      text: id ? getEntityText(data) : 'новая запись',
      current: tabId === URL.defaultTab,
    },
  ];

  const tab = tabs?.find(({ name }) => name === tabId);

  // tab table
  if (tab && tab.name !== URL.defaultTab) {
    items.push({ text: tab.caption, href: URL.viewUrl(section, id, tabId) });
  }
  // tabs child form
  if (!tabs && childId && items[items.length - 1].text !== getEntityText(data)) {
    items.push({ text: childRecordId ? getEntityText(data) : 'новая запись' });
  }

  const handleSubmit = async (entity: T) => {
    if (!recordId) {
      const addResult = await addEntity(entity);
      AppToaster.show({ message: `Успешно добавлено`, intent: 'success' });
      history.push(listUrl);
      return addResult;
    }
    const mutateResult = await mutateEntity({ id: Number(recordId), entity });
    AppToaster.show({ message: `Успешно сохранено`, intent: 'success' });
    history.push(listUrl);
    return mutateResult;
  };

  const handleDelete = async () => {
    await deleteEntity(Number(childId) || Number(id));
    AppToaster.show({ message: `Успешно удалено`, intent: 'success' });
    history.push(listUrl);
  };

  const handleRestore = async () => {
    const entity: T = { ...data, deletedWhen: false };
    const mutateResult = await mutateEntity({ id: Number(recordId), entity });
    AppToaster.show({ message: `Успешно восстановлено`, intent: 'success' });
    history.push(listUrl);
    return mutateResult;
  };

  const handleTabChange = (tab: React.ReactText, prevTabId: string) => {
    const newPath = URL.viewUrl(section, id, tab.toString());
    history.push(newPath);
  };
  const form = <Form initialValues={data} onSubmit={handleSubmit} />;
  const pageTabs = tabs && [{ name: URL.defaultTab, caption: 'Карточка', component: () => form }, ...tabs];

  // tab item form
  if (tab && tab.pageComponent && childId) {
    const Component = tab.pageComponent;
    return (
      <BreadcrumbsContext.Provider value={items}>
        <TabsContext.Provider value={pageTabs}>
          <Component />
        </TabsContext.Provider>
      </BreadcrumbsContext.Provider>
    );
  }

  // tab list
  if (tab && tabId !== URL.defaultTab) {
    const Component = tab.component;
    return (
      <BreadcrumbsContext.Provider value={items}>
        <TabsContext.Provider value={pageTabs}>
          <Component />
        </TabsContext.Provider>
      </BreadcrumbsContext.Provider>
    );
  }

  // card
  return (
    <PageSkeleton
      breadcrumbs={<Breadcrumbs items={items} />}
      actions={
        <>
          {data?.id ? (
            data?.deletedWhen === false ? (
              <Button intent="danger" text="Удалить" onClick={handleDelete} />
            ) : (
              <Button intent="warning" text="Восстановить" onClick={handleRestore} />
            )
          ) : (
            ''
          )}
        </>
      }
      content={
        <>
          {id && tabs ? (
            <Tabs large onChange={handleTabChange} selectedTabId={tabId}>
              {[{ name: URL.defaultTab, caption: 'Карточка', component: () => form }, ...tabs].map(
                ({ name, caption, component }) => (
                  <Tab key={name} id={name} title={caption} panel={component()} />
                )
              )}
            </Tabs>
          ) : (
            form
          )}
        </>
      }
    />
  );
};
