import styled from 'styled-components';
import { Colors } from '@blueprintjs/core';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 230px;
  background-color: ${Colors.LIGHT_GRAY4};
  height: calc(100% - 50px);
  overflow-y: auto;
`;
