import React, { useState } from 'react';
import { IconNames } from '@blueprintjs/icons';
import { FileInput, MenuItem, Button } from '@blueprintjs/core';
import { Select, ItemRenderer } from '@blueprintjs/select';
import * as S from './cashback-action-add-form.styles';
import { Partner } from '../../../api/partner-types';
import { usePartnerList } from '../../../api/partner-hooks';
import { baseApi } from '../../../api/base';
import { AppToaster } from '../../ui/toaster';
import { Loader } from '../../ui/shared/loader';
import { useHistory } from 'react-router-dom';

interface NumericSelectOption {
  value: number;
  caption: string;
}

const itemRenderer: ItemRenderer<NumericSelectOption> = (item, { handleClick, modifiers, query }) => {
  return <MenuItem onClick={handleClick} key={item.value} text={item.caption} />;
};

export const CashbackActionAddForm = () => {
  const history = useHistory();

  const [partner, setPartner] = useState({ id: 0, name: 'Выберите партнера' } as {
    id: number | undefined;
    name: string;
    mnemocode: string;
  });
  const [file, setFile] = useState({ name: 'Выберите файл .xlsx', data: null } as {
    name: string;
    data: Blob | null;
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const partners = usePartnerList();
  const partnerOptions = Array.isArray(partners.data)
    ? partners?.data?.map(({ id, mnemocode, components: { name } }: Partner) => ({
        value: id || 0,
        caption: `${mnemocode} - ${name}`,
      }))
    : [{ value: 0, caption: '' }];
  const handlePartnerSelect = (item: NumericSelectOption) => {
    const partner: Partner | undefined = partners?.data?.find(({ id }: Partner) => id === item.value);
    if (partner) {
      setPartner({
        id: partner.id,
        name: `${partner.mnemocode} - ${partner.components.name}`,
        mnemocode: partner.mnemocode,
      });
    }
  };
  const handleFileUpload = (event: any) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    setFile({ name: file.name, data: file });
  };
  const handleSubmitForm = async () => {
    if (!partner.id || !file.data) {
      return;
    }
    const formData = new FormData();
    formData.append('mnemocode', partner.mnemocode);
    formData.append('file', file.data);
    setIsSubmitting(true);
    try {
      const result = await baseApi.fetch('/cashback-action', {
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });
      AppToaster.show({ message: `Кэшбэк-акции успешно загружены`, intent: 'success' });
    } catch (error) {
      AppToaster.show({ message: `Кэшбэк-акции НЕ загружены`, intent: 'danger' });
    } finally {
      setIsSubmitting(false);
      history.push(`/cashback-action`);
    }
  };
  return (
    <div style={{ padding: '0 1em 1em 1em' }}>
      <S.InputWrapper>
        <div>Партнер</div>
        <Select
          items={partnerOptions}
          filterable={false}
          itemRenderer={itemRenderer}
          onItemSelect={handlePartnerSelect}
        >
          <Button style={{ width: '250px' }} text={partner.name} rightIcon="caret-down" />
        </Select>
      </S.InputWrapper>

      <S.InputWrapper>
        <div>Файл .XLSX</div>
        <FileInput onInputChange={handleFileUpload} inputProps={{ accept: '.xlsx' }} text={file.name} />
      </S.InputWrapper>

      {isSubmitting ? (
        <S.InputWrapper>
          <Loader />
        </S.InputWrapper>
      ) : (
        <div>
          <S.InputWrapper>
            <Button
              style={{ width: '250px' }}
              large
              onClick={() => handleSubmitForm()}
              text="Загрузить"
              intent="primary"
              icon={IconNames.SAVED}
              disabled={!partner?.mnemocode || !file?.data}
            />
          </S.InputWrapper>
        </div>
      )}
    </div>
  );
};
