import React from 'react';
import { FormGroup } from '@blueprintjs/core';
import { Field, FieldProps } from 'formik';
import { FormikFieldProps } from './utils';
import { WysiwygEditor } from '../WysiwygEditor';

export const WysiwygField = (props: Omit<FormikFieldProps, 'inline'>) => {
  const { name, label, disabled } = props;

  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <FormGroup inline={false} label={label} labelFor={name} labelInfo={meta.touched && meta.error}>
          <WysiwygEditor
            disabled={disabled}
            value={field.value}
            onChange={(value) => {
              field.onChange(name)(value);
            }}
          />
        </FormGroup>
      )}
    </Field>
  );
};
