import styled from 'styled-components';

export const ValueMultilineWrapper = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
`;

export const ValueWrapper = styled(ValueMultilineWrapper)`
  white-space: nowrap;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
