import { BaseApi } from 'api/base';
import { ProfileResponse } from 'api/auth-types';

class HeaderApi extends BaseApi {
  changePartner = async ({
    partnerId,
  }: {
    partnerId: number;
  }): Promise<{ token: string; profile: ProfileResponse }> => {
    const response = await this.fetch<{ token: string; profile: ProfileResponse }>(`/auth/change-partner`, {
      method: 'PUT',
      data: {
        partnerId,
      },
    });
    if (response && response.profile && response.token) {
      this.token = response.token;
    }
    return response;
  };
}

export const headerApi = new HeaderApi();
