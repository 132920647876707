import { eventChannel } from 'redux-saga';
import { all, call, take, takeEvery, put } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { appStarted, logIn, logOut, profileReceived } from 'actions/index';
import { baseApi } from 'api/base';
import { authApi } from 'api/auth';
import { ProfileResponse } from 'api/auth-types';
import { push } from 'connected-react-router';
import { URL } from 'utils/url-builder';

function createUnauthorizedChannel() {
  return eventChannel((emitter) => {
    const listener = () => {
      emitter('event');
    };
    baseApi.addEventListener('unauthorized', listener);
    return () => {
      baseApi.removeEventListener('unauthorized', listener);
    };
  });
}

function* watchUnauthorizedEvents() {
  const channel = yield call(createUnauthorizedChannel);
  try {
    while (true) {
      yield take(channel);
      yield put(logOut());
    }
  } finally {
    // eslint-disable-next-line no-console
    console.warn('watchUnauthorizedEvents terminated');
  }
}

function* loadProfile(action: ActionType<typeof logIn>): Generator {
  try {
    const profile = (yield authApi.getProfile()) as ProfileResponse;
    yield put(profileReceived(profile));
    // eslint-disable-next-line no-empty
  } catch (e) {}
}

function* redirectToHome(action: ActionType<typeof logIn>): Generator {
  yield put(push('/account'));
}

function* redirectToSignIn(action: ActionType<typeof logOut>): Generator {
  yield put(push('/account'));
}

export default function* () {
  // prettier-ignore
  yield all([
    takeEvery(appStarted, watchUnauthorizedEvents),
    takeEvery(logIn, redirectToHome),
    takeEvery([appStarted, logIn], loadProfile),
    takeEvery(logOut, redirectToSignIn)
  ]);
}
