import React from 'react';
import { IconBase } from './IconBase';

import {
  Cards,
  Home,
  Flame,
  Action,
  Bonus,
  Book,
  Exchanged,
  Download,
  Exit,
  Bubble,
  ExternalLink,
  Mail,
  Phone,
  Rules,
  Purchases,
  Settings,
  Star,
  Tutorial,
  Welcome,
  Sign,
  RoundCheck,
  RoundStop,
  Warning,
  Clock,
  Calc,
  Calendar,
  Change,
  Refund,
  Details,
  Stars,
  History,
  CalendarLate,
  Structure,
  Question,
  Arrow,
  Conversion,
  Time,
  Categories,
  Boxes,
  Cross,
  Check,
  Offers,
  Help,
  Cash,
  Basket,
  Ticket,
  User,
  Globe,
  Bolt,
  Bank,
  Hello,
  Gift,
  Wallet,
  ConversionToRoubles,
  ReturnFromMarketplace,
} from './content/iconsWireframeList';

const iconContent = {
  Cards,
  Home,
  Flame,
  Action,
  Bonus,
  Book,
  Exchanged,
  Download,
  Exit,
  Bubble,
  ExternalLink,
  Mail,
  Phone,
  Rules,
  Purchases,
  Settings,
  Star,
  Tutorial,
  Welcome,
  Sign,
  RoundCheck,
  RoundStop,
  Warning,
  Clock,
  Calc,
  Calendar,
  Change,
  Refund,
  Details,
  Stars,
  History,
  CalendarLate,
  Structure,
  Question,
  Arrow,
  Conversion,
  Time,
  Categories,
  Boxes,
  Cross,
  Check,
  Offers,
  Help,
  Cash,
  Basket,
  Ticket,
  User,
  Globe,
  Bolt,
  Bank,
  Hello,
  Gift,
  Wallet,
  ConversionToRoubles,
  ReturnFromMarketplace,
};

export type IconWireFrameType = keyof typeof iconContent;

interface Props {
  size?: number;
  color?: string;
  strokeWidth?: number;
  icon?: IconWireFrameType;
}

export function IconWireFrame({ icon, size, color, strokeWidth }: Props) {
  if (!icon) {
    return null;
  }
  const Icon = iconContent[icon];
  return (
    <IconBase size={size} color={color} strokeWidth={strokeWidth}>
      <Icon />
    </IconBase>
  );
}
