import React, { FormEvent, useRef, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { FormikProps } from 'formik/dist/types';
import { FileInput, FormGroup } from '@blueprintjs/core';
import { Field, FieldProps } from 'formik';
import { FormikFieldProps } from './utils';

interface FileSelectFieldProps extends FormikFieldProps {
  preView?: boolean;
  inputProps: React.HTMLProps<HTMLInputElement>;
  fill?: boolean;
  imageProps?: React.HTMLProps<HTMLImageElement>;
  onSelect?: (event: FormEvent<HTMLInputElement>, value: File) => void;
}

export const FileSelectField = (props: FileSelectFieldProps) => {
  const {
    name,
    label,
    inline = false,
    disabled = false,
    preView = false,
    inputProps,
    fill = false,
    imageProps,
    onSelect,
  } = props;
  const [text, setText] = useState('Выберите файл');
  const fileInputRef = useRef<FileInput>(null);
  const onInputChange = (event: FormEvent<HTMLInputElement>, form: FormikProps<any>) => {
    const file = (event.target as any).files[0];
    if (file) {
      setText(file.name);
      form.setFieldValue(name, file);
      onSelect?.(event, file);
    }
  };
  const getImgSrc = (value: string | Blob | undefined): string | undefined => {
    return typeof value === 'string' ? value : value instanceof Blob ? URL.createObjectURL(value) : undefined;
  };
  return (
    <Field name={name}>
      {({ field, meta, form }: FieldProps) => {
        /* eslint-disable */
        const fileUploadInputDOM = React.useMemo(() => {
          const node = (ReactDOM.findDOMNode(fileInputRef.current) as HTMLElement)?.querySelector(
            '.bp3-file-upload-input'
          );
          node?.classList?.add('bp3-input');
          return node;
        }, [fileInputRef.current]);

        useEffect(() => {
          fileUploadInputDOM?.classList.toggle('bp3-intent-warning', !!meta.error);
        }, [meta.error]);

        return (
          <FormGroup inline={inline} label={label} labelFor={name} labelInfo={meta.error}>
            {preView && (
              <img
                style={{ width: '200px', display: 'block', margin: '0 0 5px 0' }}
                alt={field.value?.name || field.value}
                src={getImgSrc(field.value)}
                {...(imageProps as any)}
              />
            )}
            <FileInput
              ref={fileInputRef}
              fill={fill}
              onInputChange={(event) => onInputChange(event, form)}
              disabled={disabled}
              inputProps={{ ...inputProps, name }}
              text={text}
            />
          </FormGroup>
        );
      }}
    </Field>
  );
};
