import React from 'react';
import { ReportTypesTable } from './report-types-table';
import * as S from './index.styles';

export const ReportTypesPage = () => {
  return (
    <S.Section>
      <S.Nav></S.Nav>
      <S.Section>
        <ReportTypesTable />
      </S.Section>
    </S.Section>
  );
};
