import { BaseApi } from 'api/base';
import * as Types from 'api/auth-types';

class AuthApi extends BaseApi {
  login = async (email: string, password: string): Promise<Types.LoginResponse> => {
    const data = { email, password };
    const result = await this.fetch<Types.LoginResponse>('/auth/login', { method: 'post', data });
    this.token = result.access_token;
    return result;
  };

  getProfile = async (): Promise<Types.ProfileResponse> => {
    const result = await this.fetch<Types.ProfileResponse>('/auth/profile');
    return result;
  };

  getLoginURL = async ({
    externalId,
    domain,
  }: {
    externalId: string;
    domain: string;
  }): Promise<{ authorizationLink: string }> => {
    const result = await this.fetch<{ authorizationLink: string }>('/auth/asadmin', {
      method: 'post',
      params: { externalId, domain },
    });
    return result;
  };
}

export const authApi = new AuthApi();
