import * as Yup from 'yup';
import { errorMessages } from 'components/ui/formik-controls';

export const ChangePwdSchema = Yup.object().shape({
  password: Yup.string().required(errorMessages.required),
  newPassword: Yup.string()
    .required(errorMessages.required)
    .matches(/(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).{8,}/, errorMessages.passwordComplexity),
  confirmationPassword: Yup.string().oneOf([Yup.ref('newPassword')], errorMessages.passwordsNotMatch),
});
