import React from 'react';
import { IconNames } from '@blueprintjs/icons';
import { useUser, useUserAdd, useUserUpdate, useUserDelete } from 'api/user-hooks';
import { URL } from 'utils/url-builder';
import { EntitySkeleton } from 'components/ui/skeletons/entity/entity';
import { User } from 'api/user-types';
import { UserForm } from './user-form';

export const UserPage = () => {
  return (
    <EntitySkeleton<User>
      icon={IconNames.USER}
      listUrl={URL.userList()}
      sectionText="Пользователи"
      getEntityText={(data) => data?.email}
      form={UserForm}
      useEntity={useUser}
      useEntityAdd={useUserAdd}
      useEntityMutate={useUserUpdate}
      useEntityDelete={useUserDelete}
    />
  );
};
