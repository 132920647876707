import {
  createUseEntity,
  createUseEntityAdd,
  createUseEntityDelete,
  createUseEntityList,
  createUseEntityUpdate,
} from 'api/base-hooks';
import { Partner, PartnerListResponse, PartnerResponse } from 'api/partner-types';
import { partnerApi } from 'api/partner';

export const usePartnerList = createUseEntityList<PartnerListResponse>('partner', partnerApi.getList);
export const usePartner = createUseEntity<PartnerResponse>('partner', partnerApi.getItem);
export const usePartnerAdd = createUseEntityAdd<Partner>('partner', partnerApi.addItem);
export const usePartnerUpdate = createUseEntityUpdate<Partner>('partner', partnerApi.editItem);
export const usePartnerDelete = createUseEntityDelete<number>('partner', partnerApi.deleteItem);
