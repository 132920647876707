import React from 'react';
import { BonusRegistryForm } from './bonus-registry-form';
import * as S from './index.styles';

export const BonusRegistryAddPage = () => {
  return (
    <S.Section>
      <S.Nav></S.Nav>
      <S.Section>
        <BonusRegistryForm />
      </S.Section>
    </S.Section>
  );
};
