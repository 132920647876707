import styled from 'styled-components';

export const ModalContainer = styled.div`
  min-height: 50vh;
  min-width: 50vh;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  padding: 10px;
`;

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  padding: 0 5px 0 0;
`;

export const Td = styled.td`
  border: 1px solid black;
  padding: 5px;
`;
