import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@blueprintjs/core';
import { QueryResult, MutationResultPair } from 'react-query';
import { ApiError } from 'api/base';
import { AppToaster } from 'components/ui/toaster';
import { addBreadCrumb } from 'actions';

type FormProps<T> = {
  initialValues?: T;
  onSubmit: (user: T) => Promise<T | undefined>;
  onAfterSubmit?: (data?: T) => void;
};

export type EntityLayoutProps<T> = {
  listUrl: string;
  paramName: string;
  getEntityText: (data: T | undefined) => string | undefined;
  component: (props: FormProps<T>) => JSX.Element;
  useEntity: (id: number, params?: any) => QueryResult<T, ApiError>;
  useEntityAdd: () => MutationResultPair<T, unknown, T, unknown>;
  useEntityMutate: () => MutationResultPair<T, unknown, { id: number; entity: T }, unknown>;
  useEntityDelete: () => MutationResultPair<number, unknown, number, unknown>;
  params?: any;
};

export const EntityForm = <T extends {}>(props: EntityLayoutProps<T>) => {
  const {
    listUrl,
    getEntityText,
    component: Component,
    useEntity,
    useEntityAdd,
    useEntityMutate,
    useEntityDelete,
    params,
    paramName,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const pathParams = useParams<{ [key: string]: string }>();
  const recordId = pathParams[paramName];
  const { data, isLoading } = useEntity(Number(recordId), params);
  const handleSubmit = async (entity: T) => {
    if (!recordId || recordId === 'add') {
      const addResult = await addEntity(entity);
      if (addResult) {
        AppToaster.show({ message: `Успешно добавлено`, intent: 'success' });
      }
      history.push(listUrl);
      return addResult;
    }
    const mutateResult = await mutateEntity({ id: Number(recordId), entity });
    if (mutateResult) {
      AppToaster.show({ message: `Успешно сохранено`, intent: 'success' });
    }
    history.push(listUrl);
    return mutateResult;
  };
  const handleDelete = async () => {
    await deleteEntity(Number(recordId));
    AppToaster.show({ message: `Успешно удалено`, intent: 'success' });
    history.push(listUrl);
  };
  const component = <Component initialValues={data} onSubmit={handleSubmit} />;
  const [addEntity] = useEntityAdd();
  const [mutateEntity] = useEntityMutate();
  const [deleteEntity] = useEntityDelete();
  const path = history.location.pathname;
  const caption = getEntityText(data);

  useEffect(() => {
    dispatch(addBreadCrumb({ path, caption }));
  }, [dispatch, path, caption]);
  return (
    <>
      {deleteEntity && recordId !== 'add' && (
        <div style={{ padding: '10px 5px' }}>
          <Button intent="danger" text="Удалить" onClick={handleDelete} />
        </div>
      )}
      {component}
    </>
  );
};
