import * as Yup from 'yup';
import { errorMessages } from 'components/ui/formik-controls';

export const TagSchema = Yup.object().shape({
  type: Yup.string().required(errorMessages.required),
  bankMnemocode: Yup.string().required(errorMessages.required),
  nameRu: Yup.string().required(errorMessages.required),
  mainBanner: Yup.string(),
  location: Yup.string(),
  isActive: Yup.boolean(),
});
