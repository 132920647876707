import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { TabItem } from '../section-layout-types';

type TabsProps = {
  path?: string;
  items?: TabItem[];
};

const Panel = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
`;

const Item = styled.div<{ isActive: boolean }>`
  border-radius: 5px 5px 0 0;
  border-top: 1px solid gray;
  border-right: 1px solid gray;
  border-bottom: ${({ isActive }) => (isActive ? 'none' : '1px solid gray')};
  padding: 5px;
`;

const Disabled = styled.span`
  color: rgba(92, 112, 128, 0.6);
  cursor: not-allowed;
`;

const TabsComponent = (props: TabsProps) => {
  const location = useLocation();
  const { items, path } = props;
  if (!Array.isArray(items) || items.length < 2) {
    return <></>;
  }
  return (
    <Panel>
      {items.map((item) => {
        const href = `${path}${item.path}`;
        return (
          <Item isActive={href === location.pathname} key={href}>
            {item?.disabled ? <Disabled>{item.caption}</Disabled> : <Link to={href}>{item.caption}</Link>}
          </Item>
        );
      })}
      <div style={{ borderBottom: '1px solid gray', flexGrow: 1 }} />
    </Panel>
  );
};

const mapStateToProps = ({ tabs: { path, items } }: { tabs: { path: string; items: TabItem[] } }) => {
  return { path, items };
};

export const Tabs = connect(mapStateToProps)(TabsComponent);
