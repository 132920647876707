import React from 'react';
import * as S from './index.styles';
import { CategoryAddEditForm } from './category-add-edit-form';

export const CategoryAddPage = () => {
  return (
    <S.Section>
      <S.Nav />
      <S.Section>
        <CategoryAddEditForm />
      </S.Section>
    </S.Section>
  );
};
