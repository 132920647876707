import styled from 'styled-components';

export const Header = styled.header`
  position: relative;
  display: flex;
  height: 50px;
  background-color: #3c8dbc;
  z-index: 30;
  justify-content: space-between;
  align-items: stretch;
`;

export const TopLeft = styled.div`
  width: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #367fa9;
  color: #fff;
  text-decoration: none;
`;

export const TopRight = styled.div`
  padding: 0 10px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-decoration: none;
`;

export const TopCenter = styled.div`
  flex-grow: 1;
`;
