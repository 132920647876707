import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Formik, Form, FormikHelpers, Field, FieldProps } from 'formik';
import moment from 'moment';
import * as _ from 'lodash';
import { FormGroup } from '@blueprintjs/core';
import {
  BonusCalculationEnum,
  BonusCurrencyEnum,
  CalculationRuleEnum,
  CashbackActionAddress,
  CashbackActionEdit,
  OfferTypeEnum,
} from './api/cashback-action-types';
import * as S from './cashback-action-edit-form.styles';
import {
  DateInputField,
  InputField,
  NumericInputField,
  NumericSelectField,
  SumbitButton,
  FileSelectField,
  SwitchField,
  TextAreaField,
  MultiSelectField,
} from '../../ui/formik-controls';
import { cashbackActionApi } from './api/cashback-action';
import { AppToaster } from '../../ui/toaster';
import { Loader } from '../../ui/shared/loader';
import { usePartnerList } from '../../../api/partner-hooks';
import { useCashbackActionCategories } from './api/cashback-action-hooks';
import { arrayToCsv, csvToArray } from '../../../utils';
import { isCashbackActionNew } from './utils';
import { WysiwygField } from '../../ui/formik-controls/wysiwyg-field';

const addressKeys: (keyof CashbackActionAddress)[] = ['city', 'street', 'house', 'phone', 'work_time'];

export const CashbackActionEditForm = () => {
  const { id } = useParams();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [cashbackAction, setCashbackAction] = useState<CashbackActionEdit>();

  const bonusCalculationOptions = Object.keys(BonusCalculationEnum).map((key) => ({ value: key, caption: key }));
  const calculationRuleOptions = Object.keys(CalculationRuleEnum).map((key) => ({ value: key, caption: key }));
  const bonusCurrencyOptions = Object.keys(BonusCurrencyEnum).map((key) => ({ value: key, caption: key }));
  const offerTypeOptions = Object.keys(OfferTypeEnum).map((key) => ({ value: key, caption: key }));
  const partnerMnemocodeOptions = _.chain(usePartnerList())
    .get('data')
    .map((partner) => ({ value: partner?.mnemocode, caption: `${partner?.mnemocode} - ${partner?.components?.name}` }))
    .value();

  const categoriesOptions = _.chain(useCashbackActionCategories())
    .get('data')
    .map((category) => ({ value: category.id, caption: category.name }))
    .value();

  useEffect(() => {
    cashbackActionApi
      .getItem(id)
      .then((data) => {
        const dataFinal: CashbackActionEdit = {
          ...data,
          addresses: data.addresses ? arrayToCsv(data.addresses, addressKeys) : '',
        };

        setCashbackAction(dataFinal);
      })
      .catch(() => history.push(`/cashback-action`))
      .finally(() => setIsLoading(false));
  }, [history, id]);

  const handleSubmit = async (values: CashbackActionEdit, { setSubmitting }: FormikHelpers<CashbackActionEdit>) => {
    let addresses: CashbackActionAddress[] | null = null;

    try {
      addresses = values.addresses ? (csvToArray(values.addresses, addressKeys) as CashbackActionAddress[]) : null;
    } catch (e) {
      AppToaster.show({ message: `Адреса магазинов: ${e.message.toLowerCase()}`, intent: 'danger' });
      return;
    }

    try {
      await cashbackActionApi.editItem(id, {
        ...values,
        addresses,
      });
      AppToaster.show({ message: `Кэшбэк-акция успешно сохранена`, intent: 'success' });
      history.push(`/cashback-action`);
    } catch (e) {
      AppToaster.show({ message: `Кэшбэк-акция НЕ сохранена`, intent: 'danger' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <S.Section>
      {isLoading ? (
        <S.LoaderWrapper>
          <Loader />
        </S.LoaderWrapper>
      ) : (
        <Formik<CashbackActionEdit>
          enableReinitialize
          initialValues={cashbackAction as CashbackActionEdit}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, values }) => (
            <Form>
              <S.FormWrapper>
                <S.Column>
                  <SwitchField name="isForAll" label="Подтверждёна" />
                  <InputField name="title" label="Название" />
                  <Field name="startAt">
                    {({ field }: FieldProps) => (
                      <FormGroup>Новая {field.value && isCashbackActionNew(field.value) ? 'Да' : 'Нет'}</FormGroup>
                    )}
                  </Field>
                  <FileSelectField
                    preView
                    name="iconUrl"
                    label="Логотип"
                    inputProps={{ accept: '.jpg, .jpeg, .JPG, .JPEG, .png, .PNG, .svg', id: 'iconUrl' }}
                  />
                  <NumericSelectField
                    options={offerTypeOptions}
                    name="offerType"
                    label="Тип акции"
                    filterable={false}
                    disabled
                  />
                  <SwitchField
                    name="isActivationRequired"
                    label="Требуется активация"
                    disabled={values.offerType === 'ONLINE'}
                  />
                  <NumericSelectField
                    options={calculationRuleOptions}
                    name="calculationRule"
                    label="Тип расчета"
                    filterable={false}
                  />
                  <NumericSelectField
                    options={bonusCalculationOptions}
                    name="bonusCalculation"
                    label="Тип значения"
                    filterable={false}
                  />
                  <NumericSelectField
                    options={bonusCurrencyOptions}
                    name="bonusCurrency"
                    label="Валюта расчета"
                    filterable={false}
                  />
                  <NumericInputField name="bonusValue" label="Размер кэшбэка" stepSize={0.1} />
                  <NumericInputField name="holdTime" label="Среднее время ожидания кэшбэка" />
                  <DateInputField
                    name="startAt"
                    label="Дата начала"
                    placeholder="YYYY-MM-DD"
                    formatDate={(date) => moment(date).format('YYYY-MM-DD')}
                    parseDate={(str) => moment(str, 'YYYY-MM-DD').toDate()}
                  />
                  <DateInputField
                    name="stopAt"
                    label="Дата окончания"
                    placeholder="YYYY-MM-DD"
                    formatDate={(date) => moment(date).format('YYYY-MM-DD')}
                    parseDate={(str) => moment(str, 'YYYY-MM-DD').toDate()}
                  />
                  <DateInputField
                    name="displayDate"
                    label={`Дата начала отображения в ЛК ${values.isProlongation ? '' : '(UTC)'}`}
                    placeholder="YYYY-MM-DD"
                    formatDate={(date) => moment(date).format('YYYY-MM-DD')}
                    parseDate={(str) => moment(str, 'YYYY-MM-DD').toDate()}
                  />
                  <SwitchField
                    name="isProlongation"
                    label="Использовать время на устройстве клиента"
                    disabled={!values.displayDate}
                  />
                  <NumericSelectField
                    options={partnerMnemocodeOptions}
                    name="partnerMnemocode"
                    label="Банк"
                    filterable={false}
                  />
                  <MultiSelectField<number>
                    name="offerCategories"
                    label="Категории"
                    items={categoriesOptions}
                    placeholder=""
                  />
                </S.Column>
                <S.Column>
                  <WysiwygField name="description" label="Описание" />
                  <WysiwygField name="fullDescription" label="Полное описание" />
                  <TextAreaField name="partnerShortDescription" label="О магазине" rows={6} resize="none" />
                  <InputField name="www" label="Сайт магазина" />
                  <TextAreaField
                    name="addresses"
                    label="Адреса магазинов (город, улица, дом, телефон, время работы)"
                    rows={6}
                    resize="none"
                  />
                </S.Column>
              </S.FormWrapper>
              <SumbitButton isSubmitting={isSubmitting} />
            </Form>
          )}
        </Formik>
      )}
    </S.Section>
  );
};
