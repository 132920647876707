import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { URL } from 'utils/url-builder';
import { ClientPage } from './client-page';
import { ClientsTable } from './clients-table';
import { ClientBalance } from './client-balance';
import { ClientBonus } from './client_bonus';
import { ClientOptions } from './client-options';

export const ClientRouter = () => {
  return (
    <Switch>
      <Route path={URL.clientSection()} exact component={ClientsTable} />
      <Route path={`${URL.clientSection()}/options/:clientMnemocode/:cardMnemocode`} component={ClientOptions} />
      <Route path={`${URL.clientSection()}/balance/:clientMnemocode/:cardMnemocode`} component={ClientBalance} />
      <Route path={`${URL.clientSection()}/bonus/:clientMnemocode/:currency`} component={ClientBonus} />
      <Route path={`${URL.clientSection()}/:mnemocode`} component={ClientPage} />
    </Switch>
  );
};
