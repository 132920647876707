import React, { useState } from 'react';
import * as S from './index.styles';
import { ReportTable } from './report-table';
import { ReportForm } from './report-form';
import { useParams } from 'react-router-dom';

export const ReportPage = () => {
  const { type } = useParams();
  const [submitTime, setSubmitTime] = useState<number>(Date.now);

  const handleRefresh = () => setSubmitTime(Date.now);

  return (
    <S.Section>
      <S.Nav></S.Nav>
      <S.Section>
        <ReportForm type={type} onSubmit={handleRefresh} onRefresh={handleRefresh} />
        <ReportTable type={type} key={submitTime} />
      </S.Section>
    </S.Section>
  );
};
