import { BaseApi } from 'api/base';
import * as _ from 'lodash';
import * as Types from 'api/partner-types';
import { FormObject } from '../utils';

class PartnerApi extends BaseApi {
  getList = async (): Promise<Types.PartnerListResponse> => {
    const result = await this.fetch<Types.PartnerListResponse>('/partner');
    return result;
  };

  getItem = async (id: number): Promise<Types.PartnerResponse> => {
    const result = await this.fetch<Types.PartnerResponse>(`/partner/${id}`);
    return result;
  };

  addItem = async (data: Types.Partner): Promise<Types.Partner> => {
    const result = await this.fetch<Types.AddPartnerResponse>(`/partner`, { method: 'post', data });
    return { id: result.id, ...data };
  };

  editItem = async (id: number, data: Types.Partner): Promise<Types.Partner> => {
    const formObject = FormObject(data);
    const formData = new FormData();

    _.chain(formObject)
      .omitBy(_.isUndefined)
      .forEach((value, key) => formData.append(key, value))
      .value();

    await this.fetch<Types.PartnerResponse>(`/partner/${id}`, {
      method: 'put',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    });
    return data;
  };

  deleteItem = async (id: number): Promise<number> => {
    await this.fetch<Types.PartnerResponse>(`/partner/${id}`, { method: 'delete' });
    return id;
  };
}

export const partnerApi = new PartnerApi();
