import React from 'react';
import * as S from './hooks.styles';
import * as Yup from 'yup';
import { DateInputField, errorMessages, SelectField, SwitchField } from '../../../ui/formik-controls';
import { FormFieldsFunction } from './form-fields';
import { BasicInterface, TransactionStatusEnum } from '../api/report-types';
import * as _ from 'lodash';
import { usePartnerList } from '../../../../api/partner-hooks';
import moment from 'moment';
import { useReportCurrency } from '../api/report-hooks';

const state: Partial<BasicInterface> = {
  partnerMnemocode: undefined,
  period: undefined,
  status: undefined,
  minCreatedTime: undefined,
  maxCreatedTime: undefined,
  currency: undefined,
  testReport: false,
};

const schema: Yup.Schema<object | undefined> = Yup.object().shape({
  partnerMnemocode: Yup.string().required(errorMessages.required),
  period: Yup.string().required(errorMessages.required),
  status: Yup.string().required(errorMessages.required),
  minCreatedTime: Yup.string().optional(),
  maxCreatedTime: Yup.string().optional(),
  currency: Yup.string().optional(),
  testReport: Yup.boolean().optional(),
});

export const useBasic: any = () => {
  const partnerMnemocodeOptions = _.chain(usePartnerList())
    .get('data')
    .map((partner) => ({ value: partner?.mnemocode, caption: `${partner?.mnemocode} - ${partner?.components?.name}` }))
    .value();

  const statusOptions = _.chain(TransactionStatusEnum)
    .map((status) => ({ value: status, caption: status }))
    .value();

  const currencyOptions = _.chain(useReportCurrency())
    .get('data')
    .map((currency) => ({
      value: currency?.currency,
      caption: `${currency?.currency} - ${currency?.details || currency?.name}`,
    }))
    .value();

  function render() {
    return (
      <S.FormWrapper>
        <S.Column>
          <SelectField
            options={partnerMnemocodeOptions}
            name="partnerMnemocode"
            label="Партнер"
            filterable={false}
            placeholder="Выберите партнера"
          />
          <SelectField
            options={statusOptions}
            name="status"
            label="Статус"
            filterable={false}
            placeholder="Выберите статус"
          />
          <SelectField
            options={currencyOptions}
            name="currency"
            label="Валюта"
            placeholder="Выберите валюту"
            popoverProps={{
              boundary: 'viewport',
              popoverClassName: 'scrollable',
            }}
          />
          <SwitchField name="testReport" label="Тестовый отчет" />
        </S.Column>
        <S.Column>
          <DateInputField
            name="period"
            label="Период"
            placeholder="YYYY-MM"
            formatDate={(date) => moment(date).format('YYYY-MM')}
            parseDate={(str) => moment(str, 'YYYY-MM').toDate()}
          />
          <DateInputField
            name="minCreatedTime"
            label="Минимальное время создания бонусных транзакций"
            placeholder="YYYY-MM-DDTHH:mm:ss"
            formatDate={(date) => moment(date).format('YYYY-MM-DDTHH:mm:ss')}
            parseDate={(str) => moment(str, 'YYYY-MM-DDTHH:mm:ss').toDate()}
            timePrecision="second"
          />
          <DateInputField
            name="maxCreatedTime"
            label="Максимальное время создания бонусных транзакций"
            placeholder="YYYY-MM-DDThh:mm:ss"
            formatDate={(date) => moment(date).format('YYYY-MM-DDTHH:mm:ss')}
            parseDate={(str) => moment(str, 'YYYY-MM-DDTHH:mm:ss').toDate()}
            timePrecision="second"
          />
        </S.Column>
      </S.FormWrapper>
    );
  }

  return {
    state,
    schema,
    render,
  };
};
