import React, { Ref, useEffect, useState } from 'react';
import { Alert, Button, MenuItem } from '@blueprintjs/core';
import { Select, ItemRenderer } from '@blueprintjs/select';
import { InputWrapper } from '../client-page.styles';
import './ChangeCardOptionAlert.scss';
import { clientApi } from '../api/client';
import { AppToaster } from '../../../ui/toaster';
import * as _ from 'lodash';

interface OptionsCardProgram {
  is_active: boolean;
  loyalty_code: string;
  program_name: string;
  product_option_id: number;
}

interface OptionsCardOption {
  option_id: number;
  option_name: string;
  programs: OptionsCardProgram[];
}

const optionItemRenderer: ItemRenderer<OptionsCardOption> = (item, { handleClick, modifiers, query }) => {
  return <MenuItem onClick={handleClick} key={item?.option_id} text={item?.option_name} />;
};

const programItemRenderer: ItemRenderer<OptionsCardProgram> = (item, { handleClick, modifiers, query }) => {
  return (
    <MenuItem
      onClick={handleClick}
      disabled={item?.is_active === false}
      key={item?.product_option_id}
      text={item?.program_name || item?.loyalty_code}
    />
  );
};

export interface ChangeCardOptionAlertProps {
  onClose?: (confirm: boolean, mnemocode: string) => void;
}

export interface ChangeCardOptionAlertHandles {
  open(mnemocode: string, productOptionId?: number): void;
  close(confirm: boolean): void;
}

export const ChangeCardOptionAlert = React.forwardRef(
  (props: ChangeCardOptionAlertProps, ref?: Ref<ChangeCardOptionAlertHandles>) => {
    React.useImperativeHandle(ref, () => ({
      open(mnemocode: string, productOptionId?: number) {
        setMnemocode(mnemocode);
        setProductOptionId(productOptionId);
      },
      close(confirm: boolean) {
        closeAlert(confirm);
      },
    }));

    const { onClose } = props;

    const [isOpen, setIsOpen] = useState(false);
    const [mnemocode, setMnemocode] = useState<string | undefined>(undefined);
    const [productOptionId, setProductOptionId] = useState<number | undefined>(undefined);

    useEffect(() => {
      if (mnemocode) {
        resetSelect();
        clientApi
          .getOptionsCard(mnemocode)
          .then((result: OptionsCardOption[]) => {
            setOptionOptions(result);
            if (productOptionId) {
              const lastOption = _.find(result, (opt) =>
                _.find(opt.programs, ['product_option_id', productOptionId])
              ) as OptionsCardOption;
              if (lastOption) {
                setOption(lastOption);
                setProgramOptions(lastOption?.programs);
              }
              const lastProgram = _.find(lastOption?.programs, ['product_option_id', productOptionId]);
              if (lastProgram) {
                setProgram(lastProgram);
              }
            }
            setIsOpen(true);
          })
          .catch(() => {});
      }
    }, [mnemocode]);

    const [option, setOption] = useState<Partial<OptionsCardOption>>({ option_name: 'Выберите опцию' });
    const [program, setProgram] = useState<Partial<OptionsCardProgram>>({ program_name: 'Выберите программу' });
    const [optionOptions, setOptionOptions] = useState<OptionsCardOption[]>([]);
    const [programOptions, setProgramOptions] = useState<OptionsCardProgram[]>([]);

    const resetSelect = () => {
      setOption({ option_name: 'Выберите опцию' });
      setProgram({ program_name: 'Выберите программу' });
    };

    const closeAlert = (confirm: boolean) => {
      setMnemocode(undefined);
      setIsOpen(false);
      if (onClose) {
        onClose(confirm, mnemocode as string);
      }
    };

    const handleOptionSelect = (item: OptionsCardOption) => {
      setOption(item);
      setProgramOptions(item.programs);
      setProgram({ program_name: 'Выберите программу' });
    };

    const handleProgramSelect = (item: OptionsCardProgram) => {
      setProgram(item);
    };

    const handleChangeOptionAlertCancel = () => {
      closeAlert(false);
    };

    const handleChangeOptionAlertConfirm = async () => {
      try {
        if (mnemocode && program?.product_option_id) {
          await clientApi.setCardOption(mnemocode, program.product_option_id);
          AppToaster.show({ message: `Опция успешно привязана к карте`, intent: 'success' });
        }
      } catch (e) {
        AppToaster.show({ message: `Опция НЕ привязана к карте`, intent: 'danger' });
      } finally {
        closeAlert(true);
      }
    };

    return (
      <Alert isOpen={isOpen} className={'change-card-option-alert'}>
        <h3>Смена опции</h3>
        <InputWrapper>
          <div style={{ paddingBottom: '5px' }}>Опция</div>
          <Select
            items={optionOptions}
            filterable={false}
            itemRenderer={optionItemRenderer}
            onItemSelect={handleOptionSelect}
            popoverProps={{ boundary: 'viewport', position: 'right', popoverClassName: 'scrollable' }}
          >
            <Button
              style={{ width: '250px', justifyContent: 'space-between' }}
              intent={program?.product_option_id ? 'success' : 'none'}
              text={option.option_name}
              rightIcon="caret-down"
            />
          </Select>
        </InputWrapper>
        <InputWrapper>
          <div style={{ paddingBottom: '5px' }}>Программа</div>
          <Select
            items={programOptions}
            filterable={false}
            itemRenderer={programItemRenderer}
            onItemSelect={handleProgramSelect}
            disabled={!option?.option_id}
            popoverProps={{ boundary: 'viewport', position: 'right', popoverClassName: 'scrollable' }}
          >
            <Button
              style={{ width: '250px', justifyContent: 'space-between' }}
              intent={program?.loyalty_code ? 'success' : 'none'}
              text={program?.program_name || program?.loyalty_code}
              rightIcon="caret-down"
              disabled={!option?.option_id}
            />
          </Select>
        </InputWrapper>
        <InputWrapper className={'bp3-alert-buttons'}>
          <Button text={'Отмена'} onClick={handleChangeOptionAlertCancel}></Button>
          <Button
            intent={'success'}
            text={'Сменить'}
            disabled={!program?.product_option_id || !program?.is_active}
            onClick={handleChangeOptionAlertConfirm}
          ></Button>
        </InputWrapper>
      </Alert>
    );
  }
);
