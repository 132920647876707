import React from 'react';
import { FormikFieldProps } from './utils';
import { Field, FieldProps, useFormikContext } from 'formik';
import { MultiSelect, ItemRenderer } from '@blueprintjs/select';
import { FormGroup, IPopoverProps, MenuItem } from '@blueprintjs/core';
import './multi-select-field.scss';

export interface MultiSelectFieldItem<T> {
  value: T;
  caption: string;
  key?: unknown;
}

export interface MultiSelectFieldProps<T> extends FormikFieldProps {
  items: MultiSelectFieldItem<T>[];
  filterable?: boolean;
  placeholder?: string;
  popoverProps?: Partial<IPopoverProps> & object;
}

export function MultiSelectField<T>(props: MultiSelectFieldProps<T>) {
  const { name, inline, label, items, placeholder, filterable, disabled, popoverProps } = props;
  const context = useFormikContext();

  const tagRender = (item: MultiSelectFieldItem<T>) => {
    return item.caption;
  };

  const itemRenderer: ItemRenderer<MultiSelectFieldItem<T>> = (item, { handleClick, modifiers, query }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    if (!!query && !item?.caption?.toLowerCase()?.includes(query?.toLowerCase())) {
      return null;
    }
    return <MenuItem onClick={handleClick} key={String(item.key || item.value)} text={item.caption} />;
  };

  const onItemSelect = (item: MultiSelectFieldItem<T>, event?: React.SyntheticEvent<HTMLElement>) => {
    context.setFieldValue(name, [...(context.getFieldMeta<T[]>(name)?.value || []), item.value]);
  };

  const onRemove = (item: MultiSelectFieldItem<T>, index: number) => {
    const value = [...context.getFieldMeta<T[]>(name)?.value];
    value.splice(index, 1);
    context.setFieldValue(name, value);
  };

  return (
    <Field name={name}>
      {({ field, meta, form }: FieldProps<T[]>) => {
        /* eslint-disable */
        const [selectedItems, availableItems] = React.useMemo(() => {
          const selected = field?.value
            ?.map((value) => items.find((item) => item.value === value))
            .filter((item) => !!item) as MultiSelectFieldItem<T>[];
          const available = items?.filter((item) => !field?.value?.includes(item?.value));
          return [selected, available];
        }, [field.value, items]);

        return (
          <FormGroup
            inline={inline}
            label={label}
            labelFor={name}
            labelInfo={meta.touched && meta.error}
            disabled={disabled}
          >
            <MultiSelect
              fill
              placeholder={placeholder}
              popoverProps={{
                fill: true,
                disabled: !availableItems?.length,
                ...popoverProps,
              }}
              resetOnSelect={true}
              items={availableItems}
              selectedItems={selectedItems}
              tagRenderer={tagRender}
              onItemSelect={onItemSelect}
              itemRenderer={itemRenderer}
              onRemove={onRemove}
            />
          </FormGroup>
        );
      }}
    </Field>
  );
}
