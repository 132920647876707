import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { IconNames } from '@blueprintjs/icons';
import { URL } from '../../../utils/url-builder';
import { CashbackActionPage } from './cashback-action-page';
import { CashbackActionAddPage } from './cashback-action-add-page';
import * as S from './index.styles';
import { CashbackActionEditPage } from './cashback-action-edit-page';
import { BreadcrumbRouteProps, BreadcrumbsRoute } from '../../ui/breadcrumbs-route';
import { CashbackActionMerchantsPage } from './cashback-action-merchant/cashback-action-merchants-page';
import { CashbackActionMerchantPage } from './cashback-action-merchant/cashback-action-merchant-page';
import { CategoryAddPage } from './category-add-page';
import { CategoryEditPage } from './category-edit-page';

const routes: BreadcrumbRouteProps[] = [
  { path: `${URL.cashbackActionSection()}`, icon: IconNames.LIST_DETAIL_VIEW, text: 'Кэшбэк-акции' },
  { path: `${URL.cashbackActionSection()}/categories`, text: 'Категории' },
  { path: `${URL.cashbackActionSection()}/categories/add`, text: 'Новая категория' },
  { path: `${URL.cashbackActionSection()}/categories/:id`, text: '${id}' },
  { path: `${URL.cashbackActionSection()}/add`, text: 'Загрузка' },
  { path: `${URL.cashbackActionSection()}/:id`, text: '${id}' },
  { path: `${URL.cashbackActionSection()}/:id/merchant`, text: 'Мерчанты' },
  { path: `${URL.cashbackActionSection()}/:id/merchant/add`, text: 'Создание' },
  { path: `${URL.cashbackActionSection()}/:id/merchant/:merchantId`, text: '${merchantId}' },
];

export const CashbackActionRouter = () => {
  return (
    <S.Section>
      <S.Header>
        <BreadcrumbsRoute routes={routes} />
      </S.Header>
      <S.Nav />
      <Switch>
        <Route path={`${URL.cashbackActionSection()}/:categories(categories)?`} component={CashbackActionPage} exact />
        <Route path={`${URL.cashbackActionSection()}/categories/add`} component={CategoryAddPage} exact />
        <Route path={`${URL.cashbackActionSection()}/categories/:id`} component={CategoryEditPage} exact />
        <Route path={`${URL.cashbackActionSection()}/add`} component={CashbackActionAddPage} exact />
        <Route path={`${URL.cashbackActionSection()}/:id`} component={CashbackActionEditPage} exact />
        <Route path={`${URL.cashbackActionSection()}/:id/merchant`} component={CashbackActionMerchantsPage} exact />
        <Route path={`${URL.cashbackActionSection()}/:id/merchant/add`} component={CashbackActionMerchantPage} exact />
        <Route
          path={`${URL.cashbackActionSection()}/:id/merchant/:merchantId`}
          component={CashbackActionMerchantPage}
          exact
        />
      </Switch>
    </S.Section>
  );
};
