import React, { TextareaHTMLAttributes } from 'react';
import { FormGroup, TextArea, TextAreaProps } from '@blueprintjs/core';
import { Field, FieldProps } from 'formik';
import { FormikFieldProps } from './utils';

type Resize = 'block' | 'both' | 'horizontal' | 'inline' | 'none' | 'vertical';
type TextareaHTMLAttributesProps = TextareaHTMLAttributes<TextArea> & {
  resize?: Resize;
};

export const TextAreaField = (props: FormikFieldProps & TextAreaProps & TextareaHTMLAttributesProps) => {
  const { name, label, inline, resize } = props;
  const style = { resize };
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => (
        <FormGroup inline={inline} label={label} labelFor={name} labelInfo={meta.touched && meta.error}>
          <TextArea
            id={name}
            {...props}
            {...field}
            fill
            intent={meta.touched && meta.error ? 'warning' : 'none'}
            style={style}
          />
        </FormGroup>
      )}
    </Field>
  );
};
