import React, { useState } from 'react';
import _ from 'lodash';
import { baseApi } from 'api/base';
import moment from 'moment';
import { Button, Classes, Popover } from '@blueprintjs/core';
import { useModal } from 'components/ui/modal';
import { AppToaster } from 'components/ui/toaster';
import { User } from 'api/user-types';
import { Table, Td, TdCenter } from '../client-page.styles';
import { Client } from '../api/client-types';
import { ClientPopover } from './ClientPopover';
import { SendNotificationModal } from './SendNotificationModal';

export const ClientSection = ({ user, data }: { user: User; data: Client }) => {
  const [client, setClient] = useState(data);

  const { showModal, ModalRender, hideModal } = useModal({});
  const handleLockedChange = async (profileMnemocode: string | undefined, isLocked: boolean) => {
    const response = await baseApi.fetch<{ data: Client }>(`/client/locked`, {
      method: 'POST',
      data: {
        profileMnemocodes: [profileMnemocode],
        isLocked,
      },
    });
    setClient({ ...client, isLocked: _.get(response.data, 'is_locked') ? 'Y' : 'N' });
  };
  const handleSendMessage = async () => {
    /*
    showModal(
      <SendNotificationModal
        hideModal={() => {
          hideModal();
          AppToaster.show({ message: `Сообщение успешно отправлено`, intent: 'success' });
        }}
        profileId={client.profileId}
      />
    );
    */
  };
  return (
    <div style={{ margin: '7px' }}>
      <ModalRender />
      <Table>
        <tbody>
          <tr>
            <Td>Имя клиента</Td>
            <Td>{client?.name}</Td>
          </tr>
          <tr>
            <Td>Телефон</Td>
            <Td>{client?.phone}</Td>
          </tr>
          <tr>
            <Td>E-mail</Td>
            <Td>{client?.email}</Td>
          </tr>
          <tr>
            <Td>Дата рождения</Td>
            <Td>{client?.dateOfBirth ? moment(client?.dateOfBirth).format('DD.MM.YYYY') : ''}</Td>
          </tr>
          <tr>
            <Td>Пол</Td>
            <Td>{({ M: 'Мужчина', F: 'Женщина' } as any)[client?.sex] || ''}</Td>
          </tr>
          <tr>
            <Td>Customer ID</Td>
            <Td>{client?.externalId}</Td>
          </tr>
          <tr>
            <Td>Статус</Td>
            <Td>
              {[client?.isLocked === 'Y', client?.isStopped === 'Y', client?.profileClosed === 'Y'].includes(true)
                ? 'Заблокирован'
                : 'Действует'}
            </Td>
          </tr>
          <tr>
            <TdCenter colSpan={2}>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                {user.role !== 'USER' && (
                  <Button
                    outlined
                    onClick={() => handleLockedChange(client?.mnemocode, !(client?.isLocked === 'Y'))}
                    text={
                      [client?.isLocked === 'Y', client?.isStopped === 'Y', client?.profileClosed === 'Y'].includes(
                        true
                      )
                        ? 'Разблокировать'
                        : 'Заблокировать'
                    }
                    intent={
                      [client?.isLocked === 'Y', client?.isStopped === 'Y', client?.profileClosed === 'Y'].includes(
                        true
                      )
                        ? 'success'
                        : 'danger'
                    }
                  />
                )}
                <Popover content={<ClientPopover client={client} />} popoverClassName={Classes.POPOVER_CONTENT_SIZING}>
                  <Button icon="cog" />
                </Popover>
              </div>
            </TdCenter>
          </tr>
          {false && user.role !== 'USER' && (
            <tr>
              <TdCenter colSpan={2}>
                <Button outlined onClick={() => handleSendMessage()} text="Отправить уведомление" icon="send-message" />
              </TdCenter>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};
