import { all } from 'redux-saga/effects';

import auth from 'sagas/auth';

export const rootSaga = function* root() {
  yield all([
    auth(),
    // other sagas
  ]);
};
