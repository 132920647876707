import React, { FormEvent, useState, useCallback, useEffect, SyntheticEvent, useReducer, useRef } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';
import { Field, FieldProps, FormikProps } from 'formik';
import { Button, FileInput, FormGroup, Dialog, Classes, Spinner, Icon } from '@blueprintjs/core';
import { FormikFieldProps } from './utils';
import { AppToaster } from '../toaster';

const debounce = (func: Function, args: any[], delay: number) => {
  const fn: Function & { $id: number } = func as any;
  clearTimeout(fn?.$id);
  fn.$id = setTimeout(() => {
    fn(...args);
  }, delay) as any;
};

interface DialogBodyProps {
  height?: string;
  width?: string;
}

const DialogBody = styled.div`
  margin: 0 0 0 20px;
  position: relative;
  height: ${(props: DialogBodyProps) => props.height};
  width: ${(props: DialogBodyProps) => props.width};
`;

DialogBody.defaultProps = {
  height: 'auto',
  width: 'auto',
};

const DialogBodyContent = styled.div`
  display: flex;
  overflow-y: auto;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  &.content-center {
    align-items: center;
    justify-content: center;
  }
`;

const DialogSpinner = styled.div`
  left: -20px;
  width: calc(100% + 20px);
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ebf1f5;
  opacity: 0.5;
`;

interface StaticProps {
  width?: string;
  height?: string;
}

const Static = styled.div`
  padding: 0 10px 10px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props: StaticProps) => props.height};
  width: ${(props: StaticProps) => props.width};
`;

Static.defaultProps = {
  height: '200px',
  width: '200px',
};

const Btn = styled(Button).attrs({ minimal: true })`
  height: 100%;
  width: 100%;
  border: 1px dashed lightgray;
`;

const Img = styled.img.attrs((props) => ({ className: classNames('bp3-button bp3-minimal', props.className) }))`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border: 1px dashed lightgray;

  &.select {
    transition: all 0.2s ease-in-out;
    &:hover {
      opacity: 0.8;
      transform: scale(1.02);
    }
  }
`;

export interface StaticSelectFieldProps extends FormikFieldProps {
  url: string;
  step?: number;
  static?: StaticProps;
}

type State = string[];

type Action = {
  type: 'LOAD' | 'UPLOAD';
  payload: string[];
};

const linksReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'LOAD': {
      return [...state, ...action.payload];
    }
    case 'UPLOAD': {
      return [...(new Set([...action.payload, ...state]) as any)];
    }
    default:
      return state;
  }
};

async function getLinks(url: string, offset: number, limit: number): Promise<string[]> {
  return fetch(`${url}?offset=${offset}&limit=${limit}`)
    .then((response) => response.json())
    .then((body) => body.data)
    .catch(() => []);
}

async function setFiles(url: string, files: File[]): Promise<string[]> {
  const formData = new FormData();
  files.forEach((file) => formData.append(file.name, file));
  return fetch(`${url}`, { method: 'POST', body: formData })
    .then((response) => Promise.all([response, response.json()]))
    .then(([response, body]) => {
      if (!response.ok) {
        return Promise.reject(body);
      }

      return body.data;
    })
    .catch((error) => {
      AppToaster.show({ message: error.message, intent: 'danger' });

      return [];
    });
}

export const StaticSelectField = (props: StaticSelectFieldProps) => {
  const { name, label, inline, disabled, url, step, static: staticProps } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [linksState, linksDispatch] = useReducer(linksReducer, []);
  const dialogBodyContentRef = useRef<HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const handleOpen = useCallback(() => setIsOpen(!isOpen), []);
  const handleClose = useCallback(() => setIsOpen(false), []);
  const handleSelected = useCallback((event: SyntheticEvent, form: FormikProps<any>) => {
    const value = (event.target as HTMLImageElement).src;
    form.setFieldValue(name, value);
    setIsOpen(false);
  }, []);
  const handleUpload = useCallback((event: SyntheticEvent) => {
    fileInputRef.current?.click();
  }, []);
  const handleInputChange = useCallback((event: FormEvent<HTMLInputElement>, form: FormikProps<any>) => {
    const files = Object.values(event.currentTarget?.files || {});
    upload(files);
  }, []);
  const handleScroll = useCallback((event: SyntheticEvent) => {
    event.persist();
    debounce(scroll, [event], 500);
  }, []);
  const scroll = useCallback((event: SyntheticEvent) => {
    const target = event.target as any;
    if (target.scrollTop + target.clientHeight >= target.scrollHeight - 200) {
      setIsLoad(true);
    }
  }, []);

  const load = (offset: number) => {
    const limit = step || 10;
    if (!isLoading) {
      setIsLoading(true);
      setTimeout(() => {
        getLinks(url, offset, limit)
          .then((data) => {
            linksDispatch({ type: 'LOAD', payload: data });
          })
          .finally(() => {
            setIsLoading(false);
          });
      }, 1000);
    }
  };

  const upload = (files: File[]) => {
    if (!isLoading) {
      setIsLoading(true);
      setTimeout(() => {
        setFiles(url, files)
          .then((data) => {
            linksDispatch({ type: 'UPLOAD', payload: data });
          })
          .finally(() => {
            setIsLoading(false);
          });
      }, 1000);
    }
  };

  useEffect(() => {
    if (isOpen && (!linksState.length || isLoad)) {
      setIsLoad(false);
      load(linksState.length);
    }
  }, [isOpen, isLoad]);

  return (
    <Field name={name}>
      {({ field, meta, form }: FieldProps) => (
        <FormGroup disabled={disabled} inline={inline} label={label} labelFor={name} labelInfo={meta.error}>
          <Static {...staticProps}>
            <Btn onClick={!disabled ? handleOpen : undefined}>
              {field.value ? <Img src={field.value}></Img> : <Icon icon="folder-open" size={64}></Icon>}
            </Btn>
          </Static>
          <div hidden>
            <FileInput
              inputProps={{ ref: fileInputRef, multiple: true }}
              onInputChange={(event) => handleInputChange(event, form)}
            />
          </div>
          <Dialog
            isOpen={isOpen}
            title={`${label}: ${linksState.length}`}
            style={{ width: 'auto' }}
            onClose={handleClose}
          >
            <DialogBody className={Classes.DIALOG_BODY} width="620px" height="400px">
              {isLoading && (
                <DialogSpinner>
                  <Spinner></Spinner>
                </DialogSpinner>
              )}
              <DialogBodyContent
                ref={dialogBodyContentRef}
                onScroll={handleScroll}
                className={classNames({ ['content-center']: !linksState.length })}
              >
                <Static {...staticProps}>
                  <Btn
                    onClick={handleUpload}
                    style={{ visibility: !linksState.length && isLoading ? 'hidden' : 'unset' }}
                  >
                    <Icon icon="folder-open" size={64}></Icon>
                  </Btn>
                </Static>
                {linksState.map((link, i) => (
                  <Static {...staticProps} key={i}>
                    <Img src={link} onClick={(event) => handleSelected(event, form)} className={classNames('select')} />
                  </Static>
                ))}
              </DialogBodyContent>
            </DialogBody>
          </Dialog>
        </FormGroup>
      )}
    </Field>
  );
};
