import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button, InputGroup } from '@blueprintjs/core';
import { Select, SelectOption } from 'components/ui/form-controls/Select';
import { usePartnerList } from 'api/partner-hooks';
import { authApi } from '../../../api/auth';

const domainPattern = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9-_]+\.[a-zA-Z]{2,}$/;

export const SupervisorPage = () => {
  const [externalId, setExternalId] = useState('');
  const [domain, setDomain] = useState<string>('');
  const [authorizationLink, setAuthorizationLink] = useState('');

  useEffect(() => {
    const lastUsedDomain = localStorage.getItem('lastUsedDomain');
    if (lastUsedDomain) setDomain(lastUsedDomain);
  }, []);

  const handleChangeExternalId = (value: string) => {
    setExternalId(value);
  };

  const handleSubmit = async () => {
    const url = await authApi.getLoginURL({ externalId, domain });
    setAuthorizationLink(url.authorizationLink);
    localStorage.setItem('lastUsedDomain', domain);
    window.open(url.authorizationLink);
  };

  const { data } = usePartnerList();
  const dataSafe = data ?? [];

  const selectDomainOptions = useMemo(() => {
    const dataToOptions: SelectOption[] = dataSafe
      .filter((item) => domainPattern.test(item.domainName))
      .map((item) => ({
        value: item.domainName,
        caption: item.domainName,
      }));

    const dataDomains = dataSafe.map((item) => item.domainName);

    return dataDomains.includes(domain) ? dataToOptions : [{ value: domain, caption: domain }, ...dataToOptions];
  }, [dataSafe, domain]);

  return (
    <Root>
      <h2>Войти под пользователем:</h2>
      <DomainGroup>
        <InputGroup
          style={{ width: '250px', margin: '0 0 7px 0' }}
          placeholder="domain"
          value={domain}
          onChange={(event) => setDomain(event.target.value)}
        />
        {dataSafe.length > 0 && (
          <>
            <Gutter />
            <Select
              options={selectDomainOptions}
              onItemSelect={(item) => {
                setDomain(String(item.value));
              }}
              selectedValue={domain}
            />
          </>
        )}
      </DomainGroup>
      <InputGroup
        style={{ width: '250px', margin: '0 0 7px 0' }}
        placeholder="external ID"
        value={externalId}
        onChange={(event) => handleChangeExternalId(event.target.value)}
      />
      <Button intent="primary" onClick={handleSubmit} text="Войти" icon="circle-arrow-right" />
      <div style={{ margin: '10px 0 0 0' }}>
        <b>{authorizationLink}</b>
      </div>
    </Root>
  );
};

const Root = styled.div`
  padding: 0 1em 1em 1em;
`;

const DomainGroup = styled.div`
  display: flex;
`;

const Gutter = styled.div`
  width: 16px;
`;
