import React from 'react';
import { URL } from 'utils/url-builder';
import { SectionPage } from 'components/ui/skeletons/section-page';
import { UserList } from './user-list';
import { UserPage } from './user-page';

// TODO: replace sectionListUrl with URL.listUrl
export const UsersPage = () => {
  return <SectionPage sectionListUrl={URL.userList()} listComponent={UserList} pageComponent={UserPage} />;
};
