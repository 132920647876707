import React from 'react';
import { useHistory } from 'react-router-dom';
import { QueryResult } from 'react-query';
import { Column } from 'react-table';
import { ApiError } from 'api/base';
import { Loader } from 'components/ui/shared/loader';
import { Table } from 'components/ui/table';
import { ApiListArgs } from '../../../../api/base-hooks';

export type EntityTableColumn<T extends {}> = Column<T>;

export type EntityTableProps<T extends { id?: number }> = {
  useEntityList: (params?: ApiListArgs) => QueryResult<T[], ApiError>;
  entityEditUrl?: (id: number) => string;
  columns: Column<T>[];
  initialSortBy: string;
  desc?: boolean;
  params?: ApiListArgs;
  onRowClick?: (item: T) => void;
  rowLinkPrefix?: string;
  rowLinkIdProp?: string;
};
export const EntityTable = <T extends { id?: number }>(props: EntityTableProps<T>) => {
  const {
    rowLinkPrefix,
    rowLinkIdProp,
    useEntityList,
    entityEditUrl,
    columns,
    initialSortBy,
    params,
    desc,
    onRowClick,
  } = props;
  const history = useHistory();
  const { data, isLoading } = useEntityList(params);
  const initialState = React.useMemo(
    () => ({
      sortBy: [
        {
          id: initialSortBy,
          desc,
        },
      ],
      pageIndex: 0,
      pageSize: 30,
    }),
    [initialSortBy]
  );
  const handleRowClick = (item: T) => {
    if (onRowClick) {
      onRowClick(item);
      return;
    }
    if (!item?.id || !entityEditUrl) {
      return;
    }
    history.push(entityEditUrl(item.id));
    // openNewTab(entityEditUrl(item.id));
  };
  if (!data || isLoading) {
    return <Loader size={111} />;
  }

  return (
    <Table<T>
      columns={columns}
      data={data}
      initialState={initialState}
      onRowClick={handleRowClick}
      rowLinkPrefix={rowLinkPrefix}
      rowLinkIdProp={rowLinkIdProp}
    />
  );
};
