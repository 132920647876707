import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Collapse, Icon } from '@blueprintjs/core';
import { Loader } from 'components/ui/shared/loader';
import { userSelector } from 'selectors/auth';
import { baseApi } from '../../../api/base';
import { ClientCardData } from './api/client-types';
import { Subheader, SubheaderWrap } from './client-page.styles';
import { ClientSection } from './components/ClientSection';
import { PcSection } from './components/PcSection';
import { LpSection } from './components/LpSection';

export const ClientCard = () => {
  const [isClientSectionOpen, setIsClientSectionOpen] = useState(true);
  const [isPcSectionOpen, setIsPcSectionOpen] = useState(true);
  const [isLpSectionOpen, setIsLpSectionOpen] = useState(false);
  const [data, setData] = useState<ClientCardData>({ client: null, cards: { pc: [], lp: [] } });
  const user = useSelector(userSelector);
  const { mnemocode } = useParams();
  useEffect(() => {
    baseApi.fetch<ClientCardData>(`/client/cards/${mnemocode}`).then((response) => {
      setData(response);
    });
  }, [mnemocode, setData]);
  if (!data?.client) {
    return <Loader size={111} />;
  }
  return (
    <>
      <SubheaderWrap onClick={() => setIsClientSectionOpen(!isClientSectionOpen)}>
        <Subheader>Клиент</Subheader>
        <Icon icon={isClientSectionOpen ? 'caret-down' : 'caret-up'} />
      </SubheaderWrap>
      <Collapse isOpen={isClientSectionOpen}>
        <ClientSection user={user} data={data.client} />
      </Collapse>
      <SubheaderWrap onClick={() => setIsPcSectionOpen(!isPcSectionOpen)}>
        <Subheader>Банковские карты</Subheader>
        <Icon icon={isPcSectionOpen ? 'caret-down' : 'caret-up'} />
      </SubheaderWrap>
      <Collapse isOpen={isPcSectionOpen}>
        <PcSection user={user} data={data.cards.pc} lpCards={data.cards.lp} />
      </Collapse>
      <SubheaderWrap onClick={() => setIsLpSectionOpen(!isLpSectionOpen)}>
        <Subheader>Карты лояльности</Subheader>
        <Icon icon={isLpSectionOpen ? 'caret-down' : 'caret-up'} />
      </SubheaderWrap>
      <Collapse isOpen={isLpSectionOpen}>
        <LpSection user={user} data={data.cards} />
      </Collapse>
    </>
  );
};
