import React, { ChangeEvent, useState, useMemo } from 'react';
import { Button, Classes, Popover, ButtonGroup, Dialog, Label, InputGroup } from '@blueprintjs/core';
import { AppToaster } from 'components/ui/toaster';
import { User } from 'api/user-types';
import { Table, Td, TdTop, Th } from '../client-page.styles';
import { Cards, ClientCardData, Lp } from '../api/client-types';
import { LpPopover } from './LpPopover';
import { useParams } from 'react-router-dom';
import { useOpenInNewTab } from '../../../../api/history-hooks';
import styled from 'styled-components';
import * as _ from 'lodash';
import { baseApi } from '../../../../api/base';

const DialogBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 20px 0 20px;
`;

const dialogButtonText: { [s: string]: string } = {
  M: 'Авторизовать',
  I: 'Сменить номер',
  A: 'Сменить номер',
  E: 'Сменить номер',
};

export const LpSection = ({ user, data }: { user: User; data: Cards }) => {
  const { mnemocode } = useParams<any>();
  const [cards, setCards] = useState<Lp[]>(data.lp);

  const pcProductProfileMnemocodes = useMemo(() => {
    return _.chain(data)
      .get('pc')
      .map('product.profile.mnemocode')
      .uniq()
      .value();
  }, []);

  const isShowBonus = (card: any) => {
    return (
      ['I', 'A'].includes(card.status.status) || pcProductProfileMnemocodes?.includes(card?.product?.profile?.mnemocode)
    );
  };

  const isOpenChangeNumber = (card: any) => {
    return ['I', 'A', 'M', 'E'].includes(card.status.status);
  };

  const openNewTab = useOpenInNewTab();

  const [changeCard, setChangeCard] = useState<Lp | null>(null);
  const [changeNumber, setChangeNumber] = useState<string | undefined>();

  const checkNumberValidity = (number: any, numberMask: any) => {
    return !!number && !!numberMask && new RegExp(numberMask).test(number);
  };

  const handleOpenChangeNumber = (card: Lp) => {
    setChangeCard(card);
    setChangeNumber(card.number);
  };

  const handleSaveChangeNumber = async () => {
    await (changeCard?.status?.status === 'M'
      ? baseApi.fetch<any>(`/client/card/${mnemocode}`, {
          method: 'POST',
          data: {
            number: changeNumber,
            class: changeCard.programProductClass,
          },
        })
      : baseApi.fetch<any>(`/client/card/${changeCard?.mnemocode}`, {
          method: 'PUT',
          data: {
            number: changeNumber,
          },
        })
    )
      .then(() => {
        AppToaster.show({ message: `Номер лояльности изменен`, intent: 'success' });
        baseApi.fetch<ClientCardData>(`/client/cards/${mnemocode}`).then((clientCardData) => {
          setCards(clientCardData.cards.lp);
        });
      })
      .catch(() => {
        AppToaster.show({ message: `Номер лояльности НЕ изменен`, intent: 'danger' });
      });

    setChangeCard(null);
  };

  const handleShowBonus = (currency: string) => {
    openNewTab(`/client/bonus/${mnemocode}/${currency}`);
  };
  // @ts-ignore
  return (
    <div style={{ margin: '10px 10px 30px 10px' }}>
      <Dialog isOpen={!!changeCard} title="" onClose={() => setChangeCard(null)} style={{ width: '300px' }}>
        <DialogBody>
          <Label style={{ textAlign: 'center', width: '100%', margin: '0 0 20px 0' }}>
            {changeCard?.caption}
            <InputGroup
              fill
              value={changeNumber}
              onChange={(e: ChangeEvent<HTMLInputElement>) => setChangeNumber(e.target.value)}
            ></InputGroup>
          </Label>
          <Button
            disabled={changeCard?.number === changeNumber || !checkNumberValidity(changeNumber, changeCard?.numberMask)}
            text={dialogButtonText[changeCard?.status?.status as string]}
            outlined
            fill
            intent="success"
            onClick={handleSaveChangeNumber}
          />
        </DialogBody>
      </Dialog>
      <Table>
        <thead>
          <tr>
            <Th>Статус</Th>
            <Th>Изображение</Th>
            <Th>Номер карты</Th>
            <Th>Валюта карты</Th>
            <Th> </Th>
            <Th>*</Th>
          </tr>
        </thead>
        <tbody>
          {cards.map((card) => (
            <tr key={card?.mnemocode}>
              <Td>
                <div>
                  <b>
                    <i>{card?.status?.name}</i>
                  </b>
                </div>
              </Td>
              <Td>
                <img style={{ maxWidth: '150px' }} alt="img" src={card.image} />
              </Td>
              <Td>
                <p>{card.number ? card.number : 'отсутствует'}</p>
              </Td>
              <Td>
                <p>{card?.currency?.name}</p>
              </Td>
              <Td>
                <ButtonGroup vertical>
                  {isShowBonus(card) && (
                    <Button small outlined intent="success" onClick={() => handleShowBonus(card.currency.currency)}>
                      Посмотреть бонусы
                    </Button>
                  )}
                  {isOpenChangeNumber(card) && (
                    <Button small outlined intent="success" onClick={() => handleOpenChangeNumber(card)}>
                      Ввести номер лояльности
                    </Button>
                  )}
                </ButtonGroup>
              </Td>
              <Td>
                <Popover content={<LpPopover card={card} />} popoverClassName={Classes.POPOVER_CONTENT_SIZING}>
                  <Button icon="cog" />
                </Popover>
              </Td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
