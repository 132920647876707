import React from 'react';
import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { FocusStyleManager } from '@blueprintjs/core';
import { Header } from 'components/header';
import { Sidebar } from 'components/sidebar';
import { ContentWrapper } from 'components/content-wrapper';
import { SidebarMenu, SidebarMenuItemProp } from 'components/sidebar-menu';
import { SectionName, URL } from 'utils/url-builder';
import { PartnersPage } from 'components/pages/partner';
import { UsersPage } from 'components/pages/user';
import { OffersPage } from 'components/pages/offer';
import { SupervisorPage } from 'components/pages/supervisor';
import { ClientRouter } from 'components/pages/client';
import { ActionsPage } from 'components/pages/action';
import { SectionsPage } from 'components/pages/section';
import { RolePage } from 'components/pages/role';
import { TagsPage } from 'components/pages/tag';
import { ChangePwdPage } from 'components/pages/change-pwd';
import { getAvailableSections, getUserProfile } from 'selectors/auth';
import { BonusRegistryRouter } from 'components/pages/bonus-registry';
import { CashbackActionRouter } from 'components/pages/cashback-action';
import { ReportRouter } from 'components/pages/report';

FocusStyleManager.onlyShowFocusOnTabs();

const sectionComponents: Record<SectionName, React.ComponentType> = {
  partner: PartnersPage,
  user: UsersPage,
  supervisor: SupervisorPage,
  offer: OffersPage,
  client: ClientRouter,
  action: ActionsPage,
  section: SectionsPage,
  role: RolePage,
  tag: TagsPage,
  'bonus-registry': BonusRegistryRouter,
  'cashback-action': CashbackActionRouter,
  report: ReportRouter,
};

export const MainPage = () => {
  const user = useSelector(getUserProfile);
  const availableSections = useSelector(getAvailableSections) || [];
  const menuItems: SidebarMenuItemProp[] = availableSections.map((section) => ({
    caption: section.title,
    url: URL.sectionUrl(section.path),
  }));
  return (
    <>
      <Header />
      <Sidebar>
        <SidebarMenu items={menuItems} />
      </Sidebar>
      <ContentWrapper>
        {user?.changePwd ? (
          <Switch>
            <Route
              key="change-pwd"
              path={URL.sectionUrl('change-pwd')}
              component={() => <ChangePwdPage userId={user.userId} />}
            />
            <Redirect to={URL.sectionUrl('change-pwd')} />
          </Switch>
        ) : (
          <Switch>
            {availableSections.map((section) => (
              <Route
                key={section.path}
                path={URL.sectionUrl(section.path)}
                component={sectionComponents[section.path]}
              />
            ))}
            <Redirect to={URL.sectionUrl((availableSections[0] || {}).path || 'client')} />
          </Switch>
        )}
      </ContentWrapper>
    </>
  );
};
