import React from 'react';
import { useHistory } from 'react-router-dom';
import { Breadcrumbs as BreadcrumbsBase, Breadcrumb as BreadcrumbBase, IBreadcrumbProps } from '@blueprintjs/core';
import * as S from './breadcrumbs.styles';

export type BreadcrumbItem = IBreadcrumbProps;
export type BreadcrumbItems = BreadcrumbItem[];
export type BreadcrumbsProps = {
  items: IBreadcrumbProps[];
};
export const Breadcrumbs = (props: BreadcrumbsProps) => {
  const { items } = props;

  const itemsLastCurrent = items.map(({ current, ...rest }, index, array) => ({
    current: index === array.length - 1,
    ...rest,
  }));

  return (
    <S.Container>
      <BreadcrumbsBase
        items={itemsLastCurrent}
        breadcrumbRenderer={(breadcrumbProps: IBreadcrumbProps) => {
          return <Breadcrumb {...breadcrumbProps} />;
        }}
      />
    </S.Container>
  );
};

const Breadcrumb = (props: IBreadcrumbProps) => {
  const history = useHistory();
  const { href } = props;
  const handleClick = (e: React.MouseEvent) => {
    if (!href) {
      return;
    }
    history.push(href);
    e.preventDefault();
  };

  return <BreadcrumbBase {...props} onClick={handleClick} />;
};
