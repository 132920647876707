import moment from 'moment/moment';
import { IconTypeWithFile, PartnerRestrictions, restrictionsSections } from '@whitelabel/common/dist/api';
import { FullFormValuesType } from '../types';
import { getRestrictionSectionsFullNames } from './get-full-restriction-name';

export function addRestrictionsValuesForSubmit(values: FullFormValuesType) {
  const valuesLocal = { ...values };
  valuesLocal.restrictions = valuesLocal.restrictions ?? ({} as PartnerRestrictions);

  restrictionsSections.forEach((section) => {
    const { fieldSectionName, fieldNameFrom, fieldNameTo, fieldMessage, fieldIcon } =
      getRestrictionSectionsFullNames(section);

    const sectionRestriction = valuesLocal[fieldSectionName] as boolean;
    const sectionRestrictionFrom = valuesLocal[fieldNameFrom] as string | null;
    const sectionRestrictionTo = valuesLocal[fieldNameTo] as string | null;
    const sectionMessage = valuesLocal[fieldMessage] as string | null;
    const sectionIcon = valuesLocal[fieldIcon] as IconTypeWithFile;

    const hasRestrictionSectionRules = sectionRestriction && sectionRestrictionFrom && sectionRestrictionTo;

    if (hasRestrictionSectionRules) {
      valuesLocal.restrictions![section] = {
        from: toISO(sectionRestrictionFrom as string),
        to: toISO(sectionRestrictionTo as string),
        message: sectionMessage || undefined,
        icon: sectionIcon || undefined,
      };
    } else {
      valuesLocal.restrictions![section] = undefined;
    }

    if (typeof valuesLocal[fieldSectionName] !== 'undefined') delete valuesLocal[fieldSectionName];
    if (typeof valuesLocal[fieldNameFrom] !== 'undefined') delete valuesLocal[fieldNameFrom];
    if (typeof valuesLocal[fieldNameTo] !== 'undefined') delete valuesLocal[fieldNameTo];
    if (typeof valuesLocal[fieldMessage] !== 'undefined') delete valuesLocal[fieldMessage];
    if (typeof valuesLocal[fieldIcon] !== 'undefined') delete valuesLocal[fieldIcon];
  });

  return valuesLocal;
}

function toISO(input: string) {
  return moment.utc(input).toISOString();
}
