import React, { useRef } from 'react';
import { FormikFieldProps } from './utils';
import { FormGroup, TagInput, TagInputProps } from '@blueprintjs/core';
import { Field, FieldProps, useFormikContext } from 'formik';

export interface TagInputFieldProps<T> extends FormikFieldProps, Omit<TagInputProps, 'values'> {}

export function TagInputField<T>(props: TagInputFieldProps<T>) {
  const { name, inline, label, disabled, ...tagInputProps } = props;
  const context = useFormikContext();
  const tagInputRef = useRef<TagInput>(null);
  return (
    <Field name={name}>
      {({ field, meta, form }: FieldProps<T[]>) => {
        /* eslint-disable */
        const values = React.useMemo<string[]>(() => {
          return field?.value?.map((value) => String(value)) || [];
        }, [field.value]);

        const patternTest = React.useMemo(() => {
          const patternRegExp = tagInputProps?.inputProps?.pattern
            ? new RegExp(tagInputProps?.inputProps?.pattern)
            : undefined;
          return (value: string | undefined) => (patternRegExp && value ? patternRegExp.test(value) : true);
        }, [tagInputProps]);
        return (
          <FormGroup
            inline={inline}
            label={label}
            labelFor={name}
            labelInfo={meta.touched && meta.error}
            disabled={disabled}
          >
            <TagInput
              ref={tagInputRef}
              {...tagInputProps}
              values={values}
              onAdd={(vals, method) => {
                const value = [...(new Set<T>([...(values as any), ...(vals as any)]) as any)].filter(
                  (val) => val && patternTest(val)
                );
                context.setFieldValue(name, value);
              }}
              onKeyUp={(event: React.KeyboardEvent<HTMLElement>) => {
                if (!patternTest(tagInputRef?.current?.state?.inputValue)) {
                  tagInputRef?.current?.setState({
                    ...tagInputRef?.current?.state,
                    ...{ inputValue: Reflect.get(event, 'value') || '' },
                  });
                  event.preventDefault();
                }
              }}
              onKeyDown={(event: React.KeyboardEvent<HTMLElement>) => {
                if (tagInputRef?.current?.state?.inputValue && patternTest(tagInputRef?.current?.state?.inputValue)) {
                  Reflect.set(event, 'value', tagInputRef?.current?.state?.inputValue);
                }
                if (event.key === 'Enter') {
                  context.setFieldValue(name, [...values, tagInputRef?.current?.state?.inputValue]);
                  event.preventDefault();
                }
              }}
              onRemove={(value: React.ReactNode, index: number) => {
                const vals = [...values];
                vals.splice(index, 1);
                context.setFieldValue(name, vals);
              }}
            />
          </FormGroup>
        );
      }}
    </Field>
  );
}
