import React from 'react';
import { BonusRegistryErrorTable } from './bonus-registry-error-table';
import { useParams } from 'react-router-dom';
import * as S from './index.styles';

export const BonusRegistryErrorPage = () => {
  const { id } = useParams<{ [key: string]: string }>();

  return (
    <S.Section>
      <S.Nav></S.Nav>
      <S.Section>
        <BonusRegistryErrorTable id={id} />
      </S.Section>
    </S.Section>
  );
};
