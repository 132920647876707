import React from 'react';
import { URL } from 'utils/url-builder';
import { BooleanColumnFilter, TextColumnFilter } from 'components/ui/table/filter-types';
import { EntityTable, EntityTableColumn } from 'components/ui/skeletons/entity-table';
import { useOfferList } from './api/offer-hooks';
import { OfferPartner } from './api/offer-types';

export const OffersTable = () => {
  const columns: EntityTableColumn<OfferPartner>[] = React.useMemo(
    () => [
      {
        Header: 'Название',
        accessor: 'offer',
        id: 'offer.name',
        Cell: ({ value }) => value?.name || null,
        filter: 'text',
        Filter: TextColumnFilter,
      },
      {
        Header: 'Принадлежность банку',
        accessor: 'partner',
        id: 'partner.components.name',
        Cell: ({ value }) => value?.components?.name || null,
      },
      {
        Header: 'Активность',
        accessor: 'offer',
        id: 'offer.isActive',
        Cell: ({ value }) => (value?.isActive ? 'активный' : 'не активный'),
        filter: 'boolean',
        Filter: BooleanColumnFilter,
      },
      {
        Header: 'онлайн/оффлайн',
        accessor: 'type',
        id: 'type',
        Cell: ({ value }) => (value === 'online' ? 'онлайн' : 'оффлайн'),
        filter: 'boolean',
        Filter: BooleanColumnFilter,
      },
    ],
    []
  );

  return (
    <EntityTable<OfferPartner>
      useEntityList={useOfferList}
      entityEditUrl={URL.offerView}
      columns={columns}
      initialSortBy="name"
    />
  );
};
