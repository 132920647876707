import {
  createUseEntityList,
  createUseEntity,
  createUseEntityAdd,
  createUseEntityUpdate,
  createUseEntityDelete,
} from 'api/base-hooks';
import { productProgramApi } from './product-program';
import { ProductProgram } from './product-program-types';

const SECTION = 'product-program';

export const createUseProductProgramList = (partnerId: number, productId: number) =>
  createUseEntityList<ProductProgram[]>(`${SECTION}:${partnerId}:${productId}:list`, () =>
    productProgramApi.getList(partnerId, productId)
  );

export const createUseProductProgram = (partnerId: number) =>
  createUseEntity<ProductProgram>(`${SECTION}:${partnerId}:item`, (productProgramId: number) => {
    return productProgramApi.getItem(partnerId, productProgramId);
  });

export const createUseProductProgramAdd = (partnerId: number, productId: number) =>
  createUseEntityAdd<ProductProgram>(`${SECTION}:${partnerId}:${productId}:add`, (data) =>
    productProgramApi.addItem(partnerId, productId, data.programId, data)
  );

export const createUseProductProgramUpdate = (partnerId: number) =>
  createUseEntityUpdate<ProductProgram>(`${SECTION}:${partnerId}:edit`, (productProgramId, data) =>
    productProgramApi.editItem(partnerId, productProgramId, data)
  );

export const createUseProductProgramDelete = (partnerId: number) =>
  createUseEntityDelete<number>(`${SECTION}:${partnerId}:delete`, (productProgramId) =>
    productProgramApi.deleteItem(partnerId, productProgramId)
  );
