import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactDOM from 'react-dom';

import { ContainerBase } from '../ContainerBase';
import { CloseBtn, FadeBackground } from './components';

type Event = React.MouseEvent | React.TouchEvent;

export interface ModalProps {
  closeModal: () => void;
  children?: React.ReactNode;
  showCloseBtnOnMobile?: boolean;
  windowedOnMobile?: boolean;
}

export function Modal({ children, closeModal, showCloseBtnOnMobile, windowedOnMobile }: ModalProps) {
  const [modalRoot, setModalRoot] = useState(null as any);

  const keyPress = useCallback(
    (e) => {
      if (e.key === 'Escape') closeModal();
    },
    [closeModal]
  );

  useEffect(() => {
    return () => {
      if (modalRoot) {
        document.body.removeChild(modalRoot);
      }
      document.removeEventListener('keydown', keyPress);
    };
  }, [keyPress, modalRoot]);

  if (!modalRoot) {
    const div = document.createElement('div');
    div.classList.add('modal-root');
    document.body.appendChild(div);
    setModalRoot(div);
    document.addEventListener('keydown', keyPress);
  }

  const disableReactionOnClick = useCallback((e: Event) => {
    e.stopPropagation();
  }, []);

  return (
    modalRoot &&
    ReactDOM.createPortal(
      <FadeBackground onClick={closeModal} showFadeOnMobile={windowedOnMobile}>
        <Container isColumnFlow onContainerClick={disableReactionOnClick} noPadding>
          <StyledCloseBtn visibleOnMobile={showCloseBtnOnMobile} onClick={closeModal} />
          {children}
        </Container>
      </FadeBackground>,
      modalRoot
    )
  );
}

const Container = styled(ContainerBase)<{ windowedOnMobile?: boolean }>`
  position: relative;
  pointer-events: all;
  cursor: auto;
`;

const StyledCloseBtn = styled(CloseBtn)`
  position: absolute;
  right: 16px;
  top: 16px;
  z-index: 1;
  :hover {
    cursor: pointer;
  }
`;
