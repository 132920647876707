import React from 'react';
import { Cell } from 'react-table';
import { useParams, useRouteMatch } from 'react-router-dom';
import { TableLayout } from 'components/ui/layouts/table-layout';
import { EntityTableColumn } from 'components/ui/layouts/table-layout/table-layout-types';
import { createUseModalList } from '../../../../api/modal-hooks';
import { Modal } from '../../../../api/modal-types';
import { PartnerModalPage } from './partner-modal-page';
import * as S from './partner-modals-table.styles';

export const PartnerModalsTable = ({ paramName }: { paramName: string }) => {
  const { partnerId } = useParams<{ [key: string]: string }>();
  const useModalList = createUseModalList(Number(partnerId));
  const { url } = useRouteMatch();

  const columns: EntityTableColumn<Modal>[] = React.useMemo(
    () => [
      {
        Header: 'Позиция',
        accessor: 'position',
      },
      {
        Header: 'Порядок',
        accessor: 'order',
      },
      {
        Header: 'Название',
        accessor: 'title',
      },
      {
        Header: 'Описание',
        accessor: 'description',
        width: 240,
        Cell: (cell: Cell) => <S.ValueWrapper title={cell.value}>{cell.value}</S.ValueWrapper>,
      },
      {
        Header: 'Активна',
        accessor: 'isActive',
        Cell: ({ value }) => (value ? 'Да' : 'Нет'),
      },
    ],
    []
  );

  return (
    <TableLayout<Modal>
      paramName={paramName}
      caption="Модальные окна"
      useEntityList={useModalList}
      entityComponent={() => <PartnerModalPage paramName={paramName} />}
      columns={columns}
      rowLinkPrefix={url}
      initialSortBy="id"
    />
  );
};
