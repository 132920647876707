import React from 'react';
import { useParams } from 'react-router-dom';
import { TableLayout } from 'components/ui/layouts/table-layout';
import { EntityTableColumn } from 'components/ui/layouts/table-layout/table-layout-types';
import { createUsePartnerWidgetList } from './api/widget-hooks';
import { PartnerWidgetPage } from './partner-widget-page';
import { PartnerWidget } from './api/widget-types';

export const PartnerWidgetsTable = ({ paramName }: { paramName: string }) => {
  const { partnerId } = useParams<{ [key: string]: string }>();
  const usePartnerWidgetList = createUsePartnerWidgetList(Number(partnerId));

  const columns: EntityTableColumn<PartnerWidget>[] = React.useMemo(
    () => [
      // {
      //   Header: 'Имя',
      //   accessor: 'widget',
      //   id: 'name',
      //   Cell: ({ value }) => value?.name,
      // },
      // {
      //   Header: 'Класс',
      //   accessor: 'widget',
      //   id: 'class',
      //   Cell: ({ value }) => value?.class,
      // },
      {
        Header: 'Позиция',
        accessor: 'position',
        id: 'position',
      },
      {
        Header: 'Активен',
        accessor: 'isActive',
        id: 'isActive',
        Cell: ({ value }) => (value ? 'Да' : 'Нет'),
      },
    ],
    []
  );

  return (
    <TableLayout<PartnerWidget>
      paramName={paramName}
      caption="Виджеты"
      useEntityList={usePartnerWidgetList}
      entityComponent={() => <PartnerWidgetPage paramName={paramName} />}
      columns={columns}
      initialSortBy="name"
    />
  );
};
