import * as Yup from 'yup';
import { errorMessages } from 'components/ui/formik-controls';

const schema = {
  email: Yup.string().required(errorMessages.required),
  passwordConfirmation: Yup.string().oneOf([Yup.ref('password')], errorMessages.passwordsNotMatch),
  isActive: Yup.boolean(),
  role: Yup.string().required(errorMessages.required),
  partnerId: Yup.number().required(errorMessages.required),
  partnerRole: Yup.number(),
};

export const NewUserSchema = Yup.object().shape({
  ...schema,
  password: Yup.string()
    .oneOf([Yup.ref('passwordConfirmation')], errorMessages.passwordsNotMatch)
    .required(errorMessages.required)
    .matches(/(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).{8,}/, errorMessages.passwordComplexity),
});

export const EditUserSchema = Yup.object().shape({
  ...schema,
  password: Yup.string()
    .oneOf([Yup.ref('passwordConfirmation')], errorMessages.passwordsNotMatch)
    .matches(/(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).{8,}/, errorMessages.passwordComplexity),
});
