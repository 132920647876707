import { createUseEntityList } from '../../../../api/base-hooks';
import {
  BonusRegistryListResponse,
  BonusRegistryErrorResponse,
  BonusRegistryGoalsResponse,
  BonusRegistryGoalTypesResponse,
  BonusRegistryLoyaltiesResponse,
} from './bonus-registry-types';
import { bonusRegistryApi } from './bonus-registry';

const SECTION = 'bonus-registry';

export const useBonusRegistryList = createUseEntityList<BonusRegistryListResponse>(SECTION, bonusRegistryApi.getList);
export const useBonusRegistryError = createUseEntityList<BonusRegistryErrorResponse>(
  SECTION,
  bonusRegistryApi.getError
);
export const useBonusRegistryGoals = createUseEntityList<BonusRegistryGoalsResponse>(
  `${SECTION}/goals`,
  bonusRegistryApi.getGoals
);
export const useBonusRegistryGoalTypes = createUseEntityList<BonusRegistryGoalTypesResponse>(
  `${SECTION}/goaltypes`,
  bonusRegistryApi.getGoalTypes
);
export const useBonusRegistryLoyalties = createUseEntityList<BonusRegistryLoyaltiesResponse>(
  `${SECTION}/loyalties`,
  bonusRegistryApi.getLoyalties
);
