import React, { ChangeEvent, useRef, useState } from 'react';
import { Button, Checkbox } from '@blueprintjs/core';
import { Tag } from '../api/tag-types';
import { useOfferList } from '../../offer/api/offer-hooks';
import { Loader } from '../../../ui/shared/loader';
import { ModalContainer, Table, Td } from '../tags-page.styles';
import { baseApi } from '../../../../api/base';
import { AppToaster } from '../../../ui/toaster';

export const AddOffersToTagModal = ({
  tag: { bankMnemocode, nameRu, offerToTag, id },
  onHideModal,
}: {
  tag: Tag;
  onHideModal: (result: any) => void;
}) => {
  const { data, isLoading } = useOfferList([bankMnemocode]);
  const [isChanged, setIsChanged] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const selectedOffersRef = useRef([] as { id: number; name: string }[]);
  if (!isChanged) {
    const selectedOffers: { id: number; name: string }[] = [];
    if (Array.isArray(offerToTag)) {
      offerToTag.forEach(({ offer }) => selectedOffers.push({ id: offer.id, name: offer.name }));
    }
    selectedOffersRef.current = selectedOffers;
  }
  const handleSave = async () => {
    setIsSaving(true);
    await baseApi.fetch(`/tag/${id}/add/offers`, {
      method: 'post',
      data: selectedOffersRef.current,
    });
    onHideModal({ tagId: id, selectedOffers: selectedOffersRef.current });
    AppToaster.show({ message: `Офферы успешно добавлены`, intent: 'success' });
  };
  const handleChecked = async (event: ChangeEvent<HTMLInputElement>, offer: { id: number; name: string }) => {
    if (!isChanged) {
      setIsChanged(true);
    }
    if (event.target.checked && !selectedOffersRef.current.find((selectedOffer) => offer.id === selectedOffer.id)) {
      selectedOffersRef.current = [...selectedOffersRef.current, { id: offer.id, name: offer.name }];
    } else if (!event.target.checked) {
      selectedOffersRef.current = selectedOffersRef.current.filter((selectedOffer) => selectedOffer.id !== offer.id);
    }
  };
  if (isLoading || isSaving) {
    return (
      <ModalContainer>
        <Loader size={111} />
      </ModalContainer>
    );
  }
  const offers = data;
  return (
    <div>
      <h2 style={{ textAlign: 'center' }}>{nameRu}</h2>
      <ModalContainer>
        <div style={{ overflowY: 'auto' }}>
          <Table>
            {Array.isArray(offers) &&
              offers.map((offerPartner) => (
                <tr>
                  <Td>{offerPartner.offer.name}</Td>
                  <Td style={{ textAlign: 'center' }}>
                    <Checkbox
                      onChange={(event: ChangeEvent<HTMLInputElement>) => handleChecked(event, offerPartner.offer)}
                      defaultChecked={Boolean(
                        selectedOffersRef.current.find((selectedOffer) => selectedOffer.id === offerPartner.offer.id)
                      )}
                    />
                  </Td>
                </tr>
              ))}
          </Table>
        </div>
        <div style={{ padding: '10px 0', textAlign: 'center' }}>
          <Button disabled={!isChanged} onClick={handleSave} intent="primary">
            Сохранить
          </Button>
        </div>
      </ModalContainer>
    </div>
  );
};
