import React from 'react';
import { Button, MenuItem } from '@blueprintjs/core';
import { ItemRenderer, Select as BluePrintSelect } from '@blueprintjs/select';

type SelectedValue = string | number;

export interface SelectOption {
  value: SelectedValue;
  caption: string;
}

const itemRenderer: ItemRenderer<SelectOption> = (item, { handleClick }) => {
  return <MenuItem onClick={handleClick} key={item.value} text={item.caption} />;
};

export function Select({
  options,
  onItemSelect,
  selectedValue,
  selectedItemIndex = 0,
}: {
  options: SelectOption[];
  onItemSelect: (item: SelectOption) => void;
  selectedValue?: SelectedValue;
  selectedItemIndex?: number;
}) {
  const state = options.find((option) => option.value === selectedValue);
  const initialState = state || options[selectedItemIndex];
  return (
    <BluePrintSelect items={options} filterable={false} itemRenderer={itemRenderer} onItemSelect={onItemSelect}>
      <Button text={initialState.caption} rightIcon="caret-down" />
    </BluePrintSelect>
  );
}
