export type ReportTypesResponse = (keyof typeof ReportTypeEnum)[];
export type ReportTypesResult = ReportType[];
export type ReportResponse = Report[];
export type ReportResult = Report & { id?: number }[];
export type ReportCurrencyResponse = Currency[];
export type ReportGoalTypesResponse = { [GoalType: string]: number };

export type ReportType = { type: ReportTypeEnum };

export type Report = {
  createdWhen: string;
  fileName: string;
  partnerMnemocode: string;
  reportState: ReportStateEnum;
  reportType: ReportTypeEnum;
};

export enum ReportStateEnum {
  IN_PROGRESS = 'IN_PROGRESS',
  DONE = 'DONE',
  ERROR = 'ERROR',
}

export enum ReportTypeEnum {
  SMS = 'SMS',
  CLO = 'CLO',
  CARDS = 'CARDS',
  MONTHLY = 'MONTHLY',
  BURNOUT = 'BURNOUT',
  SUMMARY = 'SUMMARY',
  BASIC_NEW = 'BASIC_NEW',
  BASIC_POSTED = 'BASIC_POSTED',
  ACTIVE_CARDS_SUMMARY = 'ACTIVE_CARDS_SUMMARY', // Сводный отчет по активным картам
}

export interface ActiveCardsSummaryInterface {
  reportPeriodStart: string;
  reportPeriodFinish: string;
}

export enum TransactionStatusEnum {
  NEW = 'NEW',
  HOLD = 'HOLD',
  POSTED = 'POSTED',
  EXCHANGE = 'EXCHANGE',
  CANCELED = 'CANCELED',
}

export interface BasicInterface {
  partnerMnemocode: string;
  period: string;
  status: TransactionStatusEnum;
  minCreatedTime?: string;
  maxCreatedTime?: string;
  currency?: string;
  testReport: boolean;
}

export interface BurnoutInterface {
  currency: string;
  partnerMnemocode: string;
  period: string;
}

export interface CardsInterface {
  partnerMnemocode: string;
  period: string;
}

export interface CloInterface {
  partnerMnemocode: string;
  period: string;
  goalTypes: number[];
  minCreatedWhen?: string;
}

export interface MonthlyInterface {
  bankMnemocode: string;
  period: string;
}

export interface SmsInterface {
  partnerMnemocode: string;
  period: string;
  excludedGoals?: number[];
  currency: string[];
  minCreatedWhen?: string;
}

export enum SchemaTypeEnum {
  TEST = 'TEST',
  PROD = 'PROD',
  TEMPORARY = 'TEMPORARY',
}

export interface SummaryInterface {
  bankMnemocode: string;
  createdWhen: string;
  schema: SchemaTypeEnum;
  startDate: string;
  finishDate: string;
}

export type PostReportData =
  | ActiveCardsSummaryInterface
  | BasicInterface
  | BurnoutInterface
  | CardsInterface
  | CloInterface
  | MonthlyInterface
  | SmsInterface
  | SummaryInterface;

export interface Currency {
  currency: string;
  details: string;
  enabledFlag: string;
  isoAlfa3Code: string;
  name: string;
}

export const mapReportTypeName: Partial<{ [key in ReportTypeEnum]: string }> = {
  [ReportTypeEnum.ACTIVE_CARDS_SUMMARY]: 'Сводный отчет по активным картам',
};
