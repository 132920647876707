import React from 'react';
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AppToaster } from 'components/ui/toaster';
import { Button } from '@blueprintjs/core';

const { protocol, hostname, port } = window.location;
const API_PORT = process.env.REACT_APP_API_PORT || port;
export const API_URL_BASE_PATH = `${protocol}//${hostname}:${API_PORT}/api`;
const UNAUTHORIZED_CODE = 401;

export type ApiResponse<T> = {
  statusCode: number;
  data: T;
};

export type ApiError = {
  statusCode: number;
  message: string;
  errorId?: string;
};

function createMessage(response: AxiosResponse<ApiError>): React.ReactNode {
  const { status, statusText, data } = response;
  return React.createElement('div', { style: { display: 'flex', flexDirection: 'column' } }, [
    React.createElement('span', { style: { padding: '0 0 6px 0' } }, `${status} ${statusText}`),
    React.createElement('span', { style: { padding: '0 0 6px 0' } }, `${data?.message}`),
    React.createElement('span', { style: { padding: '0 0 6px 0' } }, `${data?.errorId}`),
    window?.navigator?.clipboard?.writeText
      ? React.createElement(Button, {
          style: { backgroundColor: 'inherit', position: 'absolute', bottom: '8px', right: '8px' },
          rightIcon: 'clipboard',
          small: true,
          onClick: async (event: React.MouseEvent<HTMLElement>) => {
            await window?.navigator?.clipboard?.writeText(JSON.stringify(data, null, 2));
          },
        })
      : null,
  ]);
}

export class BaseApi extends EventTarget {
  constructor(base?: boolean) {
    super();
    if (!base) {
      return;
    }
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status === UNAUTHORIZED_CODE) {
          this.dispatchUnauthorized();
        } else {
          const message = createMessage(error.response);
          AppToaster.show({ icon: 'error', message, intent: 'danger' });
        }
        return Promise.reject(error);
      }
    );
  }

  // eslint-disable-next-line class-methods-use-this
  protected get token() {
    return localStorage.getItem('api-token') || '';
  }

  // eslint-disable-next-line class-methods-use-this
  protected set token(token: string) {
    localStorage.setItem('api-token', token);
  }

  protected dispatchUnauthorized = () => {
    this.dispatchEvent(new Event('unauthorized'));
  };

  logOut = () => {
    this.token = '';
    this.dispatchUnauthorized();
  };

  async fetch<T>(url: string, reqParams: AxiosRequestConfig = {}): Promise<T> {
    let headers: Record<string, string> = {};
    if (reqParams?.headers) {
      headers = { ...reqParams?.headers };
    }
    const requestParams: AxiosRequestConfig = {
      method: 'get',
      url,
      baseURL: API_URL_BASE_PATH,
      ...reqParams,
      headers: {
        ...headers,
        Authorization: `Bearer ${this.token}`,
      },
    };
    const response = await axios.request<ApiResponse<T>>(requestParams);
    const { data: payload } = response;
    return payload.data;
  }

  protected getMultipartFormData = (data: any) => {
    const { files, ...entity } = data;
    const formData = new FormData();
    formData.append('data', JSON.stringify(entity));
    if (Array.isArray(files) && files.length) {
      files.forEach((file, index) => formData.append(`file_${index}`, file.data, file.path));
    }
    return { formData, entity };
  };
}

export const baseApi = new BaseApi(true);
