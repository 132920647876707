import React from 'react';
import { getType } from 'utils';
import { Icon } from '@whitelabel/common/dist/api';
import { IconProps } from './types';
import { IconPreview } from './icon-preview';

export const IconCaption = (props: IconProps) => {
  const { icon } = props;
  const type = getType(icon);

  return (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      <IconPreview {...props} />
      {type === 'Object' && (icon as Icon)?.name && (
        <span style={{ display: 'flex', padding: '0 0 0 5px' }}>{(icon as Icon)?.name}</span>
      )}
    </span>
  );
};
