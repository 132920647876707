"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RegistryFileStatus = void 0;
var RegistryFileStatus;
(function (RegistryFileStatus) {
    RegistryFileStatus["TODO"] = "TODO";
    RegistryFileStatus["PROGRESS"] = "PROGRESS";
    RegistryFileStatus["DONE"] = "DONE";
    RegistryFileStatus["ERROR"] = "ERROR";
    RegistryFileStatus["ACCEPTED"] = "ACCEPTED";
    RegistryFileStatus["DECLINED"] = "DECLINED";
})(RegistryFileStatus = exports.RegistryFileStatus || (exports.RegistryFileStatus = {}));
