import React from 'react';
import { useRouteMatch, useHistory } from 'react-router-dom';
import * as S from './sidebar-menu.styles';

export type SidebarMenuItemProp = { url: string; caption: string };

export type SidebarMenuProps = {
  items: SidebarMenuItemProp[];
};
export const SidebarMenu = (props: SidebarMenuProps) => {
  const { items } = props;

  return (
    <S.Menu>
      {items.map(({ url, caption }) => (
        <Item key={url} url={url} caption={caption} />
      ))}
    </S.Menu>
  );
};

type ItemProps = {
  url: string;
  caption: string;
};
const Item = (props: ItemProps) => {
  const { url, caption } = props;
  const history = useHistory();
  const match = useRouteMatch(url);

  const handleClick = () => {
    history.push(url);
  };

  return (
    <S.Item selected={Boolean(match)} onClick={handleClick}>
      {caption}
    </S.Item>
  );
};
