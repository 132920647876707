import React from 'react';
import { Card } from '@blueprintjs/core';
import { SignInForm } from './form';
import * as S from './sign-in.styles';

export const SignInPage = () => {
  return (
    <S.Container>
      <S.SignInPlaceholder>
        <Card>
          <SignInForm />
        </Card>
      </S.SignInPlaceholder>
    </S.Container>
  );
};
