import React from 'react';
import styled from 'styled-components';

export interface CloseBtnProps {
  size?: number;
  color?: string;
  strokeWidth?: number;
  className?: string;
  onClick?: () => void;
  visibleOnMobile?: boolean;
}

export function CloseBtn({
  onClick,
  className,
  visibleOnMobile,
  strokeWidth = 2,
  size = 24,
  color = '#8e8f98',
}: CloseBtnProps) {
  return (
    <Container onClick={onClick} className={className} visibleOnMobile={visibleOnMobile} size={size}>
      <svg
        fill="none"
        width={size}
        height={size}
        stroke={color}
        viewBox="0 0 24 24"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
      >
        <path d="M7 17L17 7M7 7l10 10" />
      </svg>
    </Container>
  );
}

const Container = styled.div<{ visibleOnMobile?: boolean; size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
`;
