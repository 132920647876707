import { BaseApi } from 'api/base';
import * as Types from 'api/option-types';

class OptionApi extends BaseApi {
  getList = async (partnerId: number): Promise<Types.OptionListResponse> => {
    const result = await this.fetch<Types.OptionListResponse>(`/partner/${partnerId}/options`);
    return result;
  };
}

export const optionApi = new OptionApi();
