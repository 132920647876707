import React from 'react';
import { IconNames } from '@blueprintjs/icons';
import { URL } from 'utils/url-builder';
import { EntityList } from 'components/ui/skeletons/entity-list';
import { OffersTable } from './offers-table';

export const OfferList = () => {
  return (
    <EntityList
      sectionText="Офферы"
      icon={IconNames.CALCULATOR}
      listUrl={URL.offerList()}
      entityAddUrl={URL.offerView()}
      content={<OffersTable />}
    />
  );
};
