import { BaseApi } from 'api/base';
import * as Types from './program-types';

class ProgramApi extends BaseApi {
  getList = async (partnerId: number): Promise<Types.ProgramListResponse> => {
    const result = await this.fetch<Types.ProgramListResponse>(`/partner/${partnerId}/programs`);
    return result;
  };

  getItem = async (partnerId: number, programId: number): Promise<Types.ProgramResponse> => {
    const result = await this.fetch<Types.ProgramResponse>(`/partner/${partnerId}/programs/${programId}`);
    return result;
  };

  addItem = async (partnerId: number, data: Types.Program): Promise<Types.Program> => {
    const { formData } = this.getMultipartFormData(data);
    const result = await this.fetch<Types.ProgramAddResponse>(`/partner/${partnerId}/programs`, {
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    });
    return { id: result.id, ...data };
  };

  editItem = async (partnerId: number, programId: number, data: Types.Program): Promise<Types.Program> => {
    const { formData, entity } = this.getMultipartFormData(data);
    await this.fetch<void>(`/partner/${partnerId}/programs/${programId}`, {
      method: 'put',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    });
    return entity;
  };

  deleteItem = async (partnerId: number, programId: number): Promise<number> => {
    await this.fetch<void>(`/partner/${partnerId}/programs/${programId}`, { method: 'delete' });
    return programId;
  };
}

export const programApi = new ProgramApi();
