import { FormikHelpers } from 'formik';

export enum Role {
  SUDO = 'SUDO',
  USER = 'USER',
  AGENT = 'AGENT',
  ADMIN = 'ADMIN',
  ACL = 'ACL',
  GPADMIN = 'GPADMIN',
  GPUSER = 'GPUSER',
}

export type SetFieldValueType = FormikHelpers<any>['setFieldValue'];
