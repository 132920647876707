import React, { ChangeEvent, useEffect, useState } from 'react';
import styled from 'styled-components';
import sortBy from 'lodash/sortBy';
import { Button } from '@blueprintjs/core/lib/esm/components';
import { ItemRenderer, MultiSelect } from '@blueprintjs/select';
import { Loader } from 'components/ui/shared/loader';
import { Checkbox, MenuItem } from '@blueprintjs/core';
import { Role } from '../../../../utils/types';
import { useSectionList } from '../../section/api/section-hooks';
import { baseApi } from '../../../../api/base';
import { RoleAccess } from './api/role-access-types';
import { AddRoleAccessModal } from './components/AddRoleAccessModal';
import { useModal } from '../../../ui/modal';
import { AppToaster } from '../../../ui/toaster';

interface NumericSelectOption {
  value: string;
  caption: string;
}

const itemRenderer: ItemRenderer<NumericSelectOption> = (item, { handleClick }) => {
  return <MenuItem onClick={handleClick} key={item.value} text={item.caption} />;
};

const Table = styled.table`
  border-collapse: collapse;
  max-width: 1000px;
`;

const Td = styled.td`
  border: 1px solid black;
  padding: 5px;
`;

function getActionItems(availableActions: string[], selectedActions: string[]): { value: string; caption: string }[] {
  return availableActions.reduce((acc: { value: string; caption: string }[], action) => {
    if (!selectedActions.includes(action)) {
      acc.push({ value: action, caption: action });
    }
    return acc;
  }, []);
}

export const RoleAccessesTable = ({ role }: { role: Role }) => {
  const { showModal, ModalRender, hideModal } = useModal({});
  const [isLoading, setLoading] = useState(false);
  const [list, setList] = useState([] as any[]);
  const [changedItemIds, setChangedItemIds] = useState([] as any[]);
  useSectionList();
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const data = await baseApi.fetch<any>(`/role/access/list/${role}`);
      setList(data);
      setLoading(false);
    };
    fetchData();
  }, [role]);
  const handleRoleAccessDelete = async (deletedItem: any) => {
    if (!window.confirm('Удалить раздел?')) {
      return;
    }
    await baseApi.fetch<any>(`/role/access`, {
      method: 'DELETE',
      data: {
        role: deletedItem.role,
        section: deletedItem.section,
      },
    });
    setList(list.filter((item: any) => deletedItem.id !== item.id));
  };
  const handleRoleAccessAdd = () => {
    const availableSectionIds = (list as RoleAccess[]).map((item) => item.section.id);
    showModal(
      <AddRoleAccessModal
        availableSectionIds={availableSectionIds}
        role={role}
        hideModal={(result) => {
          setList(sortBy([...list, result], 'section.title'));
          hideModal();
        }}
      />
    );
  };
  const handleRoleAccessUpdate = (roleAccess: RoleAccess) => {
    setList(list.map((item) => (item.id === roleAccess.id ? roleAccess : item)));
    setChangedItemIds([...changedItemIds, roleAccess.id]);
  };
  const handleRoleAccessItemSave = async (roleAccess: RoleAccess) => {
    await baseApi.fetch<any>(`/role/access/${roleAccess.id}`, {
      method: 'PUT',
      data: {
        roleAccess: {
          path: roleAccess.path,
          role: roleAccess.role,
          actions: roleAccess.actions,
          hasUi: roleAccess.hasUi,
        },
      },
    });
    setChangedItemIds(changedItemIds.filter((id) => id !== roleAccess.id));
    AppToaster.show({ message: `Успешно сохранено`, intent: 'success' });
  };
  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      <ModalRender />
      <Table style={{ maxWidth: '1300px' }}>
        <tr>
          <Td>
            <b>Название раздела</b>
          </Td>
          <Td>
            <b>Путь</b>
          </Td>
          <Td>
            <b>Возможные действия</b>
          </Td>
          <Td>
            <b>Наличие UI</b>
          </Td>
          <Td>
            <b>Удалить</b>
          </Td>
        </tr>
        {(list || []).map((roleAccess: any) => (
          <tr>
            <Td>{roleAccess.section.title}</Td>
            <Td>{roleAccess.path}</Td>
            <Td style={{ width: '500px' }}>
              <MultiSelect
                fill
                tagInputProps={{
                  placeholder: 'Выберите действия',
                  onRemove(node, index) {
                    handleRoleAccessUpdate({
                      ...roleAccess,
                      actions: roleAccess.actions.filter((_: string, i: number) => index !== i),
                    });
                  },
                }}
                itemRenderer={itemRenderer}
                tagRenderer={(item) => <div>{item}</div>}
                items={getActionItems(roleAccess.section.actions, roleAccess.actions)}
                selectedItems={roleAccess.actions}
                onItemSelect={(selectedItem) => {
                  handleRoleAccessUpdate({ ...roleAccess, actions: [...roleAccess.actions, selectedItem.value] });
                }}
              />
            </Td>
            <Td style={{ textAlign: 'center' }}>
              {roleAccess.section.hasUi && (
                <Checkbox
                  style={{ margin: '0' }}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    handleRoleAccessUpdate({ ...roleAccess, hasUi: event.target.checked });
                  }}
                  defaultChecked={Boolean(roleAccess.hasUi)}
                />
              )}
            </Td>
            <Td>
              <Button onClick={() => handleRoleAccessDelete(roleAccess)}>Удалить</Button>
            </Td>
            <td style={{ paddingLeft: '10px' }}>
              {changedItemIds.includes(roleAccess.id) && (
                <Button intent="success" text="Сохранить" onClick={() => handleRoleAccessItemSave(roleAccess)} />
              )}
            </td>
          </tr>
        ))}
      </Table>
      <div style={{ padding: '10px' }}>
        <Button intent="success" onClick={handleRoleAccessAdd}>
          Добавить
        </Button>
      </div>
    </div>
  );
};
