import React from 'react';
import { Cell } from 'react-table';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { EntityTable, EntityTableColumn } from '../../ui/skeletons/entity-table';
import { BonusRegistry } from './api/bonus-registry-types';
import { useBonusRegistryList } from './api/bonus-registry-hooks';
import { getUserRole } from '../../../selectors/auth';
import * as S from './bonus-registry-table.styles';

export const BonusRegistryTable = () => {
  const userRole = useSelector(getUserRole);

  const columns: EntityTableColumn<BonusRegistry>[] = React.useMemo(() => {
    return [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        width: 150,
        Cell: (cell: Cell) => <S.ValueWrapper title={cell.value}>{cell.value}</S.ValueWrapper>,
      },
      {
        Header: 'Описание',
        accessor: 'description',
        id: 'description',
        width: 150,
        Cell: (cell: Cell) => <S.ValueWrapper title={cell.value}>{cell.value}</S.ValueWrapper>,
      },
      {
        Header: 'Цель',
        accessor: 'goalName',
        id: 'goalName',
        width: 150,
        Cell: (cell: Cell) => <S.ValueWrapper title={cell.value}>{cell.value}</S.ValueWrapper>,
      },
      {
        Header: 'Программа',
        accessor: 'loyaltyMnemocode',
        id: 'loyaltyMnemocode',
        width: 100,
      },
      {
        Header: 'Статус',
        accessor: 'status',
        id: 'status',
        width: 100,
      },
      {
        Header: 'Период',
        accessor: 'period',
        id: 'period',
        width: 70,
      },
      {
        Header: 'Файл',
        accessor: 'fileSource',
        id: 'fileSource',
        width: 180,
        Cell: (cell: Cell) => <S.ValueWrapper title={cell.value}>{cell.value}</S.ValueWrapper>,
      },
      {
        Header: 'Создан',
        accessor: 'createdWhen',
        id: 'createdWhen',
        width: 130,
        Cell: ({ value }: { value: Date }) => moment(value).format('DD.MM.YYYY HH:mm:ss') || null,
      },
      {
        Header: 'Обновлен',
        accessor: 'updatedWhen',
        id: 'updatedWhen',
        width: 130,
        Cell: ({ value }: { value: Date }) => moment(value).format('DD.MM.YYYY HH:mm:ss') || null,
      },
      {
        Header: 'Файл',
        accessor: 'fileProcessed',
        id: 'fileProcessed',
      },
    ].filter((column) => userRole === 'SUDO' || column.id !== 'fileProcessed') as EntityTableColumn<BonusRegistry>[];
  }, []);

  return (
    <EntityTable<BonusRegistry>
      useEntityList={useBonusRegistryList}
      columns={columns}
      initialSortBy="updatedWhen"
      desc={true}
      rowLinkPrefix="/bonus-registry/error"
    />
  );
};
