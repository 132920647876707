import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

export interface ProtectedRouteProps extends RouteProps {
  allowed: boolean;
  redirectToUrl: string;
}

export const ProtectedRoute = (props: ProtectedRouteProps) => {
  const { allowed, redirectToUrl, ...rest } = props;

  if (!allowed) {
    return <Redirect to={redirectToUrl} />;
  }

  return <Route {...rest} />;
};

export default ProtectedRoute;
