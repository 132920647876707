import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';
import { Callout } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { InputField, SumbitButton } from 'components/ui/formik-controls';
import { logIn } from 'actions';
import { authApi } from 'api/auth';
import { SignInSchema } from './sign-in-schema';
import { AppToaster } from '../../ui/toaster';

const initialValues = { email: '', password: '' };

type SignInFormProps = {
  email: string;
  password: string;
};

export const SignInForm = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState<string | undefined>();

  const handleSumbit = async (email: string, password: string, setSubmitting: (isSubmitting: boolean) => void) => {
    try {
      const result = await authApi.login(email, password);
      if (result.access_token) {
        dispatch(logIn());
      }
    } catch (e) {
      setError('Неверный логин и/или пароль');
      AppToaster.show({ message: `Неверный логин и/или пароль`, intent: 'danger' });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik<SignInFormProps>
      initialValues={initialValues}
      validationSchema={SignInSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleSumbit(values.email, values.password, setSubmitting);
      }}
    >
      {({ isSubmitting }) => (
        <Form>
          <InputField name="email" label="Логин" />
          <InputField name="password" label="Пароль" type="password" />
          <SumbitButton fill isSubmitting={isSubmitting} intent="success" icon={IconNames.LOG_IN} text="Войти" />
          {error && (
            <Callout style={{ marginTop: '10px' }} intent="warning">
              {error}
            </Callout>
          )}
        </Form>
      )}
    </Formik>
  );
};
