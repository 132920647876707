import React from 'react';
import { InputField, SwitchField } from 'components/ui/formik-controls';
import type { IconType, IconTypeWithFile, RestrictionsSectionNameType } from '@whitelabel/common/dist/api';
import type { RestrictionsCommonProps } from './types';
import { getRestrictionSectionsFullNames } from './utils';
import { ChooseIconHandler } from '../components';

const sectionNamesRussian: Record<RestrictionsSectionNameType, string> = {
  conversion: 'конвертации',
  precalc: 'предрасчет',
  overall: 'ЛК',
};

interface Props extends RestrictionsCommonProps {
  section: RestrictionsSectionNameType;
}

export function PartnerRestrictionsSection({ section, values, initialValues }: Props) {
  const { fieldSectionName, fieldNameFrom, fieldNameTo, fieldMessage, fieldIcon } =
    getRestrictionSectionsFullNames(section);

  return (
    <>
      <SwitchField name={fieldSectionName} label={`Выключить ${sectionNamesRussian[section]}`} />
      {Boolean(values[fieldSectionName]) && (
        <>
          <InputField type="datetime-local" label="с (UTC, включительно)" name={fieldNameFrom} />
          <InputField type="datetime-local" label="по (UTC, включительно)" name={fieldNameTo} />
          <InputField label="Сообщение в ЛК" name={fieldMessage} />
          <ChooseIconHandler
            groupLabel="Иконка для сообщения в ЛК"
            formFieldName={fieldIcon}
            initialIcon={initialValues[fieldIcon] as IconType}
            currentIcon={values[fieldIcon] as IconTypeWithFile}
          />
        </>
      )}
    </>
  );
}
