import { BaseApi } from 'api/base';
import { ProductProgram } from './product-program-types';

class ProductProgramApi extends BaseApi {
  getList = async (partnerId: number, productId: number): Promise<ProductProgram[]> => {
    const result = await this.fetch<ProductProgram[]>(`partner/${partnerId}/product/${productId}/product-program`);
    return result;
  };

  getItem = async (partnerId: number, productProgramId: number): Promise<ProductProgram> => {
    const result = await this.fetch<ProductProgram>(`partner/${partnerId}/product-program/${productProgramId}`);
    return result;
  };

  addItem = async (
    partnerId: number,
    productId: number,
    programId: number,
    data: ProductProgram
  ): Promise<ProductProgram> => {
    const result = await this.fetch<ProductProgram>(`/partner/${partnerId}/product/${productId}/program/${programId}`, {
      method: 'post',
      data,
    });
    return { id: result.id, ...data };
  };

  editItem = async (partnerId: number, productProgramId: number, data: ProductProgram): Promise<ProductProgram> => {
    const { programId: programIdRemoved, ...restData } = data;
    await this.fetch<ProductProgram>(`partner/${partnerId}/product-program/${productProgramId}`, {
      method: 'put',
      data: restData,
    });
    return data;
  };

  deleteItem = async (partnerId: number, productProgramId: number): Promise<number> => {
    await this.fetch<ProductProgram>(`partner/${partnerId}/product-program/${productProgramId}`, { method: 'delete' });
    return productProgramId;
  };
}

export const productProgramApi = new ProductProgramApi();
