import { BaseApi } from 'api/base';
import * as Types from './section-types';

class SectionApi extends BaseApi {
  getList = async (): Promise<Types.SectionListResponse> => {
    const result = await this.fetch<Types.SectionListResponse>(`/section`);
    return result;
  };

  getItem = async (id: number): Promise<Types.SectionResponse> => {
    const result = await this.fetch<Types.Section>(`/section/${id}`);
    return result;
  };
}

export const sectionApi = new SectionApi();
