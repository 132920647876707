import React from 'react';
import { IconNames } from '@blueprintjs/icons';
import { URL } from 'utils/url-builder';
import { EntityList } from 'components/ui/skeletons/entity-list';
import { SectionsTable } from './sections-table';

export const SectionList = () => {
  return (
    <EntityList
      sectionText="Разделы"
      icon={IconNames.CALCULATOR}
      listUrl={URL.sectionList()}
      entityAddUrl={URL.sectionView()}
      content={<SectionsTable />}
    />
  );
};
