import React from 'react';
import { ActionProps } from '@blueprintjs/core';
import { useRouteMatch, match as Match } from 'react-router-dom';
import * as _ from 'lodash';
import { BreadcrumbItem, Breadcrumbs } from '../breadcrumbs';

export interface BreadcrumbRouteProps extends ActionProps {
  path: string;
}

export type OnMatch<T> = (match?: Match<T>, route?: BreadcrumbRouteProps) => Partial<BreadcrumbItem> | void;

export interface BreadcrumbsRouteProps {
  routes: BreadcrumbRouteProps[];
  onMatch?: OnMatch<any>;
}

export const BreadcrumbsRoute = (props: BreadcrumbsRouteProps) => {
  const { routes, onMatch } = props;
  const breadcrumbsItems = _.chain(routes)
    .map('path')
    .map(_.unary(useRouteMatch))
    .filter()
    .groupBy((route) =>
      _.chain(route?.path)
        .split('/')
        .filter()
        .size()
        .value()
    )
    .values()
    .map(_.head)
    .map((match, index, matches) => {
      const route = _.find(routes, { path: match?.path });
      const href = matches.length - 1 === index ? undefined : match?.url;
      const text = route?.text && match?.params ? _.template(route?.text.toString())(match?.params) : undefined;
      return {
        ..._.omit(route, ['path']),
        ...{ href },
        ...{ text },
        ...(match && onMatch ? onMatch(match, route) : {}),
      };
    })
    .value();

  return <Breadcrumbs items={breadcrumbsItems} />;
};
