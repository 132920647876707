import { reportApi } from './report';
import { TypedQueryFunction, useQuery } from 'react-query';
import { QueryConfig } from 'react-query/types/core/types';
import { ReportResponse, ReportTypesResult, ReportCurrencyResponse, ReportGoalTypesResponse } from './report-types';

const SECTION = 'report';

export type TError = {
  statusCode: number;
  message: string;
  error: string;
};

export const createUse = <TResult, TArgs extends [] = any>(
  section: string,
  queryFn: TypedQueryFunction<TResult, any>,
  queryConfig?: QueryConfig<TResult, TError>
) => (args?: TArgs) => {
  const queryKey = [section].concat(...(args || [])).join('.');
  return useQuery<TResult, TError>(queryKey, () => queryFn(args), queryConfig);
};

export const useReportTypes = createUse<ReportTypesResult>(`${SECTION}.getTypes`, reportApi.getTypes, {
  refetchOnWindowFocus: false,
});

export const useReport = createUse<ReportResponse, any>(`${SECTION}.getReport`, reportApi.getReport, {
  refetchOnWindowFocus: false,
});

export const useReportCurrency = createUse<ReportCurrencyResponse, any>(
  `${SECTION}.getCurrency`,
  reportApi.getCurrency,
  {
    refetchOnWindowFocus: false,
  }
);

export const useReportGoalTypes = createUse<ReportGoalTypesResponse, any>(
  `${SECTION}.getGoalTypes`,
  reportApi.getGoalTypes,
  {
    refetchOnWindowFocus: false,
  }
);
