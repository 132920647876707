import React from 'react';
import { useParams } from 'react-router-dom';
import { EntityForm } from 'components/ui/layouts/entity-form';
import { TabsRouter } from 'components/ui/layouts/tabs-router';
import { Tab } from 'components/ui/layouts/tabs-router/tabs-router-types';
import {
  createUseModal,
  createUseModalAdd,
  createUseModalUpdate,
  createUseModalDelete,
} from '../../../../api/modal-hooks';
import { Modal } from '../../../../api/modal-types';
import { PartnerModalForm } from './partner-modal-form';

export const PartnerModalPage = ({ paramName }: { paramName: string }) => {
  const { partnerId, modalId } = useParams<{ [key: string]: string }>();

  const useModal = createUseModal(Number(partnerId));
  const useModalAdd = createUseModalAdd(Number(partnerId));
  const useModalUpdate = createUseModalUpdate(Number(partnerId));
  const useModalDelete = createUseModalDelete(Number(partnerId));

  const tabs: Tab[] = [
    {
      caption: 'Карточка',
      component: (
        <EntityForm<Modal>
          paramName={paramName}
          listUrl={`/partner/${partnerId}/modal`}
          getEntityText={(data) => data?.id?.toString()}
          component={PartnerModalForm}
          useEntity={useModal}
          useEntityAdd={useModalAdd}
          useEntityMutate={useModalUpdate}
          useEntityDelete={useModalDelete}
        />
      ),
    },
  ];
  return <TabsRouter route={`/partner/${partnerId}/modal/${modalId}`} tabs={tabs} />;
};
