import React from 'react';
import * as S from './hooks.styles';
import * as Yup from 'yup';
import { DateInputField, errorMessages, SelectField } from '../../../ui/formik-controls';
import { FormFieldsFunction } from './form-fields';
import { BurnoutInterface } from '../api/report-types';
import * as _ from 'lodash';
import { usePartnerList } from '../../../../api/partner-hooks';
import moment from 'moment';
import { useReportCurrency } from '../api/report-hooks';

const state: Partial<BurnoutInterface> = {
  currency: undefined,
  partnerMnemocode: undefined,
  period: undefined,
};

const schema: Yup.Schema<object | undefined> = Yup.object().shape({
  currency: Yup.string().required(errorMessages.required),
  partnerMnemocode: Yup.string().required(errorMessages.required),
  period: Yup.string().required(errorMessages.required),
});

export const useBurnout: FormFieldsFunction<Partial<BurnoutInterface>> = () => {
  const partnerMnemocodeOptions = _.chain(usePartnerList())
    .get('data')
    .map((partner) => ({ value: partner?.mnemocode, caption: `${partner?.mnemocode} - ${partner?.components?.name}` }))
    .value();

  const currencyOptions = _.chain(useReportCurrency())
    .get('data')
    .map((currency) => ({
      value: currency?.currency,
      caption: `${currency?.currency} - ${currency?.details || currency?.name}`,
    }))
    .value();

  function render() {
    return (
      <S.FormWrapper>
        <S.Column>
          <SelectField
            options={partnerMnemocodeOptions}
            name="partnerMnemocode"
            label="Партнер"
            filterable={false}
            placeholder="Выберите партнера"
          />
          <SelectField
            options={currencyOptions}
            name="currency"
            label="Валюта"
            placeholder="Выберите валюту"
            popoverProps={{
              boundary: 'viewport',
              popoverClassName: 'scrollable',
            }}
          />
          <DateInputField
            name="period"
            label="Период"
            placeholder="YYYY-MM"
            formatDate={(date) => moment(date).format('YYYY-MM')}
            parseDate={(str) => moment(str, 'YYYY-MM').toDate()}
          />
        </S.Column>
      </S.FormWrapper>
    );
  }

  return {
    state,
    schema,
    render,
  };
};
