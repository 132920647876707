import React, { useEffect } from 'react';
import { useHistory, Switch, Route, useRouteMatch } from 'react-router-dom';
import { QueryResult } from 'react-query';
import { useDispatch } from 'react-redux';
import { Button } from '@blueprintjs/core';
import { Column } from 'react-table';
import { ApiError } from 'api/base';
import { Loader } from 'components/ui/shared/loader';
import { Table } from 'components/ui/table';
import { addBreadCrumb, removeBreadCrumb } from 'actions';
import { ApiListArgs } from './table-layout-types';

type baseEntityType = { id?: number | string };

export type TableLayoutProps<T extends baseEntityType> = {
  paramName: string;
  useEntityList: (params?: ApiListArgs) => QueryResult<T[], ApiError>;
  // entityEditUrl?: (id: number) => string;
  entityComponent: () => JSX.Element;
  columns: Column<T>[];
  initialSortBy: string;
  desc?: boolean;
  params?: ApiListArgs;
  onRowClick?: (item: T) => void;
  caption: string;
  rowLinkPrefix?: string;
};
export const TableLayout = <T extends baseEntityType>(props: TableLayoutProps<T>) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const { rowLinkPrefix, useEntityList, columns, initialSortBy, params, desc, entityComponent, caption, paramName } = props;
  const { data, isLoading } = useEntityList(params);
  const initialState = React.useMemo(
    () => ({
      sortBy: [
        {
          id: initialSortBy,
          desc,
        },
      ],
      pageIndex: 0,
      pageSize: 30,
    }),
    [initialSortBy, desc]
  );
  const handleAdd = () => {
    history.push(`${url}/add`);
  };
  const handleRowClick = (item: T) => {
    history.push(`${url}/${item.id}`);
  };
  useEffect(() => {
    dispatch(addBreadCrumb({ path: url, caption }));
    return () => {
      dispatch(removeBreadCrumb({ path: url }));
    };
  }, [dispatch, url, caption]);

  if (!data || isLoading) {
    return <Loader size={111} />;
  }
  return (
    <Switch>
      <Route exact path={`${path}`}>
        <div style={{ padding: '10px' }}>
          <Button intent="success" text="Добавить" onClick={handleAdd} />
        </div>
        <Table<T>
          columns={columns}
          data={data}
          initialState={initialState}
          onRowClick={rowLinkPrefix ? undefined : handleRowClick}
          rowLinkPrefix={rowLinkPrefix}
        />
      </Route>
      <Route path={`${path}/:${paramName}`}>{entityComponent()}</Route>
    </Switch>
  );
};
