import React, { useState } from 'react';
import { Cell } from 'react-table';
import { Button, Alert, Intent, ButtonGroup } from '@blueprintjs/core';
import { useHistory } from 'react-router-dom';
import { EntityTable, EntityTableColumn } from '../../ui/skeletons/entity-table';
import { CashbackActionList } from './api/cashback-action-types';
import { useCashbackActionList } from './api/cashback-action-hooks';
import * as TS from './cashback-action-table.styles';
import * as S from './index.styles';
import { AppToaster } from '../../ui/toaster';
import { cashbackActionApi } from './api/cashback-action';
import { isCashbackActionActive, isCashbackActionNew } from './utils';

export const CashbackActionTable = () => {
  const history = useHistory();
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [reload, setReload] = useState<number>(Date.now());

  const handleMerchantClick = (item: any) => {
    history.push(`/cashback-action/${item.id}/merchant`);
  };

  const handleDeleteCancel = () => setDeleteId(null);
  const handleDeleteConfirm = async () => {
    if (deleteId !== null) {
      cashbackActionApi
        .deleteItem(deleteId)
        .then(() => {
          setReload(Date.now());
          AppToaster.show({ message: `Кэшбэк-акция успешно удалена`, intent: 'success' });
        })
        .catch(() => AppToaster.show({ message: `Кэшбэк-акция НЕ удалена`, intent: 'danger' }))
        .finally(() => setDeleteId(null));
    }
  };

  const columns: EntityTableColumn<CashbackActionList>[] = React.useMemo(() => {
    return [
      {
        Header: 'Id',
        accessor: 'id',
        id: 'id',
        width: 80,
        Cell: (cell: Cell) => <TS.ValueWrapper title={cell.value}>{cell.value}</TS.ValueWrapper>,
      },
      {
        Header: 'Банк',
        accessor: 'partner',
        id: 'partner',
        Cell: (cell: Cell) => <TS.ValueMultilineWrapper title={cell.value}>{cell.value}</TS.ValueMultilineWrapper>,
      },
      {
        Header: 'Название',
        accessor: 'title',
        id: 'title',
        Cell: (cell: Cell) => <TS.ValueMultilineWrapper title={cell.value}>{cell.value}</TS.ValueMultilineWrapper>,
      },
      {
        Header: 'Тип значения',
        width: 130,
        accessor: 'bonusCalculation',
        id: 'bonusCalculation',
        Cell: (cell: Cell) => <TS.ValueWrapper title={cell.value}>{cell.value}</TS.ValueWrapper>,
      },
      {
        Header: 'Размер кэшбэка',
        width: 150,
        accessor: 'bonusValue',
        id: 'bonusValue',
        Cell: (cell: Cell) => <TS.ValueWrapper title={cell.value}>{cell.value}</TS.ValueWrapper>,
      },
      {
        Header: 'Статус',
        accessor: (originalRow) => {
          return Number(isCashbackActionActive(originalRow));
        },
        width: 100,
        Cell: (cell: Cell<CashbackActionList>) => {
          const isActive = isCashbackActionActive(cell.row.original);
          const status = isActive ? 'Активна' : 'Не активна';

          return <TS.ValueWrapper title={status}>{status}</TS.ValueWrapper>;
        },
      },
      {
        Header: 'Подтверждена',
        accessor: (originalRow) => {
          return Number(originalRow.isForAll);
        },
        width: 140,
        Cell: (cell: Cell<CashbackActionList>) => {
          const { isForAll } = cell.row.original;
          const status = isForAll ? 'Да' : 'Нет';

          return <TS.ValueWrapper title={status}>{status}</TS.ValueWrapper>;
        },
      },
      {
        Header: 'Период',
        width: 100,
        Cell: (cell: Cell<CashbackActionList>) => {
          const { startAt, stopAt } = cell.row.original;

          return (
            <TS.ValueWrapper title={`${startAt} - ${stopAt}`}>
              {startAt}
              <br />
              {stopAt}
            </TS.ValueWrapper>
          );
        },
      },
      // {
      //   Header: 'Показ в ЛК',
      //   width: 120,
      //   Cell: (cell: Cell<CashbackActionList>) => {
      //     const { displayDate, stopAt } = cell.row.original;
      //     const displayDateDate = new Date(displayDate);
      //     const stopAtDate = new Date(stopAt);
      //     const currentDate = new Date();
      //     let status = 'Нет';
      //
      //     if (displayDate && currentDate >= displayDateDate && (!stopAt || (stopAt && currentDate <= stopAtDate))) {
      //       status = 'Да';
      //     }
      //
      //     return <TS.ValueWrapper title={status}>{status}</TS.ValueWrapper>;
      //   },
      // },
      {
        Header: 'Новая',
        accessor: (originalRow) => {
          return Number(isCashbackActionNew(originalRow));
        },
        width: 80,
        Cell: (cell: Cell<CashbackActionList>) => {
          const status = isCashbackActionNew(cell.row.original) ? 'Да' : 'Нет';

          return <TS.ValueWrapper title={status}>{status}</TS.ValueWrapper>;
        },
      },
      {
        Header: 'Действия',
        width: 100,
        Cell: (cell: Cell) => (
          <ButtonGroup vertical>
            <Button
              intent="primary"
              disabled={false}
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                handleMerchantClick(cell.row.values);
                event.stopPropagation();
                event.preventDefault();
              }}
            >
              Мерчанты
            </Button>
            <Button
              intent="danger"
              disabled={false}
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                setDeleteId(cell.row.values.id);
                event.stopPropagation();
                event.preventDefault();
              }}
            >
              Удалить
            </Button>
          </ButtonGroup>
        ),
      },
      {
        Header: ' ',
        width: 10,
      },
    ];
  }, [handleMerchantClick]);

  return (
    <S.Section>
      <Alert
        isOpen={deleteId !== null}
        icon="trash"
        intent={Intent.DANGER}
        cancelButtonText="Отмена"
        confirmButtonText="Удалить"
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
      >
        <h4>Подтвердите удаление Кэшбэк-акции!</h4>
      </Alert>
      <EntityTable<CashbackActionList>
        key={reload}
        useEntityList={useCashbackActionList}
        columns={columns}
        initialSortBy="id"
        desc
        rowLinkPrefix="/cashback-action"
      />
    </S.Section>
  );
};
