import React from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { useAction } from './api/action-hooks';

export const ActionPage = () => {
  const { id } = useParams<{ [key: string]: string }>();
  const { data } = useAction(Number(id));
  const getTr = (obj: Record<string, string | number | boolean>) => {
    return Object.entries(obj).map(([key, val]) => (
      <tr>
        <td style={{ paddingLeft: '2em' }}>
          <b>{key}:</b>
        </td>
        <td>{val.toString()}</td>
      </tr>
    ));
  };

  return (
    <table>
      <tr>
        <td>
          <b>Id:</b>
        </td>
        <td>{data?.id}</td>
      </tr>
      <tr>
        <td>
          <b>Action:</b>
        </td>
        <td>{data?.action}</td>
      </tr>
      <tr>
        <td>
          <b>Login:</b>
        </td>
        <td>{data?.login}</td>
      </tr>
      <tr>
        <td>
          <b>Time (Moscow):</b>
        </td>
        <td>{moment.utc(data?.time).format('HH:mm:ss, DD.MM.yyyy')}</td>
      </tr>
      <tr>
        <td>
          <b>Request:</b>
        </td>
        <td></td>
      </tr>
      {data?.request && getTr(data?.request)}
      <tr>
        <td>
          <b>Response:</b>
        </td>
        <td></td>
      </tr>
      {data?.response && getTr(data?.response)}
      <tr>
        <td>
          <b>Result:</b>
        </td>
        <td>{data?.result}</td>
      </tr>
    </table>
  );
};
