import { BaseApi } from 'api/base';
import * as Types from 'api/product-types';

class ProductApi extends BaseApi {
  getList = async (partnerId: number): Promise<Types.ProductListResponse> => {
    const result = await this.fetch<Types.ProductListResponse>(`/partner/${partnerId}/products`);
    return result;
  };

  getItem = async (partnerId: number, productId: number): Promise<Types.ProductResponse> => {
    const result = await this.fetch<Types.ProductResponse>(`/partner/${partnerId}/products/${productId}`);
    return result;
  };

  addItem = async (partnerId: number, data: Types.Product): Promise<Types.Product> => {
    const { formData } = this.getMultipartFormData(data);
    const result = await this.fetch<Types.AddProductResponse>(`/partner/${partnerId}/products`, {
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    });
    return { id: result.id, ...data };
  };

  editItem = async (partnerId: number, id: number, data: Types.Product): Promise<Types.Product> => {
    const { formData, entity } = this.getMultipartFormData(data);
    await this.fetch<Types.ProductResponse>(`/partner/${partnerId}/products/${id}`, {
      method: 'put',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    });
    return entity;
  };

  deleteItem = async (partnerId: number, id: number): Promise<number> => {
    await this.fetch<Types.ProductResponse>(`/partner/${partnerId}/products/${id}`, { method: 'delete' });
    return id;
  };
}

export const productApi = new ProductApi();
