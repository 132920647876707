import * as Yup from 'yup';
import { errorMessages } from 'components/ui/formik-controls';

export const PartnerProductSchema = Yup.object().shape({
  mnemocode: Yup.string().matches(/^\d+$/, errorMessages.onlyDigits).required(errorMessages.required),
  isActive: Yup.boolean(),
  // eslint-disable-next-line @typescript-eslint/camelcase
  product_class: Yup.string(),
  components: Yup.object().shape({
    name: Yup.string().required(errorMessages.required),
    // eslint-disable-next-line @typescript-eslint/camelcase
    payment_org: Yup.string().required(errorMessages.required),
    banner: Yup.string(),
    logo: Yup.string(),
  }),
});
