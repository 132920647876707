import styled from 'styled-components';

export const BREADCRUMBS_HEIGHT = 50;

export const Container = styled.div`
  display: flex;
  height: ${BREADCRUMBS_HEIGHT}px
  flex-direction: column;
  user-select: none;

  /* padding: 11px; */
`;
