import styled from 'styled-components';

export const Aside = styled.aside`
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 50px;
  height: 100%;
  width: 230px;
  color: #fff;
  background-color: #222d32;
  z-index: 20;
  // padding: 10px;
`;
