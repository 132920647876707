import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Select, SelectOption } from 'components/ui/form-controls/Select';
import { ProfileResponse } from 'api/auth-types';
import { usePartnerList } from 'api/partner-hooks';
import { Loader } from 'components/ui/shared/loader';
import { AppToaster } from 'components/ui/toaster';
import { profileReceived } from 'actions';
import { headerApi } from '../api/header';

export const PartnerSelect = ({ user }: { user: ProfileResponse | undefined }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [partnerId, setPartnerId] = useState(user?.partnerId);
  const dispatch = useDispatch();
  const partners = usePartnerList();
  const partnerOptions = Array.isArray(partners.data)
    ? partners?.data?.map(({ id, components: { name } }) => ({
        value: id || 0,
        caption: name || '',
      }))
    : [{ value: 0, caption: '' }];
  const handleSelectPartner = async (item: SelectOption) => {
    setIsLoading(true);
    const newPartnerId = Number(item.value);
    const response = await headerApi.changePartner({ partnerId: newPartnerId });
    dispatch(profileReceived(response.profile));
    setIsLoading(false);
    setPartnerId(newPartnerId);
    AppToaster.show({ message: `Партнёр успешно сменён`, intent: 'success' });
  };
  return (
    <div
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0 0 0 10px', height: '100%' }}
    >
      {isLoading ? (
        <Loader size={40} intent="none" />
      ) : (
        <Select options={partnerOptions} onItemSelect={handleSelectPartner} selectedValue={partnerId} />
      )}
    </div>
  );
};
