import { Store, createStore, applyMiddleware, compose } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { Action } from 'typesafe-actions';
import thunkMiddleware, { ThunkMiddleware, ThunkAction } from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { createRootReducer, RootState } from 'reducers/index';
import { rootSaga } from 'sagas/index';
import { appStarted } from 'actions';

export type ThunkResult<R> = ThunkAction<R, RootState, undefined, Action>;

export const history = createBrowserHistory();

/* eslint-disable @typescript-eslint/no-explicit-any */
const composeEnhancers =
  typeof window === 'object' &&
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  process.env.NODE_ENV === 'development'
    ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;
/* eslint-enable @typescript-eslint/no-explicit-any */

const configureStore = (): Store<RootState> => {
  const rootReducer = createRootReducer(history);
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(
    rootReducer,
    composeEnhancers(
      applyMiddleware(thunkMiddleware as ThunkMiddleware<RootState, Action>, routerMiddleware(history), sagaMiddleware)
    )
  );

  sagaMiddleware.run(rootSaga);
  store.dispatch(appStarted());

  return store;
};

export default configureStore;
