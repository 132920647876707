export type SectionName = string;
type TListUrl = () => string;
type TAddUrl = () => string;
type TViewUrl = (id?: number, tab?: string, childId?: number) => string;

export class URL {
  static sectionUrl = (section: SectionName) => `/${section}`;

  static listUrl = (section?: string, listName?: string): string => {
    return `/${section || ':section'}/${listName || 'list'}`;
  };

  static addUrl = (section?: string): string => {
    return `/${section || ':section'}/add`;
  };

  // private static viewUrl = (section: string, id?: number, tab?: string): string => {
  //   return `/${section}/${id || ':id'}/view/tab/${id ? tab || URL.defaultTab : ':tabId'}`;
  // };

  static viewUrl = (section: string, id?: number | string, tab?: string, childId?: number | string): string => {
    if (!id) {
      return `/${section}/:id/view`;
    }
    if (!tab) {
      return `/${section}/${id}/view/tab/${URL.defaultTab}`;
    }
    if (!childId) {
      return `/${section}/${id}/view/tab/${tab}`;
    }
    return `/${section}/${id}/view/tab/${tab}/${childId}`;
  };

  static sectionPagePaths = [
    URL.addUrl(),
    URL.viewUrl(':section', ':id', ':tabId', ':childId'),
    URL.viewUrl(':section', ':id', ':tabId'),
  ];

  static defaultTab = 'default';

  static replaceTab = (path: string, tab: string) => {
    return `${path.split('tab')[0]}tab/${tab.toString()}`;
  };

  static signIn = () => URL.sectionUrl('sign-in');

  static changePwd = () => URL.sectionUrl('change-pwd');

  static home = () => URL.partnerSection();

  static partnerSection: TListUrl = () => URL.sectionUrl('partner');

  static clientSection: TListUrl = () => URL.sectionUrl('client');

  static accountSection: TListUrl = () => URL.sectionUrl('account');

  static partnerList: TListUrl = () => URL.listUrl('partner');

  static partnerAdd: TAddUrl = () => URL.addUrl('partner');

  static partnerView: TViewUrl = (id?: number, tab?: string, childId?: number) =>
    URL.viewUrl('partner', id, tab, childId);

  static programSection: TListUrl = () => URL.sectionUrl('program');

  static programList: TListUrl = () => URL.listUrl('program');

  static programAdd: TAddUrl = () => URL.addUrl('program');

  static programView: TViewUrl = (id?: number, tab?: string, childId?: number) =>
    URL.viewUrl('program', id, tab, childId);

  static userSection: TListUrl = () => URL.sectionUrl('user');

  static userList: TListUrl = () => URL.listUrl('user');

  static userAdd: TAddUrl = () => URL.addUrl('user');

  static userView: TViewUrl = (id?: number, tab?: string) => URL.viewUrl('user', id, tab);

  static tagView: TViewUrl = (id?: number, tab?: string) => URL.viewUrl('tag', id, tab);

  static backdoorView = () => URL.sectionUrl('backdoor');

  static offerSection: TListUrl = () => URL.sectionUrl('offer');

  static offerList: TListUrl = () => URL.listUrl('offer');

  static sectionList: TListUrl = () => URL.listUrl('section');

  static roleAccessList: TListUrl = () => URL.listUrl('role');

  static offerView: TViewUrl = (id?: number, tab?: string, childId?: number) => URL.viewUrl('offer', id, tab, childId);

  static sectionView: TViewUrl = (id?: number, tab?: string, childId?: number) =>
    URL.viewUrl('section', id, tab, childId);

  static roleAccessView: TViewUrl = (id?: number, tab?: string, childId?: number) =>
    URL.viewUrl('role', id, tab, childId);

  static actionView: TViewUrl = (id?: number, tab?: string, childId?: number) =>
    URL.viewUrl('action', id, tab, childId);

  static actionList: TListUrl = () => URL.listUrl('action');

  static bonusView = () => URL.sectionUrl('bonus');

  static optionSection: TListUrl = () => URL.sectionUrl('option');

  static optionList: TListUrl = () => URL.listUrl('option');

  static optionAdd: TAddUrl = () => URL.addUrl('option');

  static optionView: TViewUrl = (id?: number, tab?: string) => URL.viewUrl('option', id, tab);

  static tagList: TListUrl = () => URL.listUrl('tag');

  static tagAdd: TListUrl = () => URL.addUrl('tag');

  static bonusRegistrySection: TListUrl = () => URL.sectionUrl('bonus-registry');

  static bonusRegistryList: TListUrl = () => URL.listUrl('bonus-registry');

  static bonusRegistryAdd: TAddUrl = () => URL.addUrl('bonus-registry');

  static bonusRegistryError: TViewUrl = (id: any) => URL.viewUrl('bonus-registry', id);

  static cashbackActionSection: TListUrl = () => URL.sectionUrl('cashback-action');

  static cashbackActionList: TListUrl = () => URL.listUrl('cashback-action');

  static cashbackActionAdd: TAddUrl = () => URL.addUrl('cashback-action');

  static reportSection: TListUrl = () => URL.sectionUrl('report');
}
