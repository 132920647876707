import {
  createUseEntityList,
  createUseEntity,
  createUseEntityAdd,
  createUseEntityUpdate,
  createUseEntityDelete,
} from 'api/base-hooks';
import { partnerWidgetApi } from './widget';
import { PartnerWidget, Widget } from './widget-types';

const SECTION = 'partner-widget';

export const createUsePartnerWidgetList = (partnerId: number) =>
  createUseEntityList<PartnerWidget[]>(`${SECTION}:${partnerId}`, () =>
    partnerWidgetApi.getWidgetsByPartner(partnerId)
  );

export const createUseAllWidgetList = () =>
  createUseEntityList<Widget[]>(`${SECTION}:all-widgets`, () => partnerWidgetApi.getAllWidgets());

export const createUseWidget = (partnerId: number) =>
  createUseEntity<PartnerWidget>(`${SECTION}:${partnerId}`, (widgetId: number) =>
    partnerWidgetApi.getItem(partnerId, widgetId)
  );

export const createUseWidgetAdd = (partnerId: number) =>
  createUseEntityAdd<PartnerWidget>(`${SECTION}:${partnerId}`, (data) => partnerWidgetApi.addItem(partnerId, data));

export const createUseWidgetUpdate = (partnerId: number) =>
  createUseEntityUpdate<PartnerWidget>(`${SECTION}:${partnerId}`, (widgetId, data) =>
    partnerWidgetApi.editItem(partnerId, widgetId, data)
  );

export const createUseWidgetDelete = (partnerId: number) =>
  createUseEntityDelete<number>(`${SECTION}:${partnerId}`, (widgetId) =>
    partnerWidgetApi.deleteItem(partnerId, widgetId)
  );
