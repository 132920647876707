import styled from 'styled-components';

export const FormWrapper = styled.div`
  padding: 10px;
  display: flex;
  form {
    width: 100%;
  }
`;

export const InputWrapper = styled.div`
  margin: 10px 10px 10px 0;
  width: 250px;
`;

export const Column = styled.div`
  width: 50%;
  &:first-child {
    padding-right: 10px;
  }
`;

export const Section = styled.section`
  height: calc(100% - 60px);
  overflow-y: auto;
`;

export const LoaderWrapper = styled.div`
  height: 100%;
  width: 100%;
`;
