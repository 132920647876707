import React from 'react';
import { FormGroup, NumericInput, NumericInputProps } from '@blueprintjs/core';
import { Field, FieldProps, useFormikContext } from 'formik';
import { FormikFieldProps } from './utils';

export const NumericInputField = (props: FormikFieldProps & NumericInputProps) => {
  const { name, label, inline } = props;
  const context = useFormikContext();
  return (
    <Field name={name}>
      {({ field, meta }: FieldProps) => {
        // eslint-disable-next-line no-shadow
        const { name, value } = field;
        const handleValueChange = (valueAsNumber: number, valueAsString: string) => {
          const value = valueAsString !== '' ? valueAsNumber : null;
          context.setFieldValue(name, value);
        };
        return (
          <FormGroup inline={inline} label={label} labelFor={name} labelInfo={meta.touched && meta.error}>
            <NumericInput {...props} name={name} value={value} onValueChange={handleValueChange} />
          </FormGroup>
        );
      }}
    </Field>
  );
};
