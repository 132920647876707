import React from 'react';
import { SectionLayout } from 'components/ui/layouts/section-layout';
import { PartnersTable } from './partners-table';

export const PartnersPage = () => {
  return (
    <SectionLayout
      route="/partner"
      tabs={[{ caption: 'Партнёры', component: <PartnersTable paramName="partnerId" /> }]}
    />
  );
};
