import React from 'react';
import { restrictionsSections } from '@whitelabel/common/dist/api';
import { RestrictionsCommonProps } from './types';
import { PartnerRestrictionsSection } from './partner-restrictions-section';

export function PartnerRestrictions({ values, initialValues }: RestrictionsCommonProps) {
  return (
    <>
      {restrictionsSections.map((section) => (
        <PartnerRestrictionsSection key={section} section={section} values={values} initialValues={initialValues} />
      ))}
    </>
  );
}
