import React from 'react';
import { useParams } from 'react-router-dom';
import {
  createUseProduct,
  createUseProductAdd,
  createUseProductUpdate,
  createUseProductDelete,
} from 'api/product-hooks';
import { EntityForm } from 'components/ui/layouts/entity-form';
import { Tab } from 'components/ui/layouts/tabs-router/tabs-router-types';
import { Product } from 'api/product-types';
import { TabsRouter } from 'components/ui/layouts/tabs-router';
import { PartnerProductForm } from './partner-product-form';
import { ProductProgramsTable } from '../product-program/product-programs-table';

export const PartnerProductPage = ({ paramName }: { paramName: string }) => {
  const { partnerId, productId } = useParams<{ [key: string]: string }>();
  const useProduct = createUseProduct(Number(partnerId));
  const useProductAdd = createUseProductAdd(Number(partnerId));
  const useProductUpdate = createUseProductUpdate(Number(partnerId));
  const useProductDelete = createUseProductDelete(Number(partnerId));
  const tabs: Tab[] = [
    {
      caption: 'Карточка',
      component: (
        <EntityForm<Product>
          paramName={paramName}
          listUrl={`/partner/${partnerId}/product`}
          getEntityText={(data) => data?.components?.name}
          component={PartnerProductForm}
          useEntity={useProduct}
          useEntityAdd={useProductAdd}
          useEntityMutate={useProductUpdate}
          useEntityDelete={useProductDelete}
        />
      ),
    },
  ];
  if (productId !== 'add') {
    tabs.push({
      path: '/product-program',
      caption: 'Программы',
      component: <ProductProgramsTable paramName="productProgramId" />,
    });
  }

  return <TabsRouter route={`/partner/${partnerId}/product/${productId}`} tabs={tabs} />;
};
