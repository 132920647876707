import { EntityTable, EntityTableColumn } from '../../ui/skeletons/entity-table';
import React from 'react';
import { Cell } from 'react-table';
import { ReportType, mapReportTypeName, ReportTypeEnum } from './api/report-types';
import { useReportTypes } from './api/report-hooks';
import * as S from './index.styles';

export const ReportTypesTable = () => {
  const columns: EntityTableColumn<ReportType>[] = React.useMemo(() => {
    return [
      {
        Header: 'Тип',
        accessor: 'type',
        id: 'type',
        Cell: (cell: Cell) => mapReportTypeName[cell.value as ReportTypeEnum] || cell.value,
      },
    ];
  }, []);

  return (
    <S.Section>
      <EntityTable<ReportType & { id?: number }>
        useEntityList={useReportTypes}
        columns={columns}
        initialSortBy="type"
        desc
        rowLinkPrefix="/report"
        rowLinkIdProp="type"
      />
    </S.Section>
  );
};
