import { BaseApi } from 'api/base';
import * as _ from 'lodash';
import * as Types from './modal-types';
import { FormObject } from '../utils';

class ModalApi extends BaseApi {
  getList = async (partnerId: number): Promise<Types.ModalsResponse> => {
    const result = await this.fetch<Types.ModalsResponse>(`/modal/${partnerId}`);
    return result;
  };

  getItem = async (partnerId: number, modalId: number): Promise<Types.ModalResponse> => {
    const result = await this.fetch<Types.ModalResponse>(`/modal/${partnerId}/${modalId}`);
    return result;
  };

  addItem = async (partnerId: number, data: Types.Modal): Promise<Types.Modal> => {
    const formObject = FormObject(data);
    const formData = new FormData();

    _.chain(formObject)
      .omitBy(_.isNil)
      .forEach((value, key) => formData.append(key, value))
      .value();

    const result = await this.fetch<Types.ModalResponse>(`/modal/${partnerId}`, {
      method: 'post',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    });
    return result;
  };

  editItem = async (partnerId: number, modalId: number, data: Types.Modal): Promise<Types.Modal> => {
    const formObject = FormObject(data);
    const formData = new FormData();

    _.chain(formObject)
      .omitBy(_.isUndefined)
      .forEach((value, key) => formData.append(key, value))
      .value();

    const result = await this.fetch<Types.ModalResponse>(`/modal/${partnerId}/${modalId}`, {
      method: 'put',
      headers: { 'Content-Type': 'multipart/form-data' },
      data: formData,
    });
    return result;
  };

  deleteItem = async (partnerId: number, modalId: number): Promise<number> => {
    await this.fetch<void>(`/modal/${partnerId}/${modalId}`, { method: 'delete' });
    return modalId;
  };
}

export const modalApi = new ModalApi();
