import React from 'react';

interface IconProps {
  children: React.ReactNode;
  size?: number;
  color?: string;
  strokeWidth?: number;
}

export function IconBase({ children, size = 24, color = '#000', strokeWidth = 1.5 }: IconProps) {
  return (
    <svg
      width={size}
      height={size}
      stroke={color}
      strokeWidth={strokeWidth}
      fill="none"
      viewBox="0 0 24 24"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      {children}
    </svg>
  );
}
