import { Action, createReducer } from 'typesafe-actions';
import { addBreadCrumb, addBreadCrumbs, removeBreadCrumb, removeBreadCrumbs } from 'actions';
import { BreadCrumbItem } from '../components/ui/layouts/section-layout/section-layout-types';

export interface BreadCrumbsState {
  readonly items: BreadCrumbItem[];
}

const initialState: BreadCrumbsState = {
  items: [] as BreadCrumbItem[],
};

export default createReducer<BreadCrumbsState, Action>(initialState)
  .handleAction(addBreadCrumb, (state, { payload }) => {
    const checkItem = state.items.find((item) => item.path === payload.path);
    if (!checkItem) {
      return { ...state, items: [...state.items, payload] };
    }
    if (!checkItem.caption && payload.caption) {
      return { ...state, items: state.items.map((item) => (item.path === payload.path ? payload : item)) };
    }
    return state;
  })
  .handleAction(addBreadCrumbs, (state, { payload }) => {
    if (payload.length === state.items.length) {
      return state;
    }
    if (payload.length < state.items.length) {
      return {
        ...state,
        items: state.items.filter((item) => payload.find((payloadItem) => item.path === payloadItem.path)),
      };
    }
    const items: BreadCrumbItem[] = [];
    payload.forEach((breadCrumb) => {
      if (!state.items.find((item) => item.path === breadCrumb.path)) {
        items.push(breadCrumb);
      }
    });
    return { ...state, items: [...state.items, ...items] };
  })
  .handleAction(removeBreadCrumb, (state, { payload }) => {
    return { ...state, items: state.items.filter((item) => item.path !== payload.path) };
  })
  .handleAction(removeBreadCrumbs, (state, { payload }) => {
    return { ...state, items: state.items.filter((item) => !payload.includes(item.path)) };
  });
