import * as Yup from 'yup';
import { errorMessages } from 'components/ui/formik-controls';

export const PartnerSchema = Yup.object().shape({
  mnemocode: Yup.string().matches(/^\d+$/, errorMessages.onlyDigits).required(errorMessages.required),
  isActive: Yup.boolean(),
  apiKey: Yup.string().required(errorMessages.required),
  domainName: Yup.string().required(errorMessages.required),
  requestSignatureKey: Yup.string(),
  restrictionConversionFromDateTime: Yup.date()
    .nullable()
    .when('restrictionConversion', {
      is: true,
      then: Yup.date().required(errorMessages.required),
    }),
  restrictionConversionToDateTime: Yup.date()
    .nullable()
    .when('restrictionConversion', {
      is: true,
      then: Yup.date()
        .required(errorMessages.required)
        .min(Yup.ref('restrictionConversionFromDateTime'), errorMessages.invalidDate),
    }),
  restrictionPrecalcFromDateTime: Yup.date()
    .nullable()
    .when('restrictionPrecalc', {
      is: true,
      then: Yup.date().required(errorMessages.required),
    }),
  restrictionPrecalcToDateTime: Yup.date()
    .nullable()
    .when('restrictionPrecalc', {
      is: true,
      then: Yup.date()
        .required(errorMessages.required)
        .min(Yup.ref('restrictionPrecalcFromDateTime'), errorMessages.invalidDate),
    }),
  restrictionOverallFromDateTime: Yup.date()
    .nullable()
    .when('restrictionOverall', {
      is: true,
      then: Yup.date().required(errorMessages.required),
    }),
  restrictionOverallToDateTime: Yup.date()
    .nullable()
    .when('restrictionOverall', {
      is: true,
      then: Yup.date()
        .required(errorMessages.required)
        .min(Yup.ref('restrictionOverallFromDateTime'), errorMessages.invalidDate),
    }),
  components: Yup.object().shape({
    name: Yup.string(),
    banner: Yup.string(),
    currencyName: Yup.string(),
    description: Yup.string(),
  }),
});
