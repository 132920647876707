import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Cell } from 'react-table';
import { Alert, Button, ButtonGroup, Intent } from '@blueprintjs/core';
import { EntityTable, EntityTableColumn } from '../../../ui/skeletons/entity-table';
import { CashbackActionMerchant } from '../api/cashback-action-types';
import * as TS from '../cashback-action-table.styles';
import * as S from '../index.styles';
import { useCashbackActionMerchantList } from '../api/cashback-action-hooks';
import { cashbackActionApi } from '../api/cashback-action';
import { AppToaster } from '../../../ui/toaster';

export interface CashbackActionMerchantTableProps {
  id: number;
}

export const CashbackActionMerchantTable = (props: CashbackActionMerchantTableProps) => {
  const { id } = props;

  const history = useHistory();
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [reload, setReload] = useState<number>(Date.now());

  const handleOnRowClick = (item: any) => {
    history.push(`/cashback-action/${id}/merchant/${item.id}`);
  };

  const handleDeleteCancel = () => setDeleteId(null);
  const handleDeleteConfirm = async () => {
    if (deleteId !== null) {
      cashbackActionApi
        .deleteMerchantItem(id, deleteId)
        .then(() => {
          setReload(Date.now());
          AppToaster.show({ message: `Мерчант успешно удален`, intent: 'success' });
        })
        .catch(() => AppToaster.show({ message: `Мерчант НЕ удален`, intent: 'danger' }))
        .finally(() => setDeleteId(null));
    }
  };

  const columns: EntityTableColumn<CashbackActionMerchant>[] = React.useMemo(() => {
    return [
      {
        Header: 'MerchantId',
        accessor: 'id',
        id: 'id',
        Cell: (cell: Cell) => <TS.ValueWrapper title={cell.value}>{cell.value}</TS.ValueWrapper>,
      },
      {
        Header: 'MCC',
        accessor: 'mcc',
        id: 'mcc',
        Cell: (cell: Cell) => <TS.ValueWrapper title={cell.value}>{cell.value}</TS.ValueWrapper>,
      },
      {
        Header: 'MerchantId',
        accessor: 'merchantId',
        id: 'merchantId',
        Cell: (cell: Cell) => <TS.ValueWrapper title={cell.value}>{cell.value}</TS.ValueWrapper>,
      },
      {
        Header: 'TerminalId',
        accessor: 'terminalId',
        id: 'terminalId',
        Cell: (cell: Cell) => <TS.ValueWrapper title={cell.value}>{cell.value}</TS.ValueWrapper>,
      },
      {
        Header: 'CardAcceptorName',
        accessor: 'cardAcceptorName',
        id: 'cardAcceptorName',
        Cell: (cell: Cell) => <TS.ValueWrapper title={cell.value}>{cell.value}</TS.ValueWrapper>,
      },
      {
        Header: 'Действия',
        width: 100,
        Cell: (cell: Cell) => (
          <ButtonGroup vertical>
            <Button
              intent="danger"
              disabled={false}
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                setDeleteId(cell.row.values.id);
                event.stopPropagation();
              }}
            >
              Удалить
            </Button>
          </ButtonGroup>
        ),
      },
      {
        Header: ' ',
        width: 10,
      },
    ];
  }, []);

  return (
    <S.Section>
      <Alert
        isOpen={deleteId !== null}
        icon="trash"
        intent={Intent.DANGER}
        cancelButtonText="Отмена"
        confirmButtonText="Удалить"
        onCancel={handleDeleteCancel}
        onConfirm={handleDeleteConfirm}
      >
        <h4>Подтвердите удаление Мерчанта!</h4>
      </Alert>
      <EntityTable<CashbackActionMerchant>
        params={[id]}
        key={reload}
        useEntityList={useCashbackActionMerchantList}
        columns={columns}
        initialSortBy="id"
        desc
        onRowClick={handleOnRowClick}
      />
    </S.Section>
  );
};
