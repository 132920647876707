import React, { useEffect, useState } from 'react';
import * as S from './role-settings-form.styles';
import { Role } from '../../../../utils/types';
import { Button, InputGroup } from '@blueprintjs/core';
import * as moment from 'moment';
import { baseApi } from '../../../../api/base';
import { AppToaster } from '../../../ui/toaster';
import { IconNames } from '@blueprintjs/icons';
import { Loader } from '../../../ui/shared/loader';
import { RoleSettings } from './api/role-settings-types';

interface Duration {
  amount: string;
  unit: moment.unitOfTime.DurationConstructor;
}

const durationPattern = /^(?<amount>[1-9]\d*)(?<unit>(h|d|w|M|y))?/;
const amountPattern = /^[1-9]\d*$/;

const durationParse = (value: string, defaults: Partial<Duration>): Duration =>
  Object.assign(defaults, value?.match(durationPattern)?.groups || {}) as Duration;

const units = {
  h: 'часы',
  d: 'дни',
  w: 'недели',
  M: 'месяцы',
  y: 'года',
};

export const RoleSettingsForm = ({ role }: { role: Role }) => {
  const [isSaving, setIsSaving] = useState(false);
  const [passwordExpiresAmount, setPasswordExpiresAmount] = useState('');
  const [passwordExpiresUnit, setPasswordExpiresUnit] = useState('d');

  useEffect(() => {
    const fetch = async () => {
      const data = await baseApi.fetch<any>(`/role/settings/${role}`).catch(() => undefined);
      const { amount = '', unit = 'd' } = durationParse(data?.passwordExpires || '', { amount: '', unit: 'd' });
      setPasswordExpiresAmount(amount);
      setPasswordExpiresUnit(unit);
    };
    fetch();
  }, [role]);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      const passwordExpires = passwordExpiresAmount ? [passwordExpiresAmount, passwordExpiresUnit].join('') : null;
      const result = await baseApi.fetch<RoleSettings>(`/role/settings/${role}`, {
        method: 'post',
        data: { passwordExpires },
      });
      const { amount = '', unit = 'd' } = durationParse(result?.passwordExpires || '', { amount: '', unit: 'd' });
      setPasswordExpiresAmount(amount);
      setPasswordExpiresUnit(unit);
      AppToaster.show({ message: `Настройки сохранены`, intent: 'success' });
    } catch (err) {
      AppToaster.show({ message: `Настройки НЕ сохранены`, intent: 'danger' });
    } finally {
      setIsSaving(false);
    }
  };

  const handleChangePasswordExpiresAmount = (event: any) => {
    if (!event?.target?.value || amountPattern.test(event?.target?.value)) {
      setPasswordExpiresAmount(event?.target?.value);
    }
  };

  const handleChangePasswordExpiresUnit = (event: any) => {
    setPasswordExpiresUnit(event?.target?.value);
  };

  const unitMenu = (
    <div className="bp3-html-select bp3-minimal">
      <select onChange={handleChangePasswordExpiresUnit} value={passwordExpiresUnit}>
        {Object.entries(units).map(([key, val]) => (
          <option value={key}>{val}</option>
        ))}
      </select>
      <span className="bp3-icon bp3-icon-double-caret-vertical"></span>
    </div>
  );

  return (
    <div>
      <S.InputWrapper>
        <div>Срок действия пароля</div>
        <InputGroup
          placeholder="не ограничен"
          rightElement={unitMenu}
          value={passwordExpiresAmount}
          onChange={handleChangePasswordExpiresAmount}
        />
      </S.InputWrapper>
      {isSaving ? (
        <S.InputWrapper>
          <Loader />
        </S.InputWrapper>
      ) : (
        <S.InputWrapper>
          <Button onClick={() => handleSave()} text="Сохранить" intent="primary" icon={IconNames.SAVED} />
        </S.InputWrapper>
      )}
    </div>
  );
};
