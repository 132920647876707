import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { URL } from '../../../utils/url-builder';
import { IconNames } from '@blueprintjs/icons';
import * as S from '../cashback-action/index.styles';
import { ReportTypesPage } from './report-types-page';
import { ReportPage } from './report-page';
import { mapReportTypeName, ReportTypeEnum } from './api/report-types';
import { BreadcrumbRouteProps, BreadcrumbsRoute, OnMatch } from '../../ui/breadcrumbs-route';

const routes: BreadcrumbRouteProps[] = [
  { path: `${URL.reportSection()}`, icon: IconNames.LIST_DETAIL_VIEW, text: 'Отчеты' },
  { path: `${URL.reportSection()}/:type`, icon: IconNames.DOCUMENT },
];

export const ReportRouter = () => {
  const onMatch: OnMatch<{ type?: ReportTypeEnum }> = (match) => {
    return match?.params?.type ? { text: mapReportTypeName[match?.params?.type] || match?.params?.type } : undefined;
  };

  return (
    <S.Section>
      <S.Header>
        <BreadcrumbsRoute routes={routes} onMatch={onMatch} />
      </S.Header>
      <S.Nav></S.Nav>
      <Switch>
        <Route path={`${URL.reportSection()}`} exact component={ReportTypesPage} />
        <Route path={`${URL.reportSection()}/:type`} component={ReportPage} />
      </Switch>
    </S.Section>
  );
};
