import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import * as _ from 'lodash';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import {
  DateInputField,
  InputField,
  MultiSelectField,
  MultiSelectFieldItem,
  NumericInputField,
  SelectField,
  StaticSelectField,
  SumbitButton,
  SwitchField,
  TagInputField,
  TextAreaField,
} from 'components/ui/formik-controls';
import { CombinationsPeriodEnum, Modal, OptionsPeriodEnum, PositionEnum } from 'api/modal-types';
import { PartnerModalSchema } from './partner-modal-schema';
import * as S from './partner-modal-form.styles';
import { createUseProductList } from '../../../../api/product-hooks';
import { createUseProgramList } from '../../../../api/program-hooks';
import { createUseOptionList } from '../../../../api/option-hooks';
import { API_URL_BASE_PATH } from '../../../../api/base';
import { ChooseIconHandler } from '../components';

/* eslint-disable @typescript-eslint/camelcase */
const emptyData = {
  isActive: false,
  title: undefined,
  position: undefined,
  description: undefined,
  icon: null,
  button: undefined,
  buttonLink: undefined,
  clients: undefined,
  products: undefined,
  combinations: undefined,
  combinationsPeriod: undefined,
  options: undefined,
  optionsPeriod: undefined,
  path: undefined,
  appearOnEnter: false,
  appearancesCount: 0,
  dateStart: undefined,
  dateEnd: undefined,
  timer: undefined,
  image: undefined,
  order: undefined,
};

type AfterSubmitHandler = (data?: Modal) => void;
type ModalFormProps = {
  initialValues?: Modal;
  onSubmit: (modal: Modal) => Promise<Modal | undefined>;
  onAfterSubmit?: AfterSubmitHandler;
};
export const PartnerModalForm = (props: ModalFormProps) => {
  const { partnerId } = useParams<{ [key: string]: string }>();
  const { initialValues = emptyData, onSubmit, onAfterSubmit } = props;

  const data = { ...emptyData, ...initialValues };

  const positionOptions = Object.keys(PositionEnum).map((key) => ({ value: key, caption: key }));

  const useProductList = createUseProductList(Number(partnerId));

  const productsItems: MultiSelectFieldItem<string>[] = _.chain(useProductList())
    .get('data')
    .map((product) => ({ value: product.product_class || '', caption: product.product_class || '' }))
    .value();

  const useProgramList = createUseProgramList(Number(partnerId));

  const combinationsItems: MultiSelectFieldItem<string>[] = _.chain(useProgramList())
    .get('data')
    .map((program) => ({ value: program.productClass || '', caption: program.productClass || '' }))
    .value();

  const useOptionList = createUseOptionList(Number(partnerId));

  const optionsItems: MultiSelectFieldItem<string>[] = _.chain(useOptionList())
    .get('data')
    .map((option) => ({ value: option.mnemocode, caption: option.mnemocode }))
    .value();

  const combinationsPeriodItems: MultiSelectFieldItem<string>[] = Object.keys(CombinationsPeriodEnum).map((key) => ({
    value: key,
    caption: key,
  }));

  const optionsPeriodItems: MultiSelectFieldItem<string>[] = Object.keys(OptionsPeriodEnum).map((key) => ({
    value: key,
    caption: key,
  }));

  const handleSubmit = async (modal: Modal, { setSubmitting }: FormikHelpers<Modal>) => {
    const result = await onSubmit(modal);
    setSubmitting(false);
    if (!result) {
      return;
    }
    if (onAfterSubmit) {
      onAfterSubmit();
    }
  };
  return (
    <Formik<Modal>
      enableReinitialize
      initialValues={data as Modal}
      validationSchema={PartnerModalSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, values }) => (
        <Form>
          <S.FormWrapper>
            <S.Column>
              <SwitchField name="isActive" label="Is active" />
              <InputField name="title" label="Title" />
              <SelectField
                options={positionOptions}
                name="position"
                label="Position"
                filterable={false}
                placeholder=" "
              />
              <NumericInputField
                disabled={values.position !== PositionEnum.HOME_BANNER}
                name="order"
                label="Order"
                fill
              />
              <TextAreaField name="description" label="Description" rows={6} resize="none" />
              <InputField name="button" label="Button" />
              <InputField name="buttonLink" label="Button link" />
              <InputField name="path" label="Path" />
              <ChooseIconHandler currentIcon={values.icon} initialIcon={data.icon} />
            </S.Column>
            <S.Column>
              <SwitchField name="appearOnEnter" label="Appear on enter" />
              <NumericInputField name="appearancesCount" label="Appearances count" fill />
              <TagInputField<number> name="clients" label="Clients" fill inputProps={{ pattern: '^\\d+$' }} />
              <MultiSelectField<string>
                name="products"
                label="Products"
                items={productsItems}
                placeholder=""
                popoverProps={{ boundary: 'viewport', position: 'right', popoverClassName: 'scrollable' }}
              />
              <MultiSelectField<string>
                name="combinations"
                label="Combinations"
                items={combinationsItems}
                placeholder=""
                popoverProps={{ boundary: 'viewport', position: 'right', popoverClassName: 'scrollable' }}
              />
              <MultiSelectField<string>
                name="combinationsPeriod"
                label="Combinations period"
                items={combinationsPeriodItems}
                placeholder=""
              />
              <MultiSelectField<string>
                name="options"
                label="Options"
                items={optionsItems}
                placeholder=""
                popoverProps={{ boundary: 'viewport', position: 'right', popoverClassName: 'scrollable' }}
              />
              <MultiSelectField<string>
                name="optionsPeriod"
                label="Options period"
                items={optionsPeriodItems}
                placeholder=""
              />
              <DateInputField
                name="dateStart"
                label="Date start"
                placeholder="YYYY-MM-DDTHH:mm"
                formatDate={(date) => moment(date).format('YYYY-MM-DDTHH:mm')}
                parseDate={(str) => moment(str, 'YYYY-MM-DDTHH:mm').toDate()}
                timePrecision="minute"
                fill
              />
              <DateInputField
                name="dateEnd"
                label="Date end"
                placeholder="YYYY-MM-DDTHH:mm"
                formatDate={(date) => moment(date).format('YYYY-MM-DDTHH:mm')}
                parseDate={(str) => moment(str, 'YYYY-MM-DDTHH:mm').toDate()}
                timePrecision="minute"
                fill
              />
              <NumericInputField name="timer" label="Timer" fill />
              <StaticSelectField url={`${API_URL_BASE_PATH}/yandex/object-storage`} name="image" label="Image" />
            </S.Column>
          </S.FormWrapper>
          <SumbitButton isSubmitting={isSubmitting} />
        </Form>
      )}
    </Formik>
  );
};
