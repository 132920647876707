import { BaseApi } from 'api/base';
import * as Types from './action-types';

class ActionApi extends BaseApi {
  getList = async (): Promise<Types.ActionListResponse> => {
    const result = await this.fetch<Types.ActionListResponse>(`/action`);
    return result;
  };

  getItem = async (id: number): Promise<Types.ActionResponse> => {
    const result = await this.fetch<Types.ActionResponse>(`/action/${id}`);
    return result;
  };
}

export const actionApi = new ActionApi();
