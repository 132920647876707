import React from 'react';
import * as S from './hooks.styles';
import * as Yup from 'yup';
import {
  DateInputField,
  errorMessages,
  MultiSelectField,
  MultiSelectFieldItem,
  SelectField,
} from '../../../ui/formik-controls';
import { FormFieldsFunction } from './form-fields';
import { CloInterface } from '../api/report-types';
import * as _ from 'lodash';
import { usePartnerList } from '../../../../api/partner-hooks';
import moment from 'moment';
import { useReportGoalTypes } from '../api/report-hooks';

const state: Partial<CloInterface> = {
  partnerMnemocode: undefined,
  period: undefined,
  goalTypes: [],
  minCreatedWhen: undefined,
};

const schema: Yup.Schema<object | undefined> = Yup.object().shape({
  partnerMnemocode: Yup.string().required(errorMessages.required),
  period: Yup.string().required(errorMessages.required),
  goalTypes: Yup.array()
    .of(Yup.number())
    .required(errorMessages.required),
  minCreatedWhen: Yup.string().optional(),
});

export const useClo: FormFieldsFunction<Partial<CloInterface>> = () => {
  const partnerMnemocodeOptions = _.chain(usePartnerList())
    .get('data')
    .map((partner) => ({ value: partner?.mnemocode, caption: `${partner?.mnemocode} - ${partner?.components?.name}` }))
    .value();

  const goalTypesOptions: MultiSelectFieldItem<number>[] = _.chain(useReportGoalTypes())
    .get('data')
    .map((code, goalType) => ({
      value: code,
      caption: `${code} - ${goalType}`,
    }))
    .value();

  function render() {
    return (
      <S.FormWrapper>
        <S.Column>
          <SelectField
            options={partnerMnemocodeOptions}
            name="partnerMnemocode"
            label="Партнер"
            filterable={false}
            placeholder="Выберите партнера"
          />
          <MultiSelectField<number>
            name="goalTypes"
            label="Типы целей"
            items={goalTypesOptions}
            placeholder="Выберите типы целей"
            popoverProps={{ boundary: 'viewport', position: 'bottom' }}
          />
        </S.Column>
        <S.Column>
          <DateInputField
            name="period"
            label="Период"
            placeholder="YYYY-MM"
            formatDate={(date) => moment(date).format('YYYY-MM')}
            parseDate={(str) => moment(str, 'YYYY-MM').toDate()}
          />
          <DateInputField
            name="minCreatedWhen"
            label="Минимальное время создания"
            placeholder="YYYY-MM-DDTHH:mm:ss"
            formatDate={(date) => moment(date).format('YYYY-MM-DDTHH:mm:ss')}
            parseDate={(str) => moment(str, 'YYYY-MM-DDTHH:mm:ss').toDate()}
            timePrecision="second"
          />
        </S.Column>
      </S.FormWrapper>
    );
  }

  return {
    state,
    schema,
    render,
  };
};
