import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, InputGroup } from '@blueprintjs/core';
import { userSelector } from 'selectors/auth';
import { Loader } from 'components/ui/shared/loader';
import { Table } from 'components/ui/table';
import { useHistory } from 'react-router-dom';
import { baseApi } from 'api/base';
import { Client } from './api/client-types';

export const ClientsTable = () => {
  const history = useHistory();
  const [externalId, setExternalId] = useState('');
  const [phone, setPhone] = useState('');
  const [cardExternalId, setCardExternalId] = useState('');
  const [mnemocode, setMnemocode] = useState('');
  const [id, setId] = useState('');
  const [searching, setSearching] = useState(false);
  const [data, setData] = useState<Client[] | null>(null);
  const user = useSelector(userSelector);
  const handleSearchButtonClick = async () => {
    if (!phone && !externalId && !cardExternalId && !mnemocode && !id) {
      return;
    }
    setSearching(true);
    const params = {} as {
      phone: string;
      externalId: string;
      rootProfileId: number;
      cardExternalId: string;
      mnemocode: string;
      id: string;
    };
    if (phone) {
      params.phone = phone;
    }
    if (externalId) {
      params.externalId = externalId;
    }
    if (cardExternalId) {
      params.cardExternalId = cardExternalId;
    }
    if (mnemocode) {
      params.mnemocode = mnemocode;
    }
    if (id) {
      params.id = id;
    }
    params.rootProfileId = user.partnerRole;
    const response = await baseApi.fetch<Client[]>('/client', { params });
    setSearching(false);
    if (Array.isArray(response)) {
      setData(response);
    } else if (data) {
      setData(null);
    }
  };
  return (
    <>
      <div style={{ padding: '0 1em 1em 1em' }}>
        <h2>Поиск клиента</h2>
        <div style={{ display: 'flex', flexFlow: 'column nowrap', width: 200, margin: '0 0 10px 0' }}>
          <span>External Id клиента</span>
          <InputGroup value={externalId} onChange={(e) => setExternalId(e.target.value.trim())} />
        </div>
        <div style={{ display: 'flex', flexFlow: 'column nowrap', width: 200, margin: '0 0 10px 0' }}>
          <span>Телефон</span>
          <InputGroup value={phone} onChange={(e) => setPhone(e.target.value.trim())} />
        </div>
        <div style={{ display: 'flex', flexFlow: 'column nowrap', width: 200, margin: '0 0 10px 0' }}>
          <span>External Id карты</span>
          <InputGroup value={cardExternalId} onChange={(e) => setCardExternalId(e.target.value.trim())} />
        </div>
        <div style={{ display: 'flex', flexFlow: 'column nowrap', width: 200, margin: '0 0 10px 0' }}>
          <span>Mnemocode</span>
          <InputGroup value={mnemocode} onChange={(e) => setMnemocode(e.target.value.trim())} />
        </div>
        <div style={{ display: 'flex', flexFlow: 'column nowrap', width: 200, margin: '0 0 10px 0' }}>
          <span>Id</span>
          <InputGroup value={id} onChange={(e) => setId(e.target.value.trim())} />
        </div>
        {searching ? (
          <div style={{ width: 200 }}>
            <Loader size={40} />
          </div>
        ) : (
          <Button icon="search" large intent="success" type="button" onClick={handleSearchButtonClick}>
            Искать
          </Button>
        )}
      </div>
      {data && data?.length > 0 && (
        <Table<any>
          columns={[
            {
              accessor: 'name',
              Header: 'ФИО',
            },
            {
              accessor: 'phone',
              Header: 'Телефон',
            },
          ]}
          data={data}
          onRowClick={(data) => history.push(`/client/${data.mnemocode}`)}
        />
      )}
      {data && data?.length === 0 && (
        <div>
          <h3 style={{ textAlign: 'center' }}>Клиент не найден</h3>
        </div>
      )}
    </>
  );
};
