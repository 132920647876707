import React from 'react';
import { IconNames } from '@blueprintjs/icons';
import { URL } from 'utils/url-builder';
import { EntityList } from 'components/ui/skeletons/entity-list';
import { UsersTable } from './users-table';

export const UserList = () => {
  return (
    <EntityList
      sectionText="Пользователи"
      icon={IconNames.USER}
      listUrl={URL.userList()}
      entityAddUrl={URL.userAdd()}
      content={<UsersTable />}
    />
  );
};
