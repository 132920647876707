import React from 'react';
import { IconNames } from '@blueprintjs/icons';
import { URL } from 'utils/url-builder';
import { EntityList } from 'components/ui/skeletons/entity-list';
import { ActionsTable } from './actions-table';

export const ActionList = () => {
  return (
    <EntityList
      sectionText="События"
      icon={IconNames.CALCULATOR}
      listUrl={URL.listUrl('actions')}
      content={<ActionsTable />}
    />
  );
};
