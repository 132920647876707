import styled from 'styled-components';

export const FormWrapper = styled.div`
  padding: 11px;
  /* height: 100%; */
  display: flex;

  form {
    width: 100%;
  }
`;

export const Column = styled.div`
  width: 50%;

  &:first-child {
    padding-right: 11px;
  }
`;
