import React from 'react';
import * as S from './index.styles';
import { CashbackActionEditForm } from './cashback-action-edit-form';

export const CashbackActionEditPage = () => {
  return (
    <S.Section>
      <S.Nav></S.Nav>
      <S.Section>
        <CashbackActionEditForm />
      </S.Section>
    </S.Section>
  );
};
