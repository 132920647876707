import React from 'react';
import * as S from './index.styles';
import { Form, Formik, FormikHelpers } from 'formik';
import { AppToaster } from '../../ui/toaster';
import { PostReportData, ReportTypeEnum } from './api/report-types';
import { useFormFileds } from './hooks/form-fields';
import { SumbitButton } from '../../ui/formik-controls';
import { reportApi } from './api/report';
import { Button } from '@blueprintjs/core';

export interface ReportFormProps {
  type: ReportTypeEnum;
  onSubmit?: () => void;
  onRefresh?: () => void;
}

export const ReportForm = (props: ReportFormProps) => {
  const { type, onSubmit, onRefresh } = props;
  const formFileds = useFormFileds(type);
  const { state, schema, render } = formFileds();

  const handleSubmit = async (values: PostReportData, { setSubmitting }: FormikHelpers<PostReportData>) => {
    try {
      await reportApi.postReport<PostReportData>(type, values);
      AppToaster.show({ message: `Отчет создается`, intent: 'success' });
    } catch (e) {
      AppToaster.show({ message: `Отчет НЕ создается`, intent: 'danger' });
    } finally {
      setSubmitting(false);
      if (onSubmit) {
        onSubmit();
      }
    }
  };

  return (
    <S.Section style={{ height: 'min-content' }}>
      <Formik<PostReportData>
        enableReinitialize
        initialValues={state as PostReportData}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            {render()}
            <span style={{ display: 'flex', justifyContent: 'space-between' }}>
              <SumbitButton isSubmitting={isSubmitting} text="Создать" />
              {onRefresh && (
                <div style={{ padding: '5px' }}>
                  <Button icon="refresh" intent="success" large onClick={onRefresh} />
                </div>
              )}
            </span>
          </Form>
        )}
      </Formik>
    </S.Section>
  );
};
