/* eslint-disable @typescript-eslint/camelcase */
import * as Yup from 'yup';
import { errorMessages } from 'components/ui/formik-controls';
import { AuthorizeType } from 'api/program-types';

export const PartnerProgramSchema = Yup.object().shape({
  mnemocode: Yup.string()
    .matches(/^\d+$/, errorMessages.onlyDigits)
    .required(errorMessages.required),

  currency: Yup.string().required(errorMessages.required),
  productClass: Yup.string().required(errorMessages.required),
  isActive: Yup.boolean(),

  components: Yup.object().shape({
    name: Yup.string().required(errorMessages.required),
    caption: Yup.string().required(errorMessages.required),

    hint: Yup.string().required(errorMessages.required),
    logo: Yup.string().required(errorMessages.required),
    icon: Yup.string(),
    placeholder: Yup.string().required(errorMessages.required),
    signup_notice: Yup.string().required(errorMessages.required),
    currency_names: Yup.string(),
    description: Yup.string(),
    howToGet: Yup.string(),
    howToUse: Yup.string(),
    howToParticipate: Yup.string(),
    banner: Yup.string(),
    entry_nr_input_mask: Yup.string().required(errorMessages.required),
    entry_nr_mask: Yup.string().required(errorMessages.required),
    canRegister: Yup.boolean(),
    canAuthorize: Yup.boolean(),
    isPartnerProgram: Yup.boolean(),

    authorizeType: Yup.mixed<AuthorizeType | null>()
      .oneOf([null, ...Object.values(AuthorizeType)])
      .nullable(),
    signup_result_button_text: Yup.string(),
    signup_result_message: Yup.string(),
    signup_registration_hint: Yup.string(),
    site_url: Yup.string(),
  }),
});
