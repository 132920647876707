import React, { useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Tab, Tabs } from '@blueprintjs/core';
import { IconName } from '@blueprintjs/icons';
import { Breadcrumbs, BreadcrumbItems } from 'components/ui/breadcrumbs';
import { PageSkeleton } from 'components/ui/page-skeleton';
import { BreadcrumbsContext, TabsContext } from 'components/ui/skeletons/entity';
import { URL } from 'utils/url-builder';

type EntityListProps = {
  sectionText?: string;
  icon?: IconName;
  listUrl?: string;
  entityAddUrl?: string;
  entityAddText?: string;
  content: JSX.Element;
};
export const EntityList = (props: EntityListProps) => {
  const breadcrumbs = useContext(BreadcrumbsContext);
  const tabs = useContext(TabsContext);
  const { section, id, tabId } = useParams<{ [key: string]: string }>();
  const { sectionText = 'список', icon, entityAddUrl, entityAddText, content } = props;
  const history = useHistory();
  const handleAdd = () => {
    // history.push(entityAddUrl || `${URL.viewUrl(section, id, tabId)}/add`);
    if (entityAddUrl) {
      history.push(entityAddUrl);
    }
  };
  const items: BreadcrumbItems = React.useMemo(
    () => breadcrumbs || [{ href: URL.viewUrl(section, id, tabId), icon, text: sectionText, current: true }],
    [breadcrumbs, section, id, tabId, icon, sectionText]
  );
  const handleTabChange = (tab: React.ReactText, prevTabId: string) => {
    const newPath = URL.viewUrl(section, id, tab.toString());
    history.push(newPath);
  };
  return (
    <BreadcrumbsContext.Provider value={breadcrumbs || items}>
      <PageSkeleton
        breadcrumbs={<Breadcrumbs items={breadcrumbs || items} />}
        actions={
          Boolean(entityAddUrl) && <Button intent="success" text={entityAddText || 'Добавить'} onClick={handleAdd} />
        }
        content={
          tabs ? (
            <Tabs large onChange={handleTabChange} selectedTabId={tabId}>
              {tabs.map(({ name, caption }) => (
                <Tab key={name} id={name} title={caption} panel={tabId === name ? content : <></>} />
              ))}
            </Tabs>
          ) : (
            content
          )
        }
      />
    </BreadcrumbsContext.Provider>
  );
};
