import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Form, Formik, FormikHelpers } from 'formik';
import { Alert, Button, ButtonGroup, Intent } from '@blueprintjs/core';
import * as _ from 'lodash';
import { CashbackActionMerchantUpdate } from '../api/cashback-action-types';
import { cashbackActionApi } from '../api/cashback-action';
import { AppToaster } from '../../../ui/toaster';
import { Loader } from '../../../ui/shared/loader';
import { InputField, SumbitButton } from '../../../ui/formik-controls';
import * as S from '../index.styles';
import * as FS from '../cashback-action-edit-form.styles';

const emptyData: CashbackActionMerchantUpdate = {
  mcc: undefined,
  merchantId: undefined,
  terminalId: undefined,
  cardAcceptorName: undefined,
} as any;

type EnabledFields = Partial<Record<keyof CashbackActionMerchantUpdate, boolean>>;

export const CashbackActionMerchantPage = () => {
  const { id, merchantId } = useParams();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [merchant, setMerchant] = useState<CashbackActionMerchantUpdate>();
  const [enabledFields, setEnabledFields] = useState<EnabledFields>({});
  const [enabledSubmit, setEnabledSubmit] = useState<boolean>(false);

  useEffect(() => {
    if (merchantId) {
      setIsLoading(true);
      cashbackActionApi
        .getMerchantItem(id, merchantId)
        .then((data) => {
          const merchantUpdate = _.pick(data, _.keys(emptyData)) as CashbackActionMerchantUpdate;
          setMerchant(merchantUpdate);
        })
        .catch(() => history.push(`/cashback-action/${id}/merchant`))
        .finally(() => setIsLoading(false));
    } else {
      setMerchant(emptyData);
    }
  }, [merchantId]);

  useEffect(() => {
    const updEnabledFields = merchantId ? _.mapValues(merchant, Boolean) : _.mapValues(merchant, _.stubTrue);
    setEnabledFields(updEnabledFields);

    const updEnabledSubmit = _.chain(merchant)
      .values()
      .map(Boolean)
      .some()
      .value();
    setEnabledSubmit(updEnabledSubmit);
  }, [merchant]);

  const handleSubmit = async (
    values: CashbackActionMerchantUpdate,
    { setSubmitting }: FormikHelpers<CashbackActionMerchantUpdate>
  ) => {
    try {
      await (merchantId
        ? cashbackActionApi.editMerchantItem(id, merchantId, values)
        : cashbackActionApi.addMerchantItem(id, values));

      AppToaster.show({ message: `Мерчант успешно сохранен`, intent: 'success' });
      history.push(`/cashback-action/${id}/merchant`);
    } catch (e) {
      AppToaster.show({ message: `Мерчант НЕ сохранен`, intent: 'danger' });
    } finally {
      setSubmitting(false);
    }
  };

  const handleValidate = (values: CashbackActionMerchantUpdate) => {
    const updEnableds = merchantId
      ? enabledFields
      : _.chain(values)
          .values()
          .map(Boolean)
          .every(_.partial(_.isEqual, false))
          .value()
      ? _.mapValues(merchant, _.stubTrue)
      : _.mapValues(values, Boolean);
    setEnabledFields(updEnableds);

    const updEnabledSubmit = _.chain(values)
      .values()
      .map(Boolean)
      .some()
      .value();
    setEnabledSubmit(updEnabledSubmit);
  };

  const handleDeleteCancel = () => setDeleteId(null);
  const handleDeleteConfirm = async () => {
    if (deleteId !== null) {
      cashbackActionApi
        .deleteMerchantItem(id, deleteId)
        .then(() => {
          history.push(`/cashback-action/${id}/merchant`);
          AppToaster.show({ message: `Мерчант успешно удален`, intent: 'success' });
        })
        .catch(() => AppToaster.show({ message: `Мерчант НЕ удален`, intent: 'danger' }))
        .finally(() => setDeleteId(null));
    }
  };

  return (
    <S.Section>
      <S.Nav>
        {merchantId && (
          <ButtonGroup>
            <Button
              intent="danger"
              disabled={false}
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                setDeleteId(merchantId);
                event.stopPropagation();
              }}
            >
              Удалить
            </Button>
          </ButtonGroup>
        )}
      </S.Nav>
      <S.Section>
        <Alert
          isOpen={deleteId !== null}
          icon="trash"
          intent={Intent.DANGER}
          cancelButtonText="Отмена"
          confirmButtonText="Удалить"
          onCancel={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
        >
          <h4>Подтвердите удаление Мерчанта!</h4>
        </Alert>
        <S.Section>
          {isLoading ? (
            <FS.LoaderWrapper>
              <Loader />
            </FS.LoaderWrapper>
          ) : (
            <Formik<CashbackActionMerchantUpdate>
              enableReinitialize
              initialValues={merchant as CashbackActionMerchantUpdate}
              validate={handleValidate}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting }) => (
                <Form>
                  <FS.FormWrapper>
                    <FS.Column>
                      <InputField name="mcc" label="MCC" disabled={!enabledFields?.mcc} />
                      <InputField name="merchantId" label="Merchant Id" disabled={!enabledFields?.merchantId} />
                      <InputField name="terminalId" label="Terminal Id" disabled={!enabledFields?.terminalId} />
                      <InputField
                        name="cardAcceptorName"
                        label="Card Acceptor Name"
                        disabled={!enabledFields?.cardAcceptorName}
                      />
                    </FS.Column>
                  </FS.FormWrapper>
                  <SumbitButton isSubmitting={isSubmitting} disabled={!enabledSubmit} />
                </Form>
              )}
            </Formik>
          )}
        </S.Section>
      </S.Section>
    </S.Section>
  );
};
