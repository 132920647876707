import { BaseApi } from 'api/base';
import { Balance, Bonus, ClientCardData, ClientFormData, OptionsDetails, Profile } from './client-types';

class ClientApi extends BaseApi {
  getProfiles = async (params: any): Promise<Profile[]> => {
    const result = await this.fetch<Profile[]>(`/client`, params);
    return result;
  };

  getCardData = async (mnemocode: string | number): Promise<ClientCardData> => {
    const result = await this.fetch<ClientCardData>(`/client/cards/${mnemocode}`);
    return result;
  };

  getFormData = async (mnemocode: string | number): Promise<ClientFormData> => {
    const result = await this.fetch<any>(`/client/${mnemocode}`);
    const data: ClientFormData = {
      id: result.mnemocode,
      login: result.login || '',
      primary_email: result.primary_email || '',
      primary_phone: result.primary_phone || '',
      exchange_active: result.exchange_active || false,
      disclaimer_required: result.disclaimer_required || '',
      oauth5_id: result.oauth5_id || '',
      mnemocode: result.mnemocode || '',
    };
    return data;
  };

  editItem = async (mnemocode: any, data: ClientFormData): Promise<ClientFormData> => {
    await this.fetch<void>(`/client/${mnemocode}`, { method: 'put', data });
    return data;
  };

  getOptionsCard = async (cardMnemocode: string): Promise<any> => {
    const result = await this.fetch<any>(`client/card/options/${cardMnemocode}`);
    return result;
  };

  getCardOptions = async (clientMnemocode: string, cardMnemocode: string): Promise<any> => {
    const result = await this.fetch<any>(`client/${clientMnemocode}/card/${cardMnemocode}/options`);
    return result;
  };

  setCardOption = async (cardMnemocode: string, productOptionId: number): Promise<any> => {
    const result = await this.fetch<any>(`client/card/${cardMnemocode}/option/${productOptionId}`, { method: 'POST' });
    return result;
  };

  getBalance = async (
    clientMnemocode: string,
    cardMnemocode: string,
    period: string,
    currency: string
  ): Promise<Balance> => {
    const result = await this.fetch<Balance>(`client/${clientMnemocode}/balance`, {
      params: { cardMnemocode, period, currency },
    });
    return result;
  };

  getBonus = async (clientMnemocode: string, period: string, currency: string): Promise<Bonus> => {
    const result = await this.fetch<Bonus>(`client/${clientMnemocode}/bonus`, {
      params: { period, currency },
    });
    return result;
  };

  getOptionsDateils = async (clientMnemocode: string, cardMnemocode: string): Promise<OptionsDetails[]> => {
    const result = await this.fetch<OptionsDetails[]>(
      `/client/${clientMnemocode}/card/${cardMnemocode}/options/details`
    );
    return result;
  };
}

export const clientApi = new ClientApi();
