import * as _ from 'lodash';

const columnDelimiter = '", "';
const rowDelimiter = '\n';

export const arrayToCsv = (array: Record<string, unknown>[], accessors: string[]) => {
  return array
    .map((item) => {
      const values: unknown[] = [];

      accessors.forEach((accessor) => {
        values.push(item[accessor]);
      });

      return `"${values.join(columnDelimiter)}"`;
    })
    .join(rowDelimiter);
};

export const csvToArray = <K extends string>(csv: string, headers: K[]) => {
  return csv.split(rowDelimiter).map((line: string) => {
    const lineWithoutEdgeQuotes = line.substring(1, line.length - 1);
    const lineItems = lineWithoutEdgeQuotes.split(columnDelimiter);

    if (headers.length !== lineItems.length)
      throw new Error('Количество столбцов должно соответствовать количеству заголовков');

    return _.zipObject(headers, lineItems) as unknown as Record<K, string>;
  });
};
