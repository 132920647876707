import React from 'react';
import { useParams } from 'react-router-dom';
import { TabsRouter } from 'components/ui/layouts/tabs-router';
import { EntityForm } from 'components/ui/layouts/entity-form';
import { Partner } from 'api/partner-types';
import { usePartner, usePartnerAdd, usePartnerDelete, usePartnerUpdate } from 'api/partner-hooks';
import { PartnerForm } from './partner-form';
import { PartnerProductsTable } from './partner-product/partner-products-table';
import { PartnerWidgetsTable } from './partner-widget/partner-widgets-table';
import { PartnerProgramsTable } from './partner-program/partner-programs-table';
import { PartnerModalsTable } from './partner-modal/partner-modals-table';

export const PartnerPage = ({ paramName }: { paramName: string }) => {
  const { partnerId } = useParams<{ [key: string]: string }>();
  const isPartnerId = Number(partnerId) > 0;
  return (
    <TabsRouter
      route={`/partner/${partnerId}`}
      tabs={[
        {
          caption: 'Карточка',
          component: (
            <EntityForm<Partner>
              paramName={paramName}
              listUrl="/partner"
              getEntityText={(data) => data?.components.name}
              component={PartnerForm}
              useEntity={usePartner}
              useEntityAdd={usePartnerAdd}
              useEntityMutate={usePartnerUpdate}
              useEntityDelete={usePartnerDelete}
            />
          ),
        },

        {
          caption: 'Продукты',
          component: <PartnerProductsTable paramName="productId" />,
          path: '/product',
          disabled: !isPartnerId,
        },
        {
          caption: 'Программы',
          component: <PartnerProgramsTable paramName="programId" />,
          path: '/program',
          disabled: !isPartnerId,
        },
        {
          caption: 'Виджеты',
          component: <PartnerWidgetsTable paramName="widgetId" />,
          path: '/widget',
          disabled: !isPartnerId,
        },
        {
          caption: 'Модальные окна',
          component: <PartnerModalsTable paramName="modalId" />,
          path: '/modal',
          disabled: !isPartnerId,
        },
      ]}
    />
  );
};
