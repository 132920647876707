import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';

export interface FadeBackgroundProps {
  showFadeOnMobile?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
}

export function FadeBackground({ children, onClick, showFadeOnMobile }: FadeBackgroundProps) {
  return (
    <Container
      onClick={onClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.1 }}
      showFadeOnMobile={showFadeOnMobile}
    >
      {children}
    </Container>
  );
}

const Container = styled(motion.div)<{ showFadeOnMobile?: boolean }>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);
  background-color: rgba(0, 0, 0, 0.65);
`;
